import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import AppHeader from './AppHeader';
import Loader from './Loader';
import {chatNewMessage, pushIDUpdate} from './Common';
import $ from "jquery";


const menuItems = [
        { 'title': 'Workout', 'url':'/Workout', 'tag':'workout', 'icon':<ion-icon name="barbell-outline"></ion-icon>},
        { 'title': 'Chat', 'url': '/Chat', 'tag': 'chat', 'icon':<ion-icon name="chatbubbles"></ion-icon>},
        { 'title': 'Timer', 'url': '/Timer', 'tag': 'timer', 'icon':<ion-icon name="timer"></ion-icon>},
        { 'title': 'Dashboard', 'url': '/Home', 'tag': 'home', 'icon':<ion-icon name="document-text-outline"></ion-icon>},
        { 'title': 'Profile', 'url': '/Profile', 'tag': 'profile', 'icon':<ion-icon name="body"></ion-icon>}
];

// <span class="badge badge-danger">5</span>
class AppBottomMenu extends Component {
    
    constructor(props) {
        super(props);

        this.state = {
            activeClass: props.activeClass,
            customerid: localStorage.getItem('userData'),
            s_id: localStorage.getItem('subscriptionID'),
            c_id: localStorage.getItem('CHAT_ID'),
            newBadgeCount: "",
        }
    }

    componentDidMount() {
    	let badgeCOUNT = 0;
    	badgeCOUNT = chatNewMessage(this.state.customerid, this.state.s_id, this.state.c_id);
    	pushIDUpdate(this.state.customerid, localStorage.getItem('WEBPUSHR_ID'));

    	this.setState({
    		newBadgeCount: badgeCOUNT,
    	})
    }


    render() {
        return (
            <div className="appBottomMenu">
                {menuItems.length >0 &&
                    menuItems.map((menuitem, i)=>{
                        let activeClass = "";
                        let url_str = "#";
                        // console.log("App bottom menu: "+this.props.allowClick);

                        if(menuitem.tag==this.state.activeClass) {
                            activeClass = 'active';
                        }

                        if(this.props.allowClick || this.props.allowClick==undefined) {
                        	url_str = menuitem.url;
                        }

                        if(menuitem.tag=='profile' || menuitem.tag=='home') {
                        	url_str = menuitem.url;
                        }

                        return(
                            <Link to={url_str} className={"item " + activeClass} key={i}>
                                <div className="col">
                                    {menuitem.icon}

                                    {menuitem.tag=='chat' && this.state.newBadgeCount &&
                                    	<span className="badge badge-danger">{this.state.newBadgeCount}</span>
                                	}
                                    
                                    <strong>{menuitem.title}</strong>
                                </div>
                            </Link>
                        )
                    })
                }
            </div>
        );
    }
}

export default AppBottomMenu