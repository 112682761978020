import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import AppHeader from './AppHeader';
import Loader from './Loader';
import $ from "jquery";

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

class Plans extends Component {
	constructor(props) {
            super(props);
            this.state = {
            LOADING: true,
            Notifications: false,
            Logged: false,
            programs: [],
            basicPrograms: 0,
            premiumPrograms: 0,
        };
    }

    getPrograms() {
        let param_arr = [];
        let savedParams = localStorage.getItem("storedSelection");
        // console.log(savedParams);

        /*if(savedParams!='') {

            let param_str = "";
            let param_var = "";
            let param_val = "";
            let result = savedParams.split('|'); // obtaining the params

            if(result!='') {
                result.forEach(function(item, index){
                    param_str = item.split('=');
                    param_var = param_str[0];
                    param_val = param_str[1];

                    param_arr[param_var] = param_val;
                });
            }
        }*/

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ mode: 'PROGRAMS', selected:savedParams })
        };

        fetch(global.config.bURL+"select_program.php", requestOptions)
            .then(function (response) {
                return response.json();
            })
            .then((result)=> {
                let responseJson = result;
                // console.log(responseJson);

                if(!responseJson.err) {
                    this.setState({ 
                        basicPrograms: responseJson.BASIC, 
                        premiumPrograms: responseJson.PREMIUM, 
                        LOADING: false 
                    });
                }
            });
    }

    componentDidMount() {
        this.getPrograms();
        this.setState({LOADING:false});
    }

    render() {
        let AppHeaderClass = 'appHeader no-border bg-common-header text-light';
        let AppHeaderLeft = 'showLINK';
        let AppHeaderLeftLink = '/';
        let AppHeaderTitle = 'PROGRAMS OFFERED';
        let AppHeaderRight = '';
        let AppHeaderRightLink = '';
        let currency_type = localStorage.getItem('CURRENCY_TYPE')?localStorage.getItem('CURRENCY_TYPE'):'INR';


        return (
            <div className="bg-beige">
                {this.state.LOADING && <Loader />}

                <AppHeader AppHeaderClass={AppHeaderClass} AppHeaderLeft={AppHeaderLeft} AppHeaderLeftLink={AppHeaderLeftLink} AppHeaderTitle={AppHeaderTitle} AppHeaderRight={AppHeaderRight} AppHeaderRightLink={AppHeaderRightLink} />

                <div className="extraHeader sub-header bg-coach-darkpurple">
                     <ul className="nav nav-tabs lined" role="tablist">
                        <li className="nav-item">
                            <a className="nav-link active" data-toggle="tab" href="#home11" role="tab">
                                BASIC
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" data-toggle="tab" href="#profile12" role="tab">
                                PREMIUM
                            </a>
                        </li>
                    </ul>
                </div>

                <div id="appCapsule" className="extra-header-active">
                	<div className="login-form">  
                	    <div className="tab-content mt-1">
                	        <div className="tab-content pl-3 pr-3 mt-3">

                                    <div className="tab-pane fade show active" id="home11" >
                                        {this.state.basicPrograms.length > 0 &&
                                            this.state.basicPrograms.map((program, i)=>{

                                                let register_free = '/Register/'+program.ID;
                                                let register_url = '/Register/'+program.ID+'/B';
                                                let duration_type = "";
                                                let priceVAL = "";
                                                let priceSYM = "";

                                                if(currency_type=='INR') {
                                                	priceVAL = program.RENEWAL_PRICE;
                                                	priceSYM = '&#x20B9;';
                                                }
                                                else {
                                                	priceVAL = program.RENEWAL_PRICE_USD;
                                                	priceSYM = '&#36;';
                                                }


                                                if(program.DURATION_TYPE=='M') {
                                                    duration_type = (program.DURATION > 1)?'Months':'Month';
                                                }
                                                else if(program.DURATION_TYPE=='W') {
                                                	duration_type = (program.DURATION > 1)?'Weeks':'Week';
                                            	}
                                                else if(program.DURATION_TYPE=='Y') {
                                                    duration_type = (program.DURATION > 1)?'Years':'Year';
                                                }

                                                return (
                                                    <div className="program-outer" key={i}>
                                                        <div className="workout-types">
                                                            {program.IMAGE && 
                                                            	<div className="image-cover">
                                                            	    <img src={program.IMAGE} alt="image" className="imaged m-auto" />
                                                            	</div>
                                                                /*<img src={program.IMAGE} alt="image" className="exta-area2 imaged w100 rounded m-auto"/>*/
                                                            }
                                                            <div className="price-block mt-2">
                                                                <h4>{program.NAME}</h4>
                                                                <p className="font-15">{program.DESC}</p>
                                                                 <div className="price-tag bg-coach-orange">
                                                                 	{priceVAL > 0 
                                                                 		?<h5><span className="ft-wt-300" dangerouslySetInnerHTML={{ __html:priceSYM}} /> {priceVAL}</h5>
                                                                 		:<h5><span className="ft-wt-300" />Free</h5>
                                                                 	}
                                                                     
                                                                     <p>{program.DURATION + ' ' + duration_type} </p>
                                                                     <div className="premium-fe"></div>
                                                                     <Link className="btn btn-coach rounded btn-block btn-mid text-upper" to={register_url}>Buy Now</Link>
                                                                     {priceVAL > 0
                                                                     	?<Link to={register_free} className="btn btn-coach-pink rounded btn-block btn-mid text-upper">Take 7 days free trial</Link>
                                                                     	:''
                                                                     }
                                                                 </div>
                                                            </div>

                                                            <h5 className="mb-1 pb-0">Plan Inclusions: </h5>
                                                            <div className="text-left pl-22">
                                                                {program.CONTENT && <p dangerouslySetInnerHTML={{ __html: program.CONTENT }} /> }
                                                            </div>
                                                            
                                                            <h5 className="mb-1 pb-0">Equipments Required:</h5>
                                                            <div className="text-left">
                                                                <ul className="gym-icons">
                                                            	{program.EQUIPMENT.length > 0 &&
                                                            		program.EQUIPMENT.map((equipment, e)=>{

                                                            			return (
                                                            			    <li key={e}>
                                                            			    	<span dangerouslySetInnerHTML={{ __html: equipment.NAME }} />
                                                            			    	{equipment.IMAGE!="" &&
                                                            			    		<img src={equipment.IMAGE} width="20" height="20"/>
                                                            			    	}
                                                            			    </li>
                                                            			)
                                                            		})
                                                            	}
                                                            	</ul>
                                                           </div> 
                                                        </div>
                                                    </div>
                                                );

                                            })
                                        }
                                        {(!this.state.basicPrograms)?'No Programs Found...':''}
                                    </div>

                	                {/*<!-- Premium program tab start -->*/}
                	                <div className="tab-pane fade" id="profile12" >
                                        {this.state.premiumPrograms.length > 0 &&
                                            this.state.premiumPrograms.map((program, i)=>{

                                                let register_free = '/Register/'+program.ID+'/P';
                                                let register_url = '/Register/'+program.ID+'/P';
                                                let duration_type = "";

                                                let priceVAL = "";
                                                let priceSYM = "";

                                                if(currency_type=='INR') {
                                                	priceVAL = program.RENEWAL_PRICE;
                                                	priceSYM = '&#x20B9;';
                                                }
                                                else {
                                                	priceVAL = program.RENEWAL_PRICE_USD;
                                                	priceSYM = '&#36;';
                                                }


                                                if(program.DURATION_TYPE=='M') {
                                                    duration_type = 'Month';
                                                }
                                                else if(program.DURATION_TYPE=='W') {
                                                	duration_type = 'Week';
                                            	}
                                                else if(program.DURATION_TYPE=='Y') {
                                                    duration_type = 'Year';
                                                }

                                                return(
                	                               <div className="program-outer" key={i}>
                        	                        <div className="workout-types">
                                                        {program.IMAGE && 
	                        	                        	<div className="image-cover">
	                        	                        	    <img src={program.IMAGE} alt="image" className="imaged m-auto" />
	                        	                        	</div>
                        	                               // <img src={program.IMAGE} alt="image" className="exta-area2  imaged w100 rounded m-auto"/>
                                                        }
                        	                            <div className="price-block mt-2">
                        	                                <h4>{program.NAME}</h4>
                        	                                <p className="font-15">{program.DESC}</p>
                        	                                 <div className="price-tag bg-coach-green">
                        	                                     <h5><span className="ft-wt-300" dangerouslySetInnerHTML={{ __html:priceSYM}} />{priceVAL}</h5>
                        	                                     <p>{program.DURATION + ' ' + duration_type} </p>
                        	                                     <div className="premium-fe">
                                                                 {program.DIET=='Y' &&
                        	                                     	<p>Customised Diet plan at additional of <span className="text-fyellow ft-wt-500">&#x20B9; {program.DIET_PRICE}</span> for life time with upgrade option</p>
                                                                 }
                        	                                     </div>
                                                                 <Link className="btn btn-coach rounded btn-block btn-mid text-upper" to={register_url}>Buy Now</Link>
                        	                                 </div>
                        	                            </div>
                        	                            <h5 className="mb-1 pb-0">Plan Inclusions: </h5>
                                                        <div className="text-left pl-22">
                                                            {program.CONTENT && <p dangerouslySetInnerHTML={{ __html: program.CONTENT }} /> }
                                                        </div>
                                                        
                                                        <h5 className="mb-1 pb-0">Equipments Required:</h5>
                                                        <div className="text-left">
                                                        	<ul className="gym-icons">
                                                            {program.EQUIPMENT.length > 0 &&
                                                            	program.EQUIPMENT.map((equipment, e)=>{

                                                            		return (
                                                            		    <li key={e}>
                                                            		    	<span dangerouslySetInnerHTML={{ __html: equipment.NAME }} />
                                                            		    	{equipment.IMAGE!="" &&
                                                            		    		<img src={equipment.IMAGE} width="20" height="20"/>
                                                            		    	}
                                                            		    </li>
                                                            		)
                                                            	})
                                                            }
                                                            </ul>
                                                        </div>
                        	                        </div>
                	                               </div>
                                                );
                                            })
                                        }
                                        {(!this.state.basicPrograms)?'No Programs Found...':''}
                	                </div>
                	                {/*<!-- Premium program tab end -->*/}
                	        </div>
                	    </div>
                	    {/*<div className="section double mt-1 mb-5">
                	        <form action="basic_program.html">
                	            <div className="form-button-group">
                	                <Link to={'./Register'} className="btn btn-coach-pink rounded btn-block btn-mid text-upper">Take 7 days free trail</Link>
                	            </div>
                	        </form>
                	    </div>*/}
                	</div>

                </div>
            </div>
        )
    }
}

export default Plans;