import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import AppHeader from './AppHeader';
import Loader from './Loader';
import $ from "jquery";

// import Footer from './Footer';
// import Menu from './Menu';
// import { PostData } from './PostData';
class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            LOADING: false,
            Notifications: false,
            Logged: false,
        };
    }

    handleSubmit = (e) => {
        e.preventDefault();

        let err = 0;
        if ($('#frmLogin')[0].checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            err++;
        }
        $('#frmLogin')[0].classList.add('was-validated');

        if (err == 0) {
            $('#submitLoading').css('display', 'block');

            const method = "POST";
            const body = new FormData(this.form);
            //console.log(body);
            var that = this;
            
            fetch(global.config.bURL+'customer_api.php', { method, body })
                .then(function (response) {
                    //console.log(response);
                    return response.json();
                })
                .then(function (result) {

                    let notify = '';
                    if (result.err != '') {
                        if (result.err == '1') notify = 'Mobile No Field is Empty';
                        else if (result.err == '2') notify = 'Password Field is Empty';
                        else if (result.err == '3') notify = 'Mobile No Not Found';
                        else if (result.err == '4') notify = 'Invalid Access Detected';
                        else if (result.err == '5') notify = 'No Such User Exist';
                        else if (result.err == '6') notify = 'Invalid Password Entered';
                    }

                    let logged = false;

                    let customer = result.customer;
                    if (customer != '' && customer != undefined) {
                        localStorage.setItem('userData', customer);
                        localStorage.setItem('Verified', result.verified);
                        localStorage.setItem('subscriptionID', result.SUBSCRIPTION_ID);
                        localStorage.setItem('CHAT_ID', result.CHAT_ID);
                        logged = true;
                    }

                    that.setState({Logged: logged });
                    that.setState({ Notifications: notify, Logged: logged });
                    $('#submitLoading').css('display', 'none');
                    if (notify != '')
                        window.$('#ModalAlert').modal('show');
                });
        }
    }

    componentWillMount() {
        let mount = $("div").hasClass("modal-backdrop");
        if (mount == true) {
            $('body').removeClass('modal-open');
            $(".modal-backdrop").remove();
            //window.location.reload(true);
        }
    }

    componentDidMount() {
    	window.$("#wrapper_bgVideo").css('display', 'none');
        //alert(localStorage.getItem('SUBSCRIBER_ID'));
        //this.setState({ LOADING: false });
    }
    render() {

        if (this.state.Logged) {
            return (<Redirect to={'/Home'}/>)
        }

        let AppHeaderClass = 'appHeader no-border  bg-common-header text-light';
        let AppHeaderLeft = '';
        let AppHeaderLeftLink = '/';
        let AppHeaderTitle = 'Login';
        let AppHeaderRight = '';
        let AppHeaderRightLink = '';


        return (
            <div className="">
                {this.state.LOADING && <Loader />}
                
                <AppHeader AppHeaderClass={AppHeaderClass} AppHeaderLeft={AppHeaderLeft} AppHeaderLeftLink={AppHeaderLeftLink} AppHeaderTitle={AppHeaderTitle} AppHeaderRight={AppHeaderRight} AppHeaderRightLink={AppHeaderRightLink} />

                <div id="appCapsule">
                    <div className="login-form mt-3 pt-1">
                        <div className="section mt-1 mb-4">
                        <img src="assets/img/new-logo.png" alt="tlc logo"/>
                            <h1 className="text-gre6 font-28 mb-0">Hey!</h1>
                            <h4 className="text-gre7 font-20 ft-wt-400">Enter your details to login</h4>
                        </div>
                        <div className="section double mt-0 mb-5">
                    		
                            <form name="frmLogin" id="frmLogin" ref={el => (this.form = el)} onSubmit={this.handleSubmit} className="needs-validation" noValidate>
                                <input type="hidden" value="SIGNIN" className="form-control" name="mode" id="mode" required />
                                <input type="hidden" value={localStorage.getItem('WEBPUSHR_ID')} className="form-control" name="push_id" id="push_id" />
                                
                                <div className="form-group curved pb-05">
                                    <div className="input-wrapper">
                                        <input type="text" className="form-control" name="txtusermobile" id="txtusermobile" placeholder="Mobile No" required minLength="10" maxLength="10" pattern="[0-9]{10}" autoComplete="off" />
                                        <i className="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                        <div className="valid-feedback">&nbsp;</div>
                                        <div className="invalid-feedback">Enter your 10 digit mobile no.</div> 
                                    </div>
                                </div>
                                <div className="form-group curved pb-05">
                                    <div className="input-wrapper">
                                        <input type="password" className="form-control" name="txtpassword" id="txtpassword" placeholder="Password" required autoComplete="off" />
                                        <i className="clear-input">
                                            <ion-icon name="close-circle"></ion-icon>
                                        </i>
                                        <div className="valid-feedback">&nbsp;</div>
                                        <div className="invalid-feedback">Enter your password.</div>  
                                    </div>
                                </div>
                                <div className="form-group">
                                    <button type="submit" className="btn btn-coach-pink rounded btn-block btn-mid text-upper">Login</button>
                                </div>
                                <div className="mt-1 float-left">
                                    <div className="pt-1">
                                        <Link to={'/ForgotPassword'} className="text-muted text-gre7 ft-wt-500">Forgot Password</Link>
                                    </div>
                                </div>

                                <div className="mt-1 float-right">
                                    <div>
                                        <a href={global.config.registerURL} className="ml-1 ft-wt-500 text-gre7 btn bdr-white rounded font-13 text-gre3">Register</a>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="modal fade dialogbox" id="ModalAlert" data-backdrop="static" tabIndex="-1" role="dialog">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-icon text-danger">
                                    <ion-icon name="close-circle"></ion-icon>
                                </div>
                                <div className="modal-body">
                                    {this.state.Notifications}
                                </div>
                                <div className="modal-footer">
                                    <div className="btn-inline">
                                        <button type="button" className="btn" data-dismiss="modal">Ok</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Login;