import React from 'react';
import ReactDOM from 'react-dom';
import {Link, Redirect} from 'react-router-dom';
import AppHeader from './AppHeader';
import Loader from './Loader';
import $ from "jquery";
// import {gtag_report_conversion} from "./Common";

class Success extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
      	LOADING: true,
      }
  	}

  	componentDidMount() {
  		this.setState({
  			LOADING: false,
  		})
  	}

  	render () {
  		let AppHeaderClass = 'appHeader no-border bg-common-header text-light';
  		let AppHeaderLeft = 'showHOME';
  		let AppHeaderLeftLink = '/Home';
  		let AppHeaderTitle = 'Thank you';
  		let AppHeaderRight = true;
  		let AppHeaderRightLink = '';

  		if(localStorage.getItem('validPayment')!=1) {
  			return(<Redirect to={"/Home"} />)
  		}
  		else {
  			window.gtag_report_conversion();
  		}

  		return (
  		    <div>
  		        {this.state.LOADING && <Loader />}
  		        <AppHeader AppHeaderClass={AppHeaderClass} AppHeaderLeft={AppHeaderLeft} AppHeaderLeftLink={AppHeaderLeftLink} AppHeaderTitle={AppHeaderTitle} AppHeaderRight={AppHeaderRight} AppHeaderRightLink={AppHeaderRightLink} />
  		      	<div id="appCapsule" className="pb-5">
  		        	<div className="login-form mt-3 thankyou-msg">
  		            	<div className="error-page pt-0 pb-0">
  		                	<img src={global.config.successImage} alt="alt" className="imaged square w200" />
  		              	</div>
  		              	<div className="mt-3">
	                	    <h4 className="title">Thank you !!</h4>
	                    	<h5>You have successfully purchased our program</h5>
	                      	<p className="text mb-5 font-15">
	                          Download the app from Play Store or App Store
	                      	</p>
	                      	<p className="text mb-5 font-15">
	                          If You already have the app installed on your device, please restart the app to see your new purchase.
	                      	</p>

  	                    	<div className="section pr-3 pl-3 pb-1 pt-0 final-details text-left">
  	                	        <div className="section full mt-1">
  	                	           <div className="wide-block pt-1 pb-1 pl-0 pr-0">
  		                	              <div className="row">
  		                	                 <div className="col-6">
  		                	                   <a href={global.config.playStoreURL} target="_blank">
  		                	                      <img src={global.config.playstoreImage} alt="playstore image" className="imaged img-fluid" />
  		                	                   </a>
  		                	                 </div>
  		                	                 <div className="col-6">
  		                	                     <a href={global.config.appStoreURL} target="_blank">
  		                	                        <img src={global.config.appstoreImage} alt="appstore image" className="imaged img-fluid" />
  		                	                    </a>
  		                	                 </div>
  		                	              </div>
  	                	           </div>
  	                	        </div>  
  	                    	</div>   
  		              	</div>
  		         	</div>
  		     	</div>
  		    </div>
		)
  	}
}

export default Success;