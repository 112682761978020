import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

class ModalAlert extends Component {

    constructor(props) {
        super(props);

        // this._clickConfrim = this._clickConfrim.bind(this);
    }

    render() {

        if(this.props.modalType=='CONFIRM')
        {
            return(
                <div className="modal fade dialogbox" id="ModalAlert" data-backdrop="static" tabIndex="-1" role="dialog">
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title">{this.props.modalTitle}</h5>
                      </div>
                      <div className="modal-body">{this.props.Notifications}</div>
                      <div className="modal-footer">
                        <div className="btn-inline"> 
                            <button type="button" className="btn btn-text-secondary" data-dismiss="modal">CANCEL</button> 
                            <button type="button" onClick={this._clickConfrim} className="btn btn-text-primary" data-dismiss="modal">CONFIRM</button> 
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            );
        }
        else 
        {
            return(
                <div className="modal fade dialogbox" id="ModalAlert" data-backdrop="static" tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            {this.props.type=='error' &&
                                <div className="modal-icon text-danger">
                                    <ion-icon name="close-circle"></ion-icon>
                                </div>
                            }

                            {this.props.type=='success' &&
                                <div className="modal-icon text-success">
                                    <ion-icon name="checkmark-circle"></ion-icon>
                                </div>   
                            }

                            <div className="modal-body"> 
                                    {this.props.Notifications} 
                            </div>
                            <div className="modal-footer">
                                <div className="btn-inline"> 
                                    <button type="button" className="btn" data-dismiss="modal">Ok</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default ModalAlert;