import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

class Logout extends Component {
    constructor(props) {
            super(props);
            this.state = {
            Notifications: false,
        };

        localStorage.clear();
    }

    render() {

      if(!localStorage.getItem('userData')) {
        return(<Redirect to={'/Login'} />);
      }
    }    
}

export default Logout;