import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import AppHeader from './AppHeader';
import Loader from './Loader';
import AppBottomMenu from './AppBottomMenu';
import {validatedate, validdate} from "./Common";
import $ from "jquery";

class UpdateProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            LOADING: true,
            Notifications: false,
            Logged: false,
            profiledata: [],
            subscriptiondata: [],
            Updated:false,
            customerid: localStorage.getItem('userData'),
            GENDER: [],
            checkedGENDER: "",
        };
    }

    handleSubmit = (e) => {
        e.preventDefault();

        let err = 0;
        if ($('#frmUpdate')[0].checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            err++;
        }
        $('#frmUpdate')[0].classList.add('was-validated');

        let dateofbirth = $('#txtdate').val()+'-'+$('#txtmonth').val()+'-'+$('#txtyear').val();

        if(!validatedate(dateofbirth)) {
            err++;
            //alert('Please enter a valid date of birth');
            $("#dob-invalid").html("Please enter a valid date of birth");
        }
        else if(!validdate( $('#txtdate').val(),  $('#txtmonth').val(),  $('#txtyear').val(), "P")) {
            err++;
            //alert('Date of birth cannot be in the future');
            $("#dob-invalid").html("Date of birth cannot be in the future");
        }

        if (err == 0) {
            $('#submitLoading').css('display', 'block');

            const method = "POST";
            const body = new FormData(this.form);
            var that = this;

            fetch(global.config.bURL+"customer_profileupdate.php", { method, body })
                .then(function (response) {
                    //console.log(response);
                    return response.json();
                })
                .then(function (result) {

                    let notify = '';
                    if (result.err != '') {
                        if (result.err == '4') notify = 'Invalid Access Detected!';
                    }

                    let updated = false;

                    if (result.err == '1') {
                        updated = true;
                    }

                    that.setState({ Notifications: notify, Updated: updated });
                     $('#submitLoading').css('display', 'none');
                     
                    if (notify != '')
                        window.$('#ModalAlert').modal('show');
                });
        }
    }

    _handleGenderRadio() {

    }

    getProfileDetails() {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ mode: 'HOME', clientid: this.state.customerid})
        };

        fetch(global.config.bURL+"global_data.php", requestOptions)
            .then(function (response) {
                return response.json();
            })
            .then((result)=> {
                let responseJson = result;
                // console.log(responseJson.USER.NAME);
                this.setState({ 
                	subscriptiondata:responseJson.SUBSCRIPTION, 
                	profiledata: responseJson.USER, 
                	LOADING: false, 
                	Logged: true,
                	checkedGENDER: responseJson.USER.GENDER,
                });
            });

        const requestOptions2 = {
             method: 'POST',
             headers: { 'Content-Type': 'application/json' },
             body: JSON.stringify({ mode: 'GLOBALS'})
        };

        fetch(global.config.bURL+"global_data.php", requestOptions2)
            .then(function (response) {
                return response.json();
            })
            .then((result)=> {
            	// console.log(result);
            	this.setState({
            		// tSHIRT: result.SIZES,
            		GENDER: result.GENDER,
            	})
            });
    }

    componentDidMount() {
        this.getProfileDetails();
        // this.setState({LOADING: false});
    }

    render() {
        
        if (!this.state.customerid) {
            localStorage.setItem('userData','');
            return (<Redirect to={'/'}/>);
        }

        let AppHeaderClass = 'appHeader no-border bg-common-header text-light';
        let AppHeaderLeft = 'showLINK';
        let AppHeaderLeftLink = '/Profile';
        let AppHeaderTitle = 'Edit Profile';
        let AppHeaderRight = '';
        let AppHeaderRightLink = '';

        if(this.state.Updated) {
            return(<Redirect to={'/Profile'} />);
        }

        return (
            <div className="edit-text">
                {this.state.LOADING && <Loader />}
                
                <AppHeader AppHeaderClass={AppHeaderClass} AppHeaderLeft={AppHeaderLeft} AppHeaderLeftLink={AppHeaderLeftLink} AppHeaderTitle={AppHeaderTitle} AppHeaderRight={AppHeaderRight} AppHeaderRightLink={AppHeaderRightLink} />
                        <div id="appCapsule">
                    <form id="frmUpdate" ref={el =>
                        (this.form = el)} onSubmit={this.handleSubmit} className="needs-validation" noValidate>
                        <input type="hidden" value="UPDATE" className="form-control" name="mode" id="mode" />
                        <input type="hidden" value={this.state.customerid} readOnly className="form-control" name="txtclientid" id="txtclientid" />


                        <div className="section pr-3 pl-3 pb-1 pt-0 basic-form">
                            {/* <input type="hidden" value={localStorage.getItem('SUBSCRIBER_ID')} className="form-control" name="webpushr_id" id="webpushr_id" /> */}
                            <div className="form-group basic">
                                <div className="input-wrapper guest-detail float-label">
                                    <input type="text" className="input-box inputText form-control" name="txtusername" id="txtusername" required autoComplete="off" defaultValue={this.state.profiledata.NAME} />
                                    <label className="floating-label">Name</label>
                                    <i className="clear-input">
                                        <ion-icon name="close-circle" role="img" className="md hydrated" aria-label="close circle"></ion-icon>
                                    </i>
                                    <div className="valid-feedback">&nbsp;</div>
                                    <div className="invalid-feedback">Please enter your full name.</div>
                                </div>
                                <div className="input-info">Enter your full name</div>
                            </div>
                            <div className="form-group basic mb-2">
                                <div className="input-wrapper guest-detail float-label">
                                   <label className="label">Email</label>
                                    <input type="email" className="input-box inputText form-control" name="txtuseremail" id="txtuseremail" required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" autoComplete="off" value={this.state.profiledata.EMAIL} readOnly />
                                    
                                    <i className="clear-input">
                                        <ion-icon name="close-circle" role="img" className="md hydrated" aria-label="close circle"></ion-icon>
                                    </i>
                                    <div className="valid-feedback">&nbsp;</div>
                                    <div className="invalid-feedback">Please enter your email id.</div>
                                </div>
                                <div className="input-info">Enter your email</div>
                            </div>
                            <div className="form-group basic">
                                <div className="input-wrapper new-input-gp0">
                                    <label className="label" htmlFor="number">Code</label>
                                    <input type="text" className="form-control" name="txtusermobilecode" id="txtusermobilecode" required value={this.state.profiledata.MCODE} readOnly />
                                    <span className="focus-border"></span>
                                    <i className="clear-input">
                                        <ion-icon name="close-circle" role="img" className="md hydrated" aria-label="close circle"></ion-icon>
                                    </i>
                                    <div className="valid-feedback">&nbsp;</div>
                                    <div className="invalid-feedback">Please enter country code.</div>
                                    <div className="mt-02 text-gre4 font-12">Country</div>
                                </div>
                                <div className="input-wrapper new-input-gp">
                                    <label className="label" htmlFor="txtusermobile">Mobile No.</label>
                                    <input type="text" className="form-control" name="txtusermobile" id="txtusermobile" required minLength="10" maxLength="10" pattern="[0-9]{10}" autoComplete="off" value={this.state.profiledata.MOBILE} readOnly/>
                                    <span className="focus-border"></span>
                                    <i className="clear-input">
                                        <ion-icon name="close-circle" role="img" className="md hydrated" aria-label="close circle"></ion-icon>
                                    </i>
                                    <div className="valid-feedback">&nbsp;</div>
                                    <div className="invalid-feedback">Please enter your mobile number.</div>
                                </div>
                                <div className="clear"></div>
                            </div>

                            <div className="form-group basic">
                            	<div className="gender-select">
                            		<label className="label" htmlFor="gender">Gender</label>
                                    <div className="gender-inline">
                                    		{this.state.GENDER && this.state.GENDER.length > 0 &&
                                    		  this.state.GENDER.map((gender, i)=>{
                                    		  		let checkedrd = false;

                                    		  		if(gender.ID==this.state.checkedGENDER) {
                                    		  			// console.log('Mark '+this.state.checkedGENDER+' checked');
                                    		  			checkedrd = true;
                                    		  		}

                                    		      	return (
                                    		      	        <div className="custom-control custom-radio mb-1 mr-2" key={i}>
                                    		      	            <input type="radio" id={'rdgender'+gender.ID} name="rdgender" className="custom-control-input" defaultValue={gender.ID} defaultChecked={checkedrd} />
                                    		      	            <label className="custom-control-label" htmlFor={'rdgender'+gender.ID}>{gender.TEXT}</label>
                                       							<span className="focus-border"></span>
                                    		      	        </div>
                                    		        );
                                    		    })
                                    		}
                                    </div>
                            	</div>
                            </div>

                            {/*<div className="form-group basic">
                            	<div className="">
                            		<label className="label" htmlFor="gender">Gender</label>
                                    <div className="row">
	                                         <div className="custom-control custom-radio d-inline">
	                                             <input type="radio" id="rdgenderM" name="rdgender" className="custom-control-input" required="required" defaultValue="M" defaultChecked={this.state.checkedM?this.state.checkedM:''} />
	                                             <label className="custom-control-label p-0" htmlFor="rdgenderM"> Male</label>
	                                         </div>

	                                         <div className="custom-control custom-radio d-inline">
	                                             <input type="radio" id="rdgenderF" name="rdgender" className="custom-control-input" defaultValue="F"  defaultChecked={this.state.checkedF?this.state.checkedF:''}/>
	                                             <label className="custom-control-label p-0" htmlFor="rdgenderF">Female </label>
	                                         </div>
                                    </div>
                            	</div>
                            </div>*/}

                            <div className="form-group basic">
                                <div className="">
                                    <label className="label" htmlFor="number">Date of Birth</label>
                                    <div className="input-wrapper new-input-gp3">
                                        <input type="text" className="form-control" placeholder="DD"  name="txtdate" id="txtdate" autoComplete="off" defaultValue={this.state.profiledata.D_DOB} />
                                        <span className="focus-border"></span>
                                        <i className="clear-input">
                                            <ion-icon name="close-circle" role="img" className="md hydrated" aria-label="close circle"></ion-icon>
                                        </i>
                                        <div className="mt-02 text-gre4 font-12">Date</div>
                                        
                                    </div>
                                    <div className="input-wrapper new-input-gp3">
                                        <input type="text" className="form-control" placeholder="MM" name="txtmonth" id="txtmonth" autoComplete="off" defaultValue={this.state.profiledata.M_DOB} />
                                        <span className="focus-border"></span>
                                        <i className="clear-input">
                                            <ion-icon name="close-circle" role="img" className="md hydrated" aria-label="close circle"></ion-icon>
                                        </i>
                                         <div className="mt-02 text-gre4 font-12">Month</div>
                                        
                                    </div>
                                    <div className="input-wrapper new-input-gp3">
                                        <input type="text" className="form-control" placeholder="YYYY" name="txtyear" id="txtyear"  autoComplete="off" defaultValue={this.state.profiledata.Y_DOB} /> 
                                        <span className="focus-border"></span>
                                        <i className="clear-input">
                                            <ion-icon name="close-circle" role="img" className="md hydrated" aria-label="close circle"></ion-icon>
                                        </i>
                                        <div className="mt-02 text-gre4 font-12">Year</div>
                                        
                                    </div>
                                    <div className="clear"></div>
                                </div>
                                <div className="valid-feedback">&nbsp;</div>
                                <div className="invalid-feedback" id="dob-invalid"></div>
                            </div>
                            <div className="form-group basic mb-2">
                                <div className="input-wrapper guest-detail float-label">
                                    <input type="text" className="form-control input-box inputText " name="txtprofession" id="txtprofession" required autoComplete="off" defaultValue={this.state.profiledata.PROFESSION}/> {/*pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"  */}
                                    <label className="floating-label">Profession</label>
                                    <i className="clear-input">
                                        <ion-icon name="close-circle" role="img" className="md hydrated" aria-label="close circle"></ion-icon>
                                    </i>
                                    <div className="valid-feedback">&nbsp;</div>
                                    <div className="invalid-feedback">Pleas enter your profession.</div>
                                </div>
                                <div className="input-info">Enter your profession</div>
                            </div>
                        </div>
                        <div className="section double mt-1 mb-5">
                            <div className="form-button-group">
                                <button type="submit" className="btn btn-coach-blue rounded btn-block btn-mid text-upper">
                                <span className="spinner-border spinner-border-sm mr-05" id="submitLoading" style={{ display: 'none' }} role="status" aria-hidden="true"></span>
                                Update
                                </button>
                            </div>
                    </div>
                </form>

                <div className="modal fade dialogbox" id="ModalAlert" data-backdrop="static" tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-icon text-danger">
                                <ion-icon name="close-circle"></ion-icon>
                            </div>
                            <div className="modal-body"> 
                                    {this.state.Notifications} 
                            </div>
                            <div className="modal-footer">
                                <div className="btn-inline"> 
                                    <button type="button" className="btn" data-dismiss="modal">Ok</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>

       
            </div>
        );
    }
}

export default UpdateProfile;