import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import AppHeader from './AppHeader';
import Loader from './Loader';
import AppBottomMenu from './AppBottomMenu';
import WorkoutSlider2 from './WorkoutSlider2';
import {secondsToHms} from './Common';
import ModalAlert from './ModalAlert';
import TimerControl from './TimerControl';
import $ from 'jquery';

const Counter = function(props) {
    return (
        <h2> 
            <span>{props.min + ':'}</span>
            <span>{props.secs + ':'}</span>
            <span>{props.milisecs}</span>
        </h2>
    );
};

const Reset = function(props) {
    return (
        <div className="col-6 text-center edited-pp play-pause">
            <button onClick={props.onClickReset} type="button" className="btn btn-coach-pink btn-lg mr-1"><ion-icon name="ellipse"></ion-icon></button>
            <p>Reset</p>
        </div>
    );
};

class WorkoutDetail extends Component {
    constructor(props) {
            super(props);
            this.state = {
            Notifications: false,
            LOADING: false,
            scheduleID: props.match.params.scheduleid,
            workoutType: props.match.params.type,
            workoutID: props.match.params.workoutid,
            workoutName: "",
            workoutSets: "",
            workoutDetails: "",
            customerid: localStorage.getItem('userData'),
            subscriptionID: localStorage.getItem('subscriptionid'),
            notifType: "",
            WO_COMMENT: "",
            WO_IMAGES: "",
            selectedImages:'',
            WO_COMPLETED: "N",
            nxtid: "",
            nxttype: "",
            nxtscid: "",
            nextredirect: "",
            nexturl: "",

            paused: true,
            minute: 1,
            sec: 1, 
            milisec: 1,
            disp_milisec: '00',
            disp_secs: '00',
            disp_minute: '00',
        };

        this.saveComment = this.saveComment.bind(this);
        this.handleImageUpload = this.handleImageUpload.bind(this);
        this.handleImageSelect = this.handleImageSelect.bind(this);
        this.handleMarkComplete = this.handleMarkComplete.bind(this);
        this._clickConfrim = this._clickConfrim.bind(this);
        this.nextWorkout = this.nextWorkout.bind(this);

    }

    tick = () => {
        let z = '';
        let disp_milisec = '00';
        let disp_secs = '00';
        let disp_minute = '00';

        z = (this.state.milisec < 10) ? '0' : '';
        disp_milisec = z + this.state.milisec;
        this.setState({ milisec: this.state.milisec + 1, disp_milisec: disp_milisec });

        if (this.state.milisec == 90) {
            this.state.milisec = 0;
            z = (this.state.sec < 10) ? '0' : '';
            disp_secs = z + this.state.sec;
            this.setState({ sec: this.state.sec + 1, disp_secs: disp_secs });

            if (this.state.sec == 60) {
                this.state.sec = 0;
                z = (this.state.minute < 10) ? '0' : '';
                disp_minute = z + this.state.minute;
                this.setState({ minute: this.state.minute + 1, disp_minute:disp_minute });
            }
        }
    }

    _removeImage = (el_id="", index="0") => {

    	if(el_id!='') {
    		console.log("removing image...");
    		let newselected= this.state.selectedImages.splice(index, 1);
    		window.$('#'+el_id).remove();
    	}
    }

    _deleteImage = (id="", el_id="") => {
    	// console.log("delete image..."+id);
    	if(id!='') {
    		const requestOptions = {
    		    method: 'POST',
    		    headers: { 'Content-Type': 'application/json' },
    		    body: JSON.stringify({ mode: 'DELETE_WOIMG', clientid:this.state.customerid, pic_id:id})
    		};

    		fetch(global.config.bURL+"global_data.php", requestOptions)
    		    .then(function (response) {
    		        return response.json();
    		    })
    		    .then((result)=> {
    		        // console.log('Chat: '+result);
    		        let notification = "";
    		        let notify_type = "";

    		        if(result.DELETED=='Y') {
    		        	notification = "Image Deleted Successfully";
    		        	notify_type = "success";
    		        }
    		        else if(result.DELETED=='N') {
    		        	notification = "Image could not be deleted";
    		        	notify_type = "error";
    		        }

    		        this.setState({
    		        	Notifications: notification,
    		        	notifType: notify_type,
    		        })

    				window.$('#'+el_id).remove();
    		        window.$('#ModalAlert').modal('show');
    		        window.$('#ModalBasic').modal('hide');
    		        
    		        this.getWorkoutSets();
    		    });
    	}
    }
    
    startTimer = () =>{
        this.interval = setInterval(this.tick,10);
        this.setState({ paused : false });
    }
    
    stopTimer = () => {
        clearInterval( this.interval );
        this.setState({ paused : true });
    }
    
    reset = () => {
        this.setState({ 
            minute: 1,
            sec: 1, 
            milisec: 1,
            disp_milisec: '00',
            disp_secs: '00',
            disp_minute: '00',
            paused: true 
        });
        clearInterval( this.interval );
    }

    _clickConfrim() {
        // alert('confirm value captured....');

        this.setState({ 
            LOADING: true,
        });

        var that = this;
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ mode: 'MARK_COMPLETED', clientid: this.state.customerid, woid: this.state.workoutID, sc_id: this.state.scheduleID})
        };

        fetch(global.config.bURL+"global_data.php", requestOptions)
            .then(function (response) {
                return response.json();
            })
            .then(function (result) {
                let notify = "Completed";
                let notify_type = "success";

                if(result.err!='') {
                    if (result.err == '1') {
                        notify = 'Invalid Access Detected';
                        notify_type = 'error';
                    }
                }

                console.log(result);

                that.setState({ 
                    LOADING: false,
                    Notifications: notify,
                    notifType: notify_type,
                    WO_COMPLETED: result.COMPLETED,
                    nxtid: result.NEXT_ID,
                    nxttype: result.TYPE,
                    nxtscid: result.SCHEDULE_ID,
                });

                $('#submitLoading').css('display', 'none');
                // window.$("#ModalBasic").modal('hide');

                if (notify != '')
                    window.$('#ModalAlert2').modal('show');
            });
    }

    nextWorkout() {
        if(this.state.nxtid!="") {
        	this.setState({
        		nextredirect: 'Y',
        		nexturl: '/WorkoutStart/'+this.state.nxttype+'/'+this.state.nxtscid+'/'+this.state.nxtid,
        		LOADING: true,
        	})
        }
    }

    saveComment = (e) => {
        e.preventDefault();

        let err = 0;
        if ($('#frmComment')[0].checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            err++;
        }
        $('#frmComment')[0].classList.add('was-validated');

        if (err == 0) {
            $('#submitLoading').css('display', 'block');

            const method = "POST";
            const body = new FormData(this.form);
            var that = this;


            fetch(global.config.bURL+"global_data.php", { method, body })
                .then(function (response) {
                    //console.log(response);
                    return response.json();
                })
                .then(function (result) {

                    let notify = "Saved";
                    let notify_type = "success";

                    if(result.err!='') {
                        if (result.err == '1') {
                            notify = 'Invalid Access Detected';
                            notify_type = 'error';
                        }
                    }

                    that.setState({ 
                        Notifications: notify,
                        notifType: notify_type,
                    });

                    $('#submitLoading').css('display', 'none');

                    window.$("#ModalBasic_c").modal('hide');

                    if (notify != '')
                        window.$('#ModalAlert').modal('show'); 
                });
        }
    }

    handleImageUpload (e) {
        e.preventDefault();

        let err = 0;
        if ($('#frmImages')[0].checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            err++;
        }
        $('#frmImages')[0].classList.add('was-validated');

        if (err == 0) {
            $('#submitLoading').css('display', 'block');

            const method = "POST";
            const body = new FormData(this.form);
            var that = this;

            fetch(global.config.bURL+"global_data.php", { method, body })
                .then(function (response) {
                    //console.log(response);
                    return response.json();
                })
                .then(function (result) {
                	// console.log(result);
                    let notify = "";
                    let notify_type = "";

                	if(result.UPLOADED =='Y') {
                		notify = "Saved";
                    	notify_type = "success";
                	}
                	else if(result.UPLOADED =='S') {
                		notify = "Some Images could not be uploaded, please upload images less than 1 mb.";
                    	notify_type = "error";	
                	}
                	else if(result.UPLOADED =='N') {
                		notify = "Images could not be uploaded, please try again later.";
                    	notify_type = "error";	
                	}

                    if(result.err!='') {
                        if (result.err == '1') {
                            notify = 'Invalid Access Detected';
                            notify_type = 'error';
                        }
                    }

                    that.setState({ 
                        Notifications: notify,
                        notifType: notify_type,
                        selectedImages: "",
                    });

                    $('#submitLoading').css('display', 'none');

                    window.$("#ModalBasic").modal('hide');

                    if (notify != '')
                        window.$('#ModalAlert').modal('show'); 
                    
                    that.getWorkoutSets();
                });
        }
    }

    handleImageSelect (e) {
    	let files = [];
        files = Array.from(e.target.files);
        let selImages = [];

        files.forEach(function(item, index) {
            // console.log(item);
            let img_uri = URL.createObjectURL(item);
            selImages.push(img_uri);
        });

        // console.log(selImages);

        this.setState({
            selectedImages: selImages,
        })
    }

    handleMarkComplete () {
        this.setState({
            Notifications: "You are about mark this complete, Continue?",
        })
        window.$('#ModalConfirm').modal('show');
    }

    getWorkoutSets() {
        let url_str = "schedule_exercise.php?mode=SCHEDULE_EXERCISE&customer="+this.state.customerid
                        +"&s_id="+this.state.subscriptionID
                        +"&sc_id="+this.props.match.params.scheduleid
                        +"&sc_e_id="+this.props.match.params.workoutid
                        +'&type='+this.props.match.params.type
                        +'&lvl_id='+this.props.match.params.lvl_id
                        +'&week_id='+this.props.match.params.week_id
                        +'&day_id='+this.props.match.params.day_id
                        +'&s_date='+this.props.match.params.s_date

        fetch(global.config.bURL+url_str, {method:'POST', headers:{ 'Content-Type': 'application/json' }})
            .then(function (response) {
                return response.json();
            })
            .then((result)=> {
                // console.log(result);
                let responseJson = result;
                let Completed = responseJson.SCHEDULE_EXERCISE[0]?responseJson.SCHEDULE_EXERCISE[0].COMPLETED:'N';
                this.setState({ schedule: responseJson.SCHEDULE, 
                	workoutSets: responseJson.SCHEDULE_EXERCISE[0]?responseJson.SCHEDULE_EXERCISE[0]:"", 
                	workoutName: responseJson.SCHEDULE_EXERCISE[0]?responseJson.SCHEDULE_EXERCISE[0].WORKOUT_NAME:"",
                	WO_COMPLETED:Completed, 
                	LOADING: false, 
                	Logged: true 
                });
            });

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ mode: 'EXERCISE_DAT', txtwoid:this.props.match.params.workoutid })
        };

        fetch(global.config.bURL+"global_data.php", requestOptions)
            .then(function (response) {
                return response.json();
            })
            .then((result)=> {

                this.setState({
                    WO_COMMENT: result.COMMENT,
                    WO_IMAGES: result.IMAGES,
                })
            });
    }

    componentWillMount() {
        let mount = $("div").hasClass("modal-backdrop");
        if (mount == true) {
            $('body').removeClass('modal-open');
            $(".modal-backdrop").remove();
            //window.location.reload(true);
        } 
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.match.params.type !== this.props.match.params.type || prevProps.match.params.workoutid !== this.props.match.params.workoutid ) 
        {
            console.log('component updated...');
            window.location.reload(); 
            // this.getWorkoutShedule();
        }
    }

    componentDidMount() {
        this.getWorkoutSets();
    }

    render() {
        let AppHeaderClass = 'appHeader no-border';
        let AppHeaderLeft = 'showLINK';
        let AppHeaderLeftLink = '/WorkoutDetail/'+this.props.match.params.type+'/'+this.props.match.params.scheduleid+'/'+this.state.subscriptionID;
        let AppHeaderTitle = this.state.workoutName;
        let AppHeaderRight = '';
        let AppHeaderRightLink = '';

        let detail_str = "";

        detail_str += (this.state.workoutSets.SETS!=0)?this.state.workoutSets.SETS+' Sets | ':''
        detail_str += (this.state.workoutSets.REPS!=0)?this.state.workoutSets.REPS+' Reps | ':''
        detail_str += (this.state.workoutSets.TIME>0)?'Time '+secondsToHms(this.state.workoutSets.TIME,this.state.workoutSets.TIMETYPE)+' | ':''
        detail_str += (this.state.workoutSets.REST!=0)?'Rest '+secondsToHms(this.state.workoutSets.REST, this.state.workoutSets.RESTTIMETYPE)+'  ':''

        let image_src = (this.state.workoutSets.WORKOUT_IMAGE)?this.state.workoutSets.WORKOUT_IMAGE:global.config.tlcImage;


        if(!this.state.customerid) {
            localStorage.setItem('userData',"");
            return (<Redirect to={'/'}/>);
        }

        if(!this.state.scheduleID || !this.state.workoutID) {
            return (<Redirect to={'/Workout'}/>);
        }

        if(this.state.nextredirect=='Y' && this.state.nexturl!="")
        {
            return (<Redirect to={this.state.nexturl}/>);
        }

        return(
            <div>
                {this.state.LOADING && <Loader />}

                {this.state.workoutSets &&
                	<AppHeader AppHeaderClass={AppHeaderClass} AppHeaderLeft={AppHeaderLeft} AppHeaderLeftLink={AppHeaderLeftLink} AppHeaderTitle={AppHeaderTitle} AppHeaderRight={AppHeaderRight} AppHeaderRightLink={AppHeaderRightLink} />
                }

                <div id="appCapsule" className="pb-5">
                        <div className="section workout-detail with-noborder full mb-0 pb-0 pt-0">
                            {this.state.workoutSets && this.state.workoutSets.DAT.length > 0 &&
                                <WorkoutSlider2 slider={this.state.workoutSets.DAT} />
                            }

                            {this.state.workoutSets && this.state.workoutSets.DAT.length == 0 &&
                                <div className="section workout-detail full mb-0 pb-0 pt-0">
                                     <div className="item video-cover">
                                         <div className="video-area">
                                             <iframe className="yt-video" src={"https://www.youtube.com/embed/"+this.state.workoutSets.WORKOUT_VIDEOLINK+'?rel=0'} frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen> </iframe>
                                         </div>
                                     </div>
                                </div>
                            }
                        </div>

                   <div className="workout-start">
                      <ul className="new-layout listview image-listview media pt-0 pb-0 mb-0 no-border">
                         <li>
                            <div className="item">
                               {/*<div className="imageWrapper">
                                  <img src={image_src} alt="image" className="imaged w76" />
                               </div>*/}
                               <div className="in starts2">
                                  <div className="new-outer text-gre5 ft-wt-500 font-15">
                                        {/*this.state.workoutSets.WORKOUT_NAME*/}
                                        <div className="text-muted pt-0 mt-0 ft-wt-400">
                                            <div className="text-left">
                                                {this.state.workoutSets.DESC && <p dangerouslySetInnerHTML={{ __html: this.state.workoutSets.DESC }} /> }
                                            </div>
                                            {/*<Link to={'/Timer/'+this.state.workoutType+'/'+this.state.scheduleID+'/'+this.state.workoutID} className="btn btn-outline-secondary rounded btn-ssm mb-0 font-11">Start</Link>*/}
                                        </div>
                                  </div>

                                  	
                               </div>
                            </div>
                         </li>
                      </ul>
                      <div className="section work-timer short-timer pb-0" style={{'width':'98%'}}>
                      				<div className="row">
                              	        <div className="col-6 time-disp text-center timer-area mb-0 pr-0 pl-0">
                          	                <div className="time-display">
                          	                    <Counter min={this.state.disp_minute} secs={this.state.disp_secs} milisecs={this.state.disp_milisec}  />
                          	                    <input type="hidden" name="txttimer" id="txttimer" value={this.state.disp_minute+":"+this.state.disp_secs+":"+this.state.disp_milisec} />
                          	                </div>
                              	        </div>
                              	        <div className="col-6">
	                              	        <div className="row">
	                              	            <TimerControl 
	                              	              paused={this.state.paused} 
	                              	              start={this.startTimer} 
	                              	              stop={this.stopTimer} 
	                              	            />
	                              	            <Reset  onClickReset={this.reset}/>
	                              	        </div>
                              	        </div>
                              	    </div>
                       </div>
                   </div>

                    <div className="section rounds workout-status pt-3 pb-3">
                        <div className="acc-options accordion p-1" id="accordionExample2">
                            {this.state.workoutSets.ID == this.state.workoutID &&

                                <div className="item">
                                    <div className="accordion-header">
                                        <div className="in">
                                            <div className="text-gre5 ft-wt-600 font-15">
                                                {this.state.workoutSets.WORKOUT_NAME}
                                                <div className="text-muted ft-wt-400">
                                                    {this.state.workoutSets.WORKOUT_INSTRUCTIONS}
                                                    <div className="text-muted ft-wt-500 text-gre7 font-14"> { detail_str.slice(0, -2) } </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            {this.state.workoutSets.ID && this.state.workoutSets.DAT.length > 0 &&
                                this.state.workoutSets.DAT.map((w_dat, i)=> {
                                    
                                    let detail_str2 = "";

                                    detail_str2 += (w_dat.SETS!=0)?w_dat.SETS+' Sets | ':'';
                                    detail_str2 += (w_dat.REPS!=0)?w_dat.REPS+' Reps | ':'';
                                    detail_str2 += (w_dat.TIME>0)?'Time '+secondsToHms(w_dat.TIME,w_dat.TIMETYPE)+' | ':'';
                                    detail_str2 += (w_dat.REST!=0 && w_dat.REST!=undefined)?'Rest '+secondsToHms(w_dat.REST,w_dat.RESTTIMETYPE)+'  ':''

                                    return(
                                        <div className="item">
                                            <div className="accordion-header">
                                                <div className="in">
                                                    <div className="text-gre5 ft-wt-600 font-15">
                                                        {w_dat.WORKOUT_NAME}
                                                        <div className="text-muted ft-wt-400">
                                                            {w_dat.WORKOUT_INSTRUCTIONS}
                                                            <div className="text-muted ft-wt-500 text-gre7 font-14"> { detail_str2.slice(0, -2) } </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>

                        {/*<div className="custom-control custom-checkbox mb-1">
                            <input type="checkbox" value= className="custom-control-input" id="customCheckb1" />
                            <label className="custom-control-label" htmlFor="customCheckb1"> </label>
                        </div>*/}
                        <div className="row">
                            <div className="col-4 mt-2">
                                <button type="button" onClick={this.state.WO_COMPLETED!='Y'?this._clickConfrim:''} className={"btn btn-outline-primary rounded btn-brem btn-block text-upper "+(this.state.WO_COMPLETED=='Y'?'complete-active':'') }>
                                    { (this.state.WO_COMPLETED=='Y')?'Completed':'Mark Complete' } 
                                </button>
                            </div>
                            <div className="col-4 mt-2">
                                <button type="button" className="btn btn-outline-primary rounded btn-brem btn-block text-upper" data-toggle="modal" data-target="#ModalBasic_c">Add Comment</button>
                            </div>
                            
                            <div className="col-4 mt-2">
                                <button type="button" className="btn btn-outline-primary rounded btn-brem btn-block text-upper" data-toggle="modal" data-target="#ModalBasic">Upload Image</button>
                            </div>
                        </div>
                   
                       {/* // COMMENT MODAL : start // */}
                        <div className="modal fade modalbox" id="ModalBasic_c" tabIndex="-1" role="dialogName">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="appHeader no-border">
                                        <div className="left">
                                            <Link to={"#"} data-dismiss="modal" className="headerButton">
                                                <ion-icon name="chevron-back-outline"></ion-icon>
                                            </Link>
                                        </div>
                                        
                                        <div className="pageTitle">Enter your Comment</div>
                                        <div className="right"></div>
                                    </div>

                                    <div className="modal-body">
                                        <form id="frmComment" ref={el =>
                                            (this.form = el)} onSubmit={this.saveComment} className="needs-validation" noValidate>
                                            <input type="hidden" value="COMMENT" className="form-control" name="mode" id="mode" />
                                            <input type="hidden" value={this.state.workoutSets.ID} className="form-control" name="txtwo_id" id="txtwo_id" />

                                            <div className="form-group mb-3">
                                                <div className="input-wrapper">
                                                    <textarea type="text" className="form-control" rows="4"  id="txtcomment" name="txtcomment" placeholder="" required="required" defaultValue={this.state.WO_COMMENT}></textarea>
                                                    <i className="clear-input">
                                                        <ion-icon name="close-circle"></ion-icon>
                                                    </i>
                                                    <div className="valid-feedback">&nbsp;</div>
                                                    <div className="invalid-feedback">Please enter a comment.</div>
                                                </div>
                                            </div>
                                            
                                            <button type="submit" className="btn btn-coach-blue rounded btn-block btn-smid text-upper">
                                                <span className="spinner-border spinner-border-sm mr-05" id="submitLoading" style={{ display: 'none' }} role="status" aria-hidden="true"></span>
                                                Save
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                       </div>
                       {/* // COMMENT MODAL : end // */}

                       {/* // IMAGE MODAL : start // */}
                        <div className="modal fade modalbox" id="ModalBasic" tabIndex="-1" role="dialogName">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="appHeader no-border">
                                        <div className="left">
                                            <Link to={"./"} data-dismiss="modal" className="headerButton">
                                                <ion-icon name="chevron-back-outline"></ion-icon>
                                            </Link>
                                        </div>
                                        
                                        <div className="pageTitle">Upload Results</div>
                                        <div className="right"></div>
                                    </div>
                                    
                                    <div className="modal-body">
                                        <div className="section payment-opts full mt-3 pt-2 mb-0 pl-0 pr-0">
                                            <div className="wide-block bg-transparent pt-0 pb-0 pl-0 pr-0 no-border text-center">
                                                <form id="frmImages" ref={el =>(this.form = el)} className="needs-validation" noValidate onSubmit={this.handleImageUpload} encType="multipart/form-data">
                                                    <input type="hidden" name="mode" id="mode" readOnly value="IMAGES" />
                                                    <input type="hidden" name="txtwo_id" readOnly value={this.state.workoutSets.ID} />
                                                    <input type="hidden" name="clientid" readOnly value={this.state.customerid} />
                                                    <div className="ot">
                                                        <div className="otin imaged w160 mar-auto mb-6">
                                                            {this.state.selectedImages.length > 0  &&
                                                                this.state.selectedImages.map((image_src, i) => {
                                                                    
                                                                    return(
                                                                        <div className="upload-contain" id={'disp_'+i} key={'disp_'+i}>
                                                                            <img
                                                                                key = {i} 
                                                                                src= {image_src} 
                                                                                alt= "image" 
                                                                                className="imaged img-fluid m-auto"
                                                                            />

                                                                            {/*<button type="button" className="up-delete btn btn-danger rounded btn-ssm mb-0 font-11" 
                                                                            	onClick={()=>{this._removeImage('disp_'+i)}} >
                                                                            	Delete</button>*/}
                                                                        </div>
                                                                    );

                                                                })
                                                            }
                                                            {this.state.WO_IMAGES.length > 0  &&
                                                                this.state.WO_IMAGES.map((image_src, i) => {
                                                                    
                                                                    if(image_src.IMAGE!='')
                                                                    {
	                                                                    return(
	                                                                        <div className="upload-contain" id={'img_'+i} key={'img_'+i}>
	                                                                        <img
	                                                                            key = {i} 
	                                                                            src= {image_src.IMAGE} 
	                                                                            alt= "image" 
	                                                                            className="imaged img-fluid m-auto"
	                                                                        />
	                                                                            <button type="button" className="up-delete btn btn-danger rounded btn-ssm mb-0 font-11"
	                                                                            onClick={()=>{this._deleteImage(image_src.ID, 'img_'+i)}}
	                                                                            >Delete</button>
	                                                                        
	                                                                        </div>
	                                                                    );
	                                                                }

                                                                })
                                                            }
                                                        </div>
                                                         <div className="custom-file-upload">
                                                                <input type="file" id="fileuploadInput" name="fileuploadInput[]" accept=".png, .jpg, .jpeg" multiple="multiple" onChange={this.handleImageSelect} ref={input => this.fileInput = input} />
                                                                <label htmlFor="fileuploadInput">
                                                                    <span>
                                                                        <strong>
                                                                            <ion-icon name="cloud-upload-outline"></ion-icon>
                                                                            <i>Tap to select images</i>
                                                                        </strong>
                                                                    </span>
                                                                </label>
                                                            </div>
                                                    </div>
                                                    
                                                    <button type="submit" className="btn btn-coach-blue rounded btn-block btn-smid text-upper">Upload</button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                       </div>
                       {/* // IMAGE MODAL : end // */}

                    </div>
                </div>


                <ModalAlert Notifications={this.state.Notifications} type={this.state.notifType} />

                <div className="modal fade dialogbox" id="ModalAlert2" data-backdrop="static" tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            {this.state.notifType=='error' &&
                                <div className="modal-icon text-danger">
                                    <ion-icon name="close-circle"></ion-icon>
                                </div>
                            }

                            {this.state.notifType=='success' &&
                                <div className="modal-icon text-success">
                                    <ion-icon name="checkmark-circle"></ion-icon>
                                </div>   
                            }

                            <div className="modal-body"> 
                                    {this.state.Notifications} 
                            </div>
                            <div className="modal-footer">
                                <div className="btn-inline"> 
                                    <button type="button" onClick={this.nextWorkout} className="btn" data-dismiss="modal">Ok</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade dialogbox" id="ModalConfirm" data-backdrop="static" tabIndex="-1" role="dialog">
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title">{this.state.modalTitle}</h5>
                      </div>
                      <div className="modal-body">{this.state.Notifications}</div>
                      <div className="modal-footer">
                        <div className="btn-inline"> 
                            <button type="button" className="btn btn-text-secondary" data-dismiss="modal">CANCEL</button> 
                            <button type="button" onClick={this._clickConfrim} className="btn btn-text-primary" data-dismiss="modal">CONFIRM</button> 
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <AppBottomMenu activeClass={"workout"} />
            </div>
        );
    }
}

export default WorkoutDetail;