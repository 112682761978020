import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import AppHeader from './AppHeader';
import Loader from './Loader';
import AppBottomMenu from './AppBottomMenu';
import ModalAlert from './ModalAlert';
import $ from "jquery";

class UpdateProgress extends Component {
	constructor(props) {
		super(props);
		this.state = {
            LOADING: true,
            Notifications: false,
            Logged: false,
            profiledata: [],
            measurmentFIELD: [],
            UPDATED: "N",
            customerid: localStorage.getItem('userData'),
        }

        this.handleProgressUpdate = this.handleProgressUpdate.bind(this);
	}

	getMeasurementFields() {
		const requestOptions = {
		    method: 'POST',
		    headers: { 'Content-Type': 'application/json' },
		    body: JSON.stringify({ mode: 'GEN_MEASUREMENT', s_id: localStorage.getItem('subscriptionID'), clientid: this.state.customerid })
		};

		fetch(global.config.bURL+"global_data.php", requestOptions)
		    .then(function (response) {
		        return response.json();
		    })
		    .then((result)=> {
		        console.log(result);

		        this.setState({
		        	measurmentFIELD: result.MEASUREMENT,
		        	LOADING: false,
		        })
		    });
	}

	handleProgressUpdate(evt) {
		evt.preventDefault();
		// console.log('submitting the new profile here...');

		let err = 0;
		if ($('#frmProfile')[0].checkValidity() === false) {
		    evt.preventDefault();
		    evt.stopPropagation();
		    err++;
		}
		$('#frmProfile')[0].classList.add('was-validated');

		let notify = "";
		let notify_type = "";
		let filePic = $('#file_pic').val();

		if (notify != '')
		    window.$('#ModalAlert').modal('show'); 

		if(err==0)
        {
		    const method = "POST";
		    const body = new FormData(this.form);
		    var that = this;

		    fetch(global.config.bURL+"customer_measurement_update.php", { method, body })
		        .then(function (response) {
		            //console.log(response);
		            return response.json();
		        })
		        .then(function (result) {
		            let responseJson = result;
		            notify = "Measurements Updated Successfully";
		            notify_type = "success";

		            if (result.err != '') {
		                if(result.err >= 0) notify_type = "error";

		                if (result.err == '1') notify = 'Invalid Access Detected!';
		            }

		            that.setState({
		                UPDATED: result.UPDATED,
		                LOADING: false, 
		                Logged: true,
		                Notifications: notify,
		                notifType: notify_type,
		            });

		            if (notify != '')
		                window.$('#ModalAlert').modal('show'); 
		        });
		}
	}

	componentDidMount() {
		this.getMeasurementFields();
	}

	render() {
		if (!this.state.customerid) {
		    localStorage.setItem('userData','');
		    return (<Redirect to={'/'}/>)
		}

		let AppHeaderClass = 'appHeader no-border bg-common-header text-light';
		let AppHeaderLeft = 'showLINK';
		let AppHeaderLeftLink = '/Profile';
		let AppHeaderTitle = 'Progress';
		let AppHeaderRight = '';
		let AppHeaderRightLink = '';

		if(this.state.UPDATED=='Y') {
			return( <Redirect to={'/Profile'} />);
		}

		return(
			<div>
			    {this.state.LOADING && <Loader />}
			    
			    <AppHeader AppHeaderClass={AppHeaderClass} AppHeaderLeft={AppHeaderLeft} AppHeaderLeftLink={AppHeaderLeftLink} AppHeaderTitle={AppHeaderTitle} AppHeaderRight={AppHeaderRight} AppHeaderRightLink={AppHeaderRightLink} />

			    <div id="appCapsule">
			    	<div className="section payment-opts full mt-0 pt-2 mb-0 pl-3 pr-3">
			    		<div className="wide-block bg-transparent pt-0 pb-0 pl-0 pr-0 no-border text-center">
			    			<img src={localStorage.getItem('userProfileImg')} alt="image" className="imaged w86 m-auto" />
			    			<h4 className="text-upper mt-2 mb-0 pb-0">Enter your measurments</h4>
			    		</div>
			    	</div>
			    	<form id="frmProfile" ref={el =>(this.form = el)} className="needs-validation" noValidate onSubmit={this.handleProgressUpdate} encType="multipart/form-data">
			    		<input type="hidden" name="clientid" id="clientid" readOnly value={this.state.customerid} />
			    		<div className="section pr-3 pl-3 pb-1 pt-0 basic-form">
			    			<div className="form-group basic">
			    			{this.state.measurmentFIELD.length > 0 &&
	    						this.state.measurmentFIELD.map((field_elem, i)=>{
	    							let classLR = "";
	    							let clearDIV = "";

	    							if(i%2==0) {
	    								classLR = 'divide-50l';
	    							}
	    							else { 
	    								classLR = 'divide-50r';
	    								clearDIV = "clear";
	    							}

	    							return(
	    								<div>
		    			    				<div className={classLR}>
		    			    					<div className="input-wrapper guest-detail float-label">
		    			    						<input type="hidden" name="txtmid[]" defaultValue={field_elem.ID} />
		    			    						<input type="hidden" name="mode" defaultValue="CLIENT_MEASUREMENT_UPDATE" />
		    			    						<input type="hidden" name="customer" defaultValue={this.state.customerid} />
		    			    						<input type="hidden" name="s_id" defaultValue={localStorage.getItem('subscriptionID')} />

		    			    						<input type="text" className="input-box inputText form-control" required="required" minLength="2" maxLength="3" pattern="[0-9]{2,3}" name={"txtmid_"+field_elem.ID} id={"txtmid_"+field_elem.ID} />
		    			    						<label className="floating-label">{field_elem.NAME}</label>
		    			    						<i className="clear-input">
		    			    							<ion-icon name="close-circle" role="img" className="md hydrated" aria-label="close circle"></ion-icon>
		    			    						</i>
		    			    						<div className="valid-feedback">&nbsp;</div>
		    			                            <div className="invalid-feedback">Please enter numeric values min 2 and max 3 digits.</div>
		    			    					</div>
		    			    				</div>
		    			    				<div className={clearDIV}></div>
	    			    				</div>
	    							);
	    						})
	    					}
			    			</div>

			    			<div className=" mt-1 text-left">
			    				<div className="custom-control custom-checkbox">
			    					<input type="checkbox" className="custom-control-input form-control" id="customChecka1" required="required" />
			    					<label className="custom-control-label text-gre4 font-12" htmlFor="customChecka1">
			    						<span>The measurments are taken recently and are best for my knowledge.</span>
			    					</label>

		    						<div className="valid-feedback">&nbsp;</div>
                                    <div className="invalid-feedback">Please tick the box to proceed.</div>
			    				</div>
			    			</div>
			    		</div>
			    		<div className="section double mt-1 mb-5">
			    			<div className="form-button-group">
				    			<span className="spinner-border spinner-border-sm mr-05" id="submitLoading" style={{ display: 'none' }} role="status" aria-hidden="true"></span>
			    				<button type="submit" className="btn btn-coach-blue rounded btn-block btn-mid text-upper">Update</button>
			    			</div>
			    		</div>
			    	</form>
			    </div>

			    <ModalAlert Notifications={this.state.Notifications} type={this.state.notifType} />
			</div>
		);
	}
}

export default UpdateProgress;