import React from 'react';
import ReactDOM from 'react-dom';
import { Link, Redirect } from 'react-router-dom';

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import $ from 'jquery';


/*function _playVideo(title="", code="") {
	console.log('popup called...');
	// console.log(title);
	// console.log(code);
	// this.setState({
	// 	WO_TITLE:
	// 	WO_VCODE:
	// })
	window.$('#playVideo').modal('show'); 
}*/

const WorkoutSlider = (props) => {
    let Items = "";

    // console.log(props);
    let pi = [];
    Items = props.slider.map((s_slider, i) => {
    	pi[i] = s_slider.COMPLETED;
	    let prevItem = i-1;
    	// console.log(s_slider.TYPE);
    	console.log(i);
    	// console.log(prevItem);

    		if(s_slider.WORKOUT_VIDEOIMG!='')
    		{
	            return (
	                    <div className="item video-cover " key={'slider_'+i} onClick={()=>{props._playVideo(s_slider.WORKOUT_NAME, s_slider.WORKOUT_VIDEOLINK)}}>
	                    	<button type="button" className="video-btn">
	                        	<div className="video-area">
	                                {/*<iframe src={"https://www.youtube.com/embed/"+s_slider.WORKOUT_VIDEOLINK} height="250px" frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen> </iframe>*/}
	                               	<img src={s_slider.WORKOUT_VIDEOIMG} alt={s_slider.WORKOUT_NAME} className="w-100" />
	                                {/*<ion-icon name="play"></ion-icon>*/}
	                        	</div>
	                        </button>
	                        <div className="video-text video-icon">
	                            <h5 className="m-0 ft-wt-400 text-gre6 font-12">{s_slider.WORKOUT_NAME}</h5>
	                            <button type="button" className="btn btn-coach-pink rounded play-mid  text-upper"><ion-icon name="play"></ion-icon></button>
	                        </div> 
	                    </div>
	            );
	        }
	        else
	        {

	        	let start_url = "#";
	        	let detail_str = "";
	        	let image_src = (s_slider.WORKOUT_VIDEOIMG!="")?s_slider.WORKOUT_VIDEOIMG:global.config.tlcImage;
	        	let prevCompleted = "Y";
	        	let prevCompleted2 = "";
	        	let fnCompleted = "";//(event) => event.preventDefault();

	        	if(prevItem >= 0) {
	        	    prevCompleted = pi[prevItem];
	        	}

	        	if(i==0 && props.type=='E'){
	        	    prevCompleted = props.WARMUP_COMPLETE;
	        	}
	        	else if(i==0 && props.type=='C') {
	        	    prevCompleted = props.COOLDOWN_COMPLETE;       
	        	}

	        	if(props.scheduleid != 0) {
	        	    start_url = '/WorkoutStart/'+props.type+'/'+props.scheduleid+'/'+s_slider.ID;
	        	}

	        	if(prevCompleted == 'N' || prevCompleted==undefined) {
	        	    fnCompleted = (e) =>props.notCompleted(e);
	        	    start_url = "#";
	        	}

	        	if(prevCompleted=='' || props.scheduleid== 0) {
	        		fnCompleted = (e) =>props.pressStart(e);
	        	    start_url = "#";	
	        	}

	        	return (
	        	        <div className="item video-cover tlc-image" key={'slider_'+i}>
	        	        	<Link to={start_url} className="video-btn" onClick={fnCompleted}>
	        	            	<div className="video-area">
	        	                   	<img src={image_src} alt={s_slider.TYPE} className="w-100" />
	        	            	</div>
	        	            </Link>
	        	            <div className="video-text video-icon">
	        	                <h5 className="m-0 ft-wt-600 text-gre6 font-12">{s_slider.TYPE}</h5>
	        	            </div> 
	        	        </div>
	        	);	
	        }
        });

    const options = {
        items: 2,
        nav: false,
        loop: false,
        dots: false,
        stagePadding: 30,
        rewind: false,
    };

    return (
        <div>
            <OwlCarousel className="owl-theme" loop margin={15} {...options}>
                    {Items}
            </OwlCarousel>
        </div>
    );
}

export default WorkoutSlider;