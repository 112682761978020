import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import AppHeader from './AppHeader';
import Loader from './Loader';
import SelectProgramSlider from './SelectProgramSlider';
import AppBottomMenu from './AppBottomMenu';
import ModalAlert from './ModalAlert';
import Moment from 'react-moment';
import { CompareDate, formatDate } from './Common';
import HomeSlider from './HomeSlider';
import $ from "jquery";

class Home extends Component {
    constructor(props) {
            super(props);
            this.state = {
            Notifications: false,
            notifType: "",
            sliderImages: "",
            userHome: [],
            subscriptiondata: [],
            redirectToProgress: "N",
            customerid: localStorage.getItem('userData'),
            TODAY: formatDate(new Date()),
            schedule: "",
            subscription: "",
            WO_COMPLETED: "",
            ALLOWED: "",
            alertTitle: "",
            alertNotif: "",
            userINSTA: [],
            redirectRenewal: "N",
            redirectPayment: "N",
            BATCH_PROGRAM: '',
        };

        this._checkPROGRESS = this._checkPROGRESS.bind(this);
        this._clickConfrim = this._clickConfrim.bind(this);
    }

    _clickConfrim () {
    	this.setState({
    		redirectPayment: "Y",

    	})
    }

    _checkPROGRESS = (flag="N", date="") => {
    	
    	if(flag=='Y')
    	{
    		this.setState({
    			redirectToProgress: "Y",
    		})
    	}
    	else {
    		this.setState({
    			Notifications: "Your Progress Update Date is "+date,
    			notifType: "error",
    		})

    		window.$('#ModalAlert').modal('show'); 
    	}

    }

    GetUserDetails() {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ mode: 'WEB_HOME', clientid: this.state.customerid})
        };

        fetch(global.config.bURL+"global_data.php", requestOptions)
            .then(function (response) {
                return response.json();
            })
            .then((result)=> {
                let responseJson = result;

                this.setState({ 
                	subscriptiondata:responseJson.SUBSCRIPTION, 
                  BATCH_PROGRAM: responseJson.PERSONAL_TRAINING,
                	userHome: responseJson.USER, 
                	userINSTA: responseJson.INSTAGRAM_FEED, 
                	LOADING: false, 
                	Logged: true 
                });

                // localStorage.setItem('batchProgramID', this.state.BATCH_PROGRAM.BATCH_PROGRAM_ID);
            });
    }

    componentWillMount() {
    	let mount = $("div").hasClass("modal-backdrop");

    	if (mount == true) {
    	    $('body').removeClass('modal-open');
    	    $(".modal-backdrop").remove();
    	} 
    }

    componentDidMount() {
    	this.GetUserDetails();
    }

    render() {
        let AppHeaderClass = 'appHeader no-border bg-common-header text-light';
        let AppHeaderLeft = 'showHOME';
        let AppHeaderLeftLink = '/Home';
        let AppHeaderTitle = 'THATLIFESTYLECOACH';
        let AppHeaderRight = true;
        let AppHeaderRightLink = '';
        
        let title_str = "";
        let message_text = "";
        let btn_txt = "";
        let btn_link = "";
        let textClass = "";

        if(!this.state.customerid) {
          localStorage.setItem('userData',"");
          return (<Redirect to={'Logout'}/>);
        }

        if(!localStorage.getItem('userData')) {
		  	return ( <Redirect to="/Logout" />);      	
        }

        if(this.state.redirectPayment=='Y') {
        	return (<Redirect to={'/ContinuePayment'}/>);
        }

        if(this.state.subscriptiondata.STATUS=='P') {
        	title_str = "Plan Details";
        	message_text = "You have a pending payment";
        	btn_txt = "Pay Now";
        	btn_link = "/OnlinePay";
        	textClass = "home-sub";
        }
        else if(this.state.subscriptiondata.STATUS=='X' || this.state.TODAY > this.state.subscriptiondata.END ) {
        	title_str = "Last Plan Details";
        	message_text = "Your plan has expired";	
        	btn_txt = "Renew Plan";
        	btn_link = "/Subscription";
        }
        else if(this.state.subscriptiondata.STATUS=='A') {
        	title_str = "Current Active Plan";
        	message_text = "Subscription ends on "+this.state.subscriptiondata.EXP_DATE;
        	btn_txt = "Upgrade";
        	btn_link = "/Subscription";
        }

        return (
            <div>
                {this.state.LOADING && <Loader />}

                <AppHeader AppHeaderClass={AppHeaderClass} AppHeaderLeft={AppHeaderLeft} AppHeaderLeftLink={AppHeaderLeftLink} AppHeaderTitle={AppHeaderTitle} AppHeaderRight={AppHeaderRight} AppHeaderRightLink={AppHeaderRightLink} />

                    <div id="appCapsule" className="pb-5">
                    <div className="login-form mt-3 coach-intro">
                        <div className="section user-status">
                            <ul className="listview image-listview media mb-1">
                               <li>
                                  <Link to={'/UpdateProfile'} className="item">
                                     <div className="in">
                                        <div>
                                           <h3 className="pb-0 mb-0 font-20  ft-wt-900">Hi <span className="ft-wt-400">
                                            {this.state.userHome.NAME}
                                           </span></h3>
                                           {this.state.userHome.PROGRESS=='Y' &&
                                            <div className="text-muted">Your Profile is incomplete</div>
                                            }
                                        </div>
                                        {this.state.userHome.PROGRESS=='Y' &&
                                          <span to={'/UpdateProfile'} className="btn btn-outline-secondary rounded btn-ssm mb-0 font-11">COMPLETE</span>
                                        }
                                     </div>
                                  </Link>
                               </li>

                              {this.state.subscriptiondata.END &&
                               <li>
                                  <Link to={btn_link} className="item">
                                     <div className="in">
                                        <div className={"font-13 text-left text-red ft-wt-900 "+textClass}>
                                           {message_text}
                                        </div>
                                        <Link to={btn_link} className="btn btn-danger rounded btn-ssm mb-0 font-11">{btn_txt}</Link>
                                        {this.state.subscriptiondata.STATUS=='P' &&
                                        	<Link to={'/Subscription'} className="btn btn-primary rounded btn-ssm mb-0 font-11">Change Plan</Link>
                                    	}
                                     </div>
                                  </Link>
                               </li>
                        	   }

                              {this.state.BATCH_PROGRAM &&
                                  this.state.BATCH_PROGRAM.map((batch, i)=>{
                                      return(
                                          <>
                                              {batch.BATCH_PROGRAM_END &&
                                                   <li>
                                                      <Link to={'./OnlinePayPT/'+batch.BATCH_PROGRAM_ID} className="item">
                                                         <div className="in">
                                                            <div className={"font-13 text-left text-red ft-wt-900"}>
                                                               PT {batch.BATCH_PROGRAM_NAME} Subscription {(batch.BATCH_PROGRAM_END<=this.state.TODAY)?'Expired':'Expires'}  on <Moment date={batch.BATCH_PROGRAM_END} format="DD MMM YYYY"> </Moment>.
                                                            </div>
                                                            {batch.BATCH_PROGRAM_PAY_NOW==='Y' &&
                                                               <Link to={'./OnlinePayPT/'+batch.BATCH_PROGRAM_ID} className="btn btn-warning rounded btn-ssm mb-0 font-11">Renew</Link>
                                                            }
                                                         </div>
                                                      </Link>
                                                   </li>
                                              }
                                          </>
                                      )
                                  })
                              }
                            </ul>
                        </div>

                        {this.state.subscriptiondata!='' && this.state.subscriptiondata.ID!=0 && this.state.subscriptiondata.ID!=null &&
                        <div className="section pr-3 pl-3 pb-1 pt-0 final-details text-left">
                        	<h3>{title_str}</h3>
                            <div className="section full mt-1">
                            	<div className="wide-block pt-1 pb-1 pl-0 pr-0">
                            	   <div className="row">
                            	      <div className="col-6">
                            	         <div className="section-title btn-titles pl-05 pr-05">Package Name</div>
                            	      </div>
                            	      <div className="col-6">
                            	         <div className="section-title btn-titles2 pl-05 pr-05">{this.state.subscriptiondata.PROGRAM_NAME} | {this.state.subscriptiondata.SUBSCRIPTION_NAME}</div>
                            	      </div>
                            	   </div>
                            	</div>
                               <div className="wide-block pt-1 pb-1 pl-0 pr-0">
                                  <div className="row">
                                     <div className="col-6">
                                        <div className="section-title btn-titles pl-05 pr-05">Place of Practice</div>
                                     </div>
                                     <div className="col-6">
                                        <div className="section-title btn-titles2 pl-05 pr-05">{this.state.userHome.PP_NAME}</div>
                                     </div>
                                  </div>
                               </div>
                               <div className="wide-block pt-1 pb-1 pl-0 pr-0">
                                  <div className="row">
                                     <div className="col-6">
                                        <div className="section-title btn-titles pl-05 pr-05">Fitness Level</div>
                                     </div>
                                     <div className="col-6">
                                        <div className="section-title btn-titles2 pl-05 pr-05">{this.state.userHome.LVL_NAME}</div>
                                     </div>
                                  </div>
                               </div>
                               <div className="wide-block pt-1 pb-1 pl-0 pr-0">
                                  <div className="row">
                                     <div className="col-6">
                                        <div className="section-title btn-titles pl-05 pr-05">Start Date</div>
                                     </div>
                                     <div className="col-6">
                                        <div className="section-title btn-titles2 pl-05 pr-05">{this.state.subscriptiondata.START_DATE}</div>
                                     </div>
                                  </div>
                               </div>
                               <div className="wide-block pt-1 pb-1 pl-0 pr-0">
                                  <div className="row">
                                     <div className="col-6">
                                        <div className="section-title btn-titles pl-05 pr-05">End Date</div>
                                     </div>
                                     <div className="col-6">
                                        <div className="section-title btn-titles2 pl-05 pr-05">{this.state.subscriptiondata.EXP_DATE}</div>
                                     </div>
                                  </div>
                               </div>
                            </div>
                    	   </div> 
                        }

                        {this.state.BATCH_PROGRAM &&
                            this.state.BATCH_PROGRAM.map((batch, i)=>{
                                return(
                                    <div className="section pr-3 pl-3 pb-1 pt-0 final-details text-left">
                                       <h3>Personal Training Program Details</h3>
                                       <div className="section full mt-1">
                                          <div className="wide-block pt-1 pb-1 pl-0 pr-0">
                                             <div className="row">
                                                <div className="col-6">
                                                   <div className="section-title btn-titles pl-05 pr-05">Package Name</div>
                                                </div>
                                                <div className="col-6">
                                                   <div className="section-title btn-titles2 pl-05 pr-05">{batch.BATCH_PROGRAM_NAME}</div>
                                                </div>
                                             </div>
                                          </div>
                                         <div className="wide-block pt-1 pb-1 pl-0 pr-0">
                                            <div className="row">
                                               <div className="col-6">
                                                  <div className="section-title btn-titles pl-05 pr-05">Start Date</div>
                                               </div>
                                               <div className="col-6">
                                                  <div className="section-title btn-titles2 pl-05 pr-05">{batch.BATCH_PROGRAM_START}</div>
                                               </div>
                                            </div>
                                         </div>
                                         <div className="wide-block pt-1 pb-1 pl-0 pr-0">
                                            <div className="row">
                                               <div className="col-6">
                                                  <div className="section-title btn-titles pl-05 pr-05">End Date</div>
                                               </div>
                                               <div className="col-6">
                                                  <div className="section-title btn-titles2 pl-05 pr-05">{batch.BATCH_PROGRAM_END}</div>
                                               </div>
                                            </div>
                                         </div>
                                       </div>
                                    </div> 
                                )
                            })
                        }

                    	<div className="section pr-3 pl-3 pb-1 pt-0 final-details text-left">
                	        <div className="section full mt-1">
                	           <div className="wide-block pt-1 pb-1 pl-0 pr-0">
                	                    <p>
	                	              	Download the app from Play Store or App Store
	                	              	</p>
	                	              <div className="row">
	                	                 <div className="col-6">
	                	                   <a href={global.config.playStoreURL} target="_blank">
	                	                      <img src={global.config.playstoreImage} alt="playstore image" className="imaged img-fluid" />
	                	                   </a>
	                	                 </div>
	                	                 <div className="col-6">
	                	                     <a href={global.config.appStoreURL} target="_blank">
	                	                        <img src={global.config.appstoreImage} alt="appstore image" className="imaged img-fluid" />
	                	                    </a>
	                	                 </div>
	                	              </div>
                	           </div>
                	        </div>  
                    	</div>

                    		{this.state.userINSTA && this.state.userINSTA.length > 0 &&
                    	      <div className="mt-4">
                    	        <HomeSlider instagram_feed={this.state.userINSTA}/>
                    	       </div>
                    		}   
                   	</div>
                    </div>

                <ModalAlert type={this.state.notifType} Notifications={this.state.Notifications} modalType={this.state.modalType}/>
            </div>
        )
    }
}

export default Home;