module.exports = global.config = {
    // i18n: {
    //     welcome: {
    //         en: "Welcome",
    //         fa: "خوش آمدید"
    //     }
    //     // rest of your translation object
    // },
    bURL : "https://thatlifestylecoach.com/api/",
    // bURL : "http://126.0.0.100/TLC_app/api/",
    defaultImage: "/assets/img/no-img.jpg",
    tlcImage: "/assets/img/icon/icons-white/96x96.png",
    defaultProfile: "/assets/img/user.jpg",
    registerURL: "https://register.thatlifestylecoach.com/",
    defaultWorkoutImage: "/assets/img/workout1.jpg",
    appstoreImage: "/assets/img/app.png",
    playstoreImage: "/assets/img/play.png",
    successImage: "assets/img/thank-you.png",
    failedImage: "assets/img/fail.png",
    playStoreURL: "https://play.google.com/store/apps/details?id=com.thatlifestylecoach.pwa",
    appStoreURL: "https://apps.apple.com/us/app/thatlifestylecoach/id1549875044",
    RZ_TEST_KEY: 'rzp_test_173Rfe4mnnanVl',
    RZ_LIVE_KEY: 'rzp_live_MmcsqJar11RjVN',
};