import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import AppHeader from './AppHeader';
import Loader from './Loader';
import $ from "jquery";

class TimerControl extends Component {
    constructor(props) {
        super(props);
    };
  
    onClickHandler = () => {
        if(this.props.paused){
            this.props.start();
        }
        else{
            this.props.stop();
        }
    }
  
    render() {
        return (
                <div className="col-6 tet-center edited-pp play-pause">
                    <button onClick={this.onClickHandler} type="button" className="btn btn-coach btn-lg mr-1">
                        {this.props.paused?<ion-icon name="play-outline"></ion-icon>:<ion-icon name="pause-outline"></ion-icon>}
                    </button>
                    <br />
                    <p> {this.props.paused?"play":"pause"} </p>
                </div>
        );
    }
}

export default TimerControl