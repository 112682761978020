import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import AppHeader from './AppHeader';
import Loader from './Loader';
import Moment from 'react-moment';
import $ from "jquery";

class ContinuePayment extends Component {
    constructor(props) {
            super(props);
            this.state = {
            LOADING: true,
            Notifications: false,
            Logged: false,
            pDATA: [],
            pSelected: false,
            Saved: '',
            changeDETECTED: false,
            customerid: props.match.params.customerid,
            selectedVariables: "",
            basicPrograms: '',
            premiumPrograms: '',
        };

        this.ToggleClass = this.ToggleClass.bind(this);
        this.setActive = this.setActive.bind(this);
    }

    fetchDetailList() {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ mode: 'PRGRM_OPTIONS' })
        };

        fetch(global.config.bURL+"global_data.php", requestOptions)
            .then(function (response) {
                // console.log(response.gvariables);
                return response.json();
            })
            .then((result)=> {
                let responseJson = result;
                this.setState({ pDATA: responseJson, LOADING: false });
            });

        const requestOptions2 = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ mode: 'SELECTED_VARIABLES', clientid:this.state.customerid })
        };
        fetch(global.config.bURL+"global_data.php", requestOptions2)
            .then(function (response) {
                return response.json();
            })
            .then((result)=> {
                // console.log(result.SAVED_PARAMS);
                this.setState({
                    selectedVariables: result
                })

                localStorage.setItem('storedSelection', result.SAVED_PARAMS);
            });

            
        let savedParams = (localStorage.getItem('storedSelection')!='')?localStorage.getItem('storedSelection'):'';

        const requestOptions3 = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ mode: 'PROGRAMS', selected: savedParams })
        };

        fetch(global.config.bURL+"select_program.php", requestOptions3)
            .then(function (response) {
                return response.json();
            })
            .then((result)=> {
                let responseJson = result;
                // console.log(result);
                if(!responseJson.err) {
                    this.setState({ 
                        basicPrograms: responseJson.BASIC, 
                        premiumPrograms: responseJson.PREMIUM, 
                        LOADING: false 
                    });
                }
            });
    }

    setActive(el_id="", classname="") {
        let parent = $("#"+el_id).parent().parent();
        // $('.'+classname).removeClass('active'); // remove class

        if(!$('.'+classname).hasClass('active')) {
            $(parent).addClass('active'); // add class
        }
    }

    ToggleClass(event, classname="") {
      let el = event.target;
      let el_id = el.id;
      let parent = $("#"+el_id).parent().parent();

        if(classname!='') {
            $('.'+classname).removeClass('active'); // remove class
            $(parent).addClass('active'); // add class
        }

        this.setState({
            changeDETECTED: true,
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();

        let err = 0;
        if ($('#frmProgram')[0].checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            err++;
        }
        $('#frmProgram')[0].classList.add('was-validated');

        let plcofprac = $('input[name="plcofprac"]:checked');
        let level = $('input[name="level"]:checked');
        let goal = $('input[name="goal"]:checked');
        let health = $('input[name="health[]"]:checked');
        let exertype = '';//$('input[name="exertype"]:checked');

        if (plcofprac.length == 0) {
            err++;
            $("#err_practice").html('Please select place of practice.');
        }
        if (level.length == 0) {
            err++;
            $("#err_level").html('Please select a fitness level.');
        }
        if (goal.length == 0) {
            err++;
            $("#err_goal").html('Please select a goal.');
        }
        // if (health.length == 0) {
        //     err++;
        // }
        // if (exertype.length == 0) {
        //     err++;
        //     $("#err_exertype").html('Please select an exercise type.');
        // }

        // err = 0;
        if (err == 0) {
            let notify = '';
            let dataSaved = false;

            const method = "POST";
            const body = new FormData(this.form);
            var that = this;

            let health_arr = [];    
            $(health).each(function(){
              health_arr.push($(this).val());
            });

            let storedSelection = "plcofprac="+plcofprac.val()+"|level="+level.val()+"|goal="+goal.val()+"|health="+health_arr;//+"|exertype="+exertype.val();
            localStorage.setItem('storedSelection', storedSelection);
            if(localStorage.getItem("storedSelection")!='') {
              $('#submitLoading').css('display', 'block');
              dataSaved = true;
              this.setState({ Notifications: notify, Saved: dataSaved });
            }
        }
    }

    componentDidMount() {
        //alert(localStorage.getItem('SUBSCRIBER_ID'));
        this.fetchDetailList();
        localStorage.setItem('userData', this.state.customerid);
    }

    render() {
        let AppHeaderClass = 'appHeader no-border bg-common-header text-light';
        let AppHeaderLeft = 'showLINK';
        let AppHeaderLeftLink = '/Profile';
        let AppHeaderTitle = 'Complete Payment';
        let AppHeaderRight = '';
        let AppHeaderRightLink = '';

        let currency_type = localStorage.getItem('CURRENCY_TYPE')?localStorage.getItem('CURRENCY_TYPE'):'INR';

        if(this.state.Saved) {
          return ( <Redirect to="/Plans2" />);
        }

        return (
            <div>
                {this.state.LOADING && <Loader />}
                <AppHeader AppHeaderClass={AppHeaderClass} AppHeaderLeft={AppHeaderLeft} AppHeaderLeftLink={AppHeaderLeftLink} AppHeaderTitle={AppHeaderTitle} AppHeaderRight={AppHeaderRight} AppHeaderRightLink={AppHeaderRightLink} />

                  <div id="appCapsule">
                    <form id="frmProgram" ref={el =>
                        (this.form = el)} onSubmit={this.handleSubmit} className="needs-validation" noValidate>
                        <input type="hidden" value="PRGRM" className="form-control" name="mode" id="mode" />

                          <div className="login-form">      
                              <div className="section full mt-2 pr-1 pl-1">
                                  <div className="section-title btn-titles">Place of Practice</div>
                                  <div className="wide-block-noborder pt-05 pb-2">
                                      <div className="row">
                                            {this.state.pDATA.practice && 
                                                this.state.pDATA.practice.map((prc, i)=> {
                                                    let checked = false;
                                                    let activeCLASS = "";
                                                    if(this.state.selectedVariables.PPID==prc.ID) {
                                                        // this.setActive("plcofprac_"+i, 'practice');
                                                            activeCLASS  = "active";
                                                            checked = true;
                                                    }

                                                    return (
                                                        <div className="col-6" key={i} id={prc.ID}>
                                                          <div className={"radio fancy-blue btn btn-select btn-outline-grey rounded mb-1 imaged img-fluid practice "+ activeCLASS }>
                                                            <label>
                                                              <input type="radio" name="plcofprac" id={"plcofprac_"+i} defaultChecked={checked} defaultValue={prc.ID}  required="required" onClick={(e)=>this.ToggleClass(e, 'practice')}/>
                                                              {prc.NAME}
                                                              <span dangerouslySetInnerHTML={{__html: prc.ICON}} />
                                                              <span></span>
                                                            </label>
                                                          </div>
                                                      </div>
                                                    );
                                                })
                                            }
                                            <span id="err_practice" className="err-required"></span>
                                      </div>

                                  </div>
                              </div>
                              
                                {/*<div className="section full mt-1 mb-5">
                                    {this.state.pDATA.etype!=undefined && <ContinuePaymentSlider etype={this.state.pDATA.etype} />}
                                </div>*/}

                              <div className="section full mt-2 pr-1 pl-1">
                                  <div className="section-title btn-titles">Fitness Level</div>
                                  <div className="wide-block-noborder pt-05 pb-2">
                                      <div className="row">
                                            {this.state.pDATA.level &&
                                                this.state.pDATA.level.map((lt, i)=>{
                                                    let activeCLASS = "";
                                                    let checked = false;
                                                    if(this.state.selectedVariables.LVL==lt.ID) {
                                                        // this.setActive("level"+lt.ID, 'level');
                                                        checked = true;
                                                        activeCLASS  = "active";
                                                    }

                                                    return(
                                                        <div className="col-4" key={i}>
                                                             <div className={"radio fancy-blue btn btn-select2 btn-outline-grey rounded mb-1 imaged img-fluid level "+activeCLASS }>
                                                              <label>
                                                                <input type="radio" name="level" id={'level'+lt.ID} defaultChecked={checked} defaultValue={lt.ID} required="required" onClick={(e)=>this.ToggleClass(e, 'level')}/> 
                                                                {lt.NAME}
                                                                <span></span>
                                                              </label>
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                            }
                                            <span id="err_level" className="err-required"></span>
                                      </div>

                                  </div>
                              </div>   
                              <div className="section inset mt-0">

                                  <div className="acc-options accordion no-lines" id="accordionExample2">

                                      <div className="item">
                                          <div className="accordion-header">
                                              <button className="btn collapsed" type="button" data-toggle="collapse" data-target="#accordion01">
                                                  Your Goal
                                              </button>
                                          </div>
                                          <div id="accordion01" className="accordion-body collapse" data-parent="#accordionExample2">
                                              <div className="accordion-content text-left">
                                                  <div className="wide-block no-border">
                                                  {this.state.pDATA.goal && 
                                                    this.state.pDATA.goal.map((goal, i)=>{
                                                        
                                                        let checked = false;
                                                        if(this.state.selectedVariables.GOAL==goal.ID) {
                                                            // console.log(this.state.selectedVariables.GOAL +" == "+ goal.ID);
                                                            // this.setActive("goal"+goal.ID, 'goal');
                                                            checked = true;
                                                        }

                                                        return (
                                                            <div className="custom-control custom-radio mb-1" key={i}>
                                                                <input type="radio" id={'goal'+goal.ID} defaultChecked={checked} name="goal" className="custom-control-input" defaultValue={goal.ID}  required="required" onClick={(e)=>this.ToggleClass(e, '')}/>
                                                                <label className="custom-control-label" htmlFor={'goal'+goal.ID}>{goal.NAME}</label>
                                                            </div>
                                                        );
                                                    })
                                                  }
                                                </div>
                                              </div>
                                          </div>
                                          <span id="err_goal" className="err-required"></span>
                                      </div>

                                      <div className="item">
                                          <div className="accordion-header">
                                              <button className="btn collapsed" type="button" data-toggle="collapse" data-target="#accordion02">
                                                  Health Conditions
                                              </button>
                                          </div>
                                          <div id="accordion02" className="accordion-body collapse" data-parent="#accordionExample2">
                                              <div className="accordion-content text-left">
                                                  <div className="wide-block no-border">
                                                    {this.state.pDATA.health && 
                                                      this.state.pDATA.health.map((health, i)=>{

                                                            let checked = false;
                                                            let HC_ARR = this.state.selectedVariables.HCID;

                                                            if(HC_ARR!=undefined && HC_ARR.length > 0) {
                                                                if(HC_ARR.includes(health.ID)) {
                                                                    checked = true; //"checked";
                                                                }
                                                            }

                                                          return (
                                                              <div className="custom-control custom-checkbox mb-1" key={i}>
                                                                  <input type="checkbox" className="custom-control-input" defaultChecked={checked} name="health[]" id={'health'+health.ID} defaultValue={health.ID} onClick={(e)=>this.ToggleClass(e, '')}/>
                                                                  <label className="custom-control-label" htmlFor={'health'+health.ID}>{health.NAME}</label>
                                                              </div>
                                                          );
                                                      })
                                                    }

                                                  </div>
                                              </div>
                                          </div>
                                      </div>

                                  </div>
                              </div>      

                              {!this.state.changeDETECTED &&
                                <div>
                                    <div className="extraHeader subscription_head sub-header bg-coach-darkblue">
                                         <ul className="nav nav-tabs lined" role="tablist">
                                            <li className="nav-item">
                                                <a className="nav-link active" data-toggle="tab" href="#home11" role="tab">
                                                    BASIC
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" data-toggle="tab" href="#profile12" role="tab">
                                                    PREMIUM
                                                </a>
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="tab-content mt-0 bg-beige">
                                        <div className="tab-content pl-3 pr-3 pt-3 pb-3 mt-0">
                                                <div className="tab-pane fade show active" id="home11" role="tabpanel">
                                                    {this.state.basicPrograms.length > 0 &&
                                                        this.state.basicPrograms.map((program, i)=>{

                                                            let register_free = '/PaymentNow/'+program.ID;
                                                            let register_url = '/PaymentNow/'+program.ID+'/B';
                                                            let duration_type = "";
                                                            let priceVAL = "";
                                                            let priceSYM = "";

                                                            if(currency_type=='INR') {
                                                            	priceVAL = program.RENEWAL_PRICE;
                                                            	priceSYM = '&#x20B9;';
                                                            }
                                                            else {
                                                            	priceVAL = program.RENEWAL_PRICE_USD;
                                                            	priceSYM = '&#36;';
                                                            }

                                                            if(program.DURATION_TYPE=='M') {
                                                                duration_type = 'Month';
                                                            }
                                                            else if(program.DURATION_TYPE=='W') {
                                                            	duration_type = 'Week';
                                                        	}
                                                            else if(program.DURATION_TYPE=='Y') {
                                                                duration_type = 'Year';
                                                            }

                                                            let thisSubscripbed = false;
                                                            let expDATE = (this.state.selectedVariables.EXPDATE!=undefined)?this.state.selectedVariables.EXPDATE:'';
                                                            if( this.state.selectedVariables.PRGID == program.ID && this.state.selectedVariables.PLAN_TYPE=='B') {
                                                                thisSubscripbed = true;                                                                
                                                            }

                                                            return (
                                                                <div className="program-outer" key={i}>
                                                                    <div className="workout-types">
                                                                        {program.IMAGE && 
                                                                            <div className="image-cover">
                                                                                <img src={program.IMAGE} alt="image" className="imaged m-auto" />
                                                                            </div>
                                                                        }
                                                                        <div className="price-block mt-2">
                                                                            <h4>{program.NAME}</h4>
                                                                            <p className="font-15 mb-05">{program.DESC}</p>
                                                                            
                                                                            {thisSubscripbed &&
                                                                                <div className="fon text-red ft-wt-900">
                                                                                   Subscription Ends on &nbsp;
                                                                                   <Moment date={expDATE} format="DD MMM YYYY"> </Moment>
                                                                                </div>
                                                                            }

                                                                             <div className="price-tag bg-coach-orange">
                                                                                 <h5><span className="ft-wt-300" dangerouslySetInnerHTML={{ __html:priceSYM}} /> {priceVAL}</h5>
                                                                                 <p>{program.DURATION + ' ' + duration_type} </p>
                                                                                 <div className="premium-fe"></div>
                                                                                 <Link className="btn btn-coach rounded btn-block btn-mid text-upper" to={register_url}>Buy Now</Link>
                                                                             </div>
                                                                        </div>

                                                                        <h5 className="mb-1 pb-0">Plan Inclusions: </h5>
                                                                        <div className="text-left pl-22">
                                                                            {program.CONTENT && <p dangerouslySetInnerHTML={{ __html: program.CONTENT }} /> }
                                                                        </div>
                                                                        
                                                                        <h5 className="mb-1 pb-0">Equipments Required:</h5>
                                                                        <div className="text-left">
                                                                            	<ul className="gym-icons">
                                                                                {program.EQUIPMENT.length > 0 &&
                                                                                	program.EQUIPMENT.map((equipment, e)=>{

                                                                                		return (
                                                                                		    <li key={e}>
                                                                                		    	<span dangerouslySetInnerHTML={{ __html: equipment.NAME }} />
                                                                                		    	{equipment.IMAGE!="" &&
                                                                                		    		<img src={equipment.IMAGE} width="20" height="20"/>
                                                                                		    	}
                                                                                		    </li>
                                                                                		)
                                                                                	})
                                                                                }
                                                                                </ul>
                                                                       </div> 
                                                                    </div>
                                                                </div>
                                                            );

                                                        })
                                                    }
                                                    {(!this.state.basicPrograms)?'No Programs Found...':''}
                                                </div>


                                                <div className="tab-pane fade" id="profile12" role="tabpanel">
                                                    {this.state.premiumPrograms.length > 0 &&
                                                        this.state.premiumPrograms.map((program, i)=>{

                                                            let register_free = '/PaymentNow/'+program.ID;
                                                            let register_url = '/PaymentNow/'+program.ID+'/P';
                                                            let duration_type = "";
                                                            let priceVAL = "";
                                                            let priceSYM = "";

                                                            if(currency_type =='INR') {
                                                            	priceVAL = program.RENEWAL_PRICE;
                                                            	priceSYM = '&#x20B9;';
                                                            }
                                                            else {
                                                            	priceVAL = program.RENEWAL_PRICE_USD;
                                                            	priceSYM = '&#36;';
                                                            }

                                                            if(program.DURATION_TYPE=='M') {
                                                                duration_type = 'Month';
                                                            }
                                                            else if(program.DURATION_TYPE=='W') {
                                                            	duration_type = 'Week';
                                                        	}
                                                            else if(program.DURATION_TYPE=='Y') {
                                                                duration_type = 'Year';
                                                            }

                                                            let thisSubscripbed = false;
                                                            let expDATE = (this.state.selectedVariables.EXPDATE!=undefined)?this.state.selectedVariables.EXPDATE:'';
                                                            if( this.state.selectedVariables.PRGID == program.ID && this.state.selectedVariables.PLAN_TYPE=='P') {
                                                                thisSubscripbed = true;                                                                
                                                            }

                                                            return(
                                                               <div className="program-outer" key={i}>
                                                                <div className="workout-types">
                                                                    {program.IMAGE && 
                                                                       <div className="image-cover">
                                                                           <img src={program.IMAGE} alt="image" className="imaged m-auto" />
                                                                       </div>
                                                                    }
                                                                    <div className="price-block mt-2">
                                                                        <h4>{program.NAME}</h4>
                                                                        <p className="font-15 mb-05">{program.DESC}</p>

                                                                        {thisSubscripbed &&
                                                                            <div className="fon text-red ft-wt-900">
                                                                               Subscription Ends on &nbsp; <Moment date={expDATE} format="DD MMM YYYY"> </Moment>
                                                                            </div>
                                                                        }

                                                                         <div className="price-tag bg-coach-green">
                                                                             <h5><span className="ft-wt-300" dangerouslySetInnerHTML={{ __html:priceSYM}} /> {priceVAL}</h5>
                                                                             <p>{program.DURATION + ' ' + duration_type} </p>
                                                                             <div className="premium-fe">
                                                                             {program.DIET=='Y' &&
                                                                                <p>Customised Diet plan at additional of 
                                                                                <span className="text-fyellow ft-wt-500"> 
                                                                                	
                                                                                 {program.DIET_PRICE}</span> for life time with upgrade option</p>
                                                                             }
                                                                             </div>
                                                                             <Link className="btn btn-coach rounded btn-block btn-mid text-upper" to={register_url}>Buy Now</Link>
                                                                         </div>
                                                                    </div>
                                                                    <h5 className="mb-1 pb-0">Plan Inclusions: </h5>
                                                                    <div className="text-left pl-22">
                                                                        {program.CONTENT && <p dangerouslySetInnerHTML={{ __html: program.CONTENT }} /> }
                                                                    </div>
                                                                    
                                                                    <h5 className="mb-1 pb-0">Equipments Required:</h5>
                                                                    <div className="text-left">
                                                                        	<ul className="gym-icons">
                                                                            {program.EQUIPMENT.length > 0 &&
                                                                            	program.EQUIPMENT.map((equipment, e)=>{

                                                                            		return (
                                                                            		    <li key={e}>
                                                                            		    	<span dangerouslySetInnerHTML={{ __html: equipment.NAME }} />
                                                                            		    	{equipment.IMAGE!="" &&
                                                                            		    		<img src={equipment.IMAGE} width="20" height="20"/>
                                                                            		    	}
                                                                            		    </li>
                                                                            		)
                                                                            	})
                                                                            }
                                                                            </ul>
                                                                    </div>
                                                                </div>
                                                               </div>
                                                            );
                                                        })
                                                    }
                                                    {(!this.state.basicPrograms)?'No Programs Found...':''}
                                                </div>
                                        </div>
                                    </div>
                                </div>
                              }

                            {this.state.changeDETECTED &&
								<div className="section double mt-1 mb-5">
								<div className="mt-4">
                                    <button type="submit" className="btn btn-coach-pink rounded btn-block btn-mid text-upper">
                                        <span className="spinner-border spinner-border-sm mr-05" id="submitLoading" style={{ display: 'none' }} role="status" aria-hidden="true"></span>
                                        Generate a program
                                    </button>
								</div>
								</div>
							}
                              
                              
                          </div>
                    </form>
                  </div>
            </div>
        );
    }
}

export default ContinuePayment;