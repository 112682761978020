export function plus(a, b) {
  return a + b;
}

export function minus(a, b) {
  return a - b;
}

export function validatedate(inputText) {
	var ret_val = true;

    var dateformat = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
    // Match the date format through regular expression
    if (inputText.match(dateformat)) {
        // document.form1.text1.focus();
        //Test which seperator is used '/' or '-'
        var opera1 = inputText.split('/');
        var opera2 = inputText.split('-');
        var lopera1 = opera1.length;
        var lopera2 = opera2.length;
        // Extract the string into month, date and year
        if (lopera1 > 1) {
            var pdate = inputText.split('/');
        } else if (lopera2 > 1) {
            var pdate = inputText.split('-');
        }
        var dd = parseInt(pdate[0]);
        var mm = parseInt(pdate[1]);
        var yy = parseInt(pdate[2]);
        // Create list of days of a month [assume there is no leap year by default]
        var ListofDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
        if (mm == 1 || mm > 2) {
            if (dd > ListofDays[mm - 1]) {
                // console.log('Invalid date format!');
                ret_val = false;
            }
        }
        if (mm == 2) {
            var lyear = false;
            if ((!(yy % 4) && yy % 100) || !(yy % 400)) {
                lyear = true;
            }
            if ((lyear == false) && (dd >= 29)) {
                // console.log('Invalid date format!');
                ret_val = false;
            }
            if ((lyear == true) && (dd > 29)) {
                // console.log('Invalid date format!');
                ret_val = false;
            }
        }
    } else {
        // console.log("Invalid date format!");
        // document.form1.text1.focus();
        ret_val = false;
    }

    return ret_val;
}

export function validdate(dd, mm, yy, type="") {

	var ret_val = false;
	var M = mm-1;
	var today = new Date();
	var inpDate = new Date(yy, M, dd);

	// console.log(inpDate);

	if(type=='P') {
		// only past & today date accepted
		if (inpDate.setHours(0, 0, 0, 0) <= today.setHours(0, 0, 0, 0)) {
			// console.log('Date in the past');
		  ret_val = true;
		}
	}
	else if(type=='F') {
		// only future & today date accepted
		if (inpDate.setHours(0, 0, 0, 0) >= today.setHours(0, 0, 0, 0)) {
			// console.log('Date in the future');
		  ret_val = true;
		}
	}

	return ret_val;
}

export function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
}

export function CompareDate(date_str1, date_str2) {
    var result = "";
    var date1 = formatDate(date_str1);
    var date2 = formatDate(date_str2);
   
    if(date2 < date1) {
        result = date2+'<'+date1;
    }
    else {
        result = date2+' > '+date1;
    }

   return result;
}

export function secondsToHms(d, format="S") {
    d = Number(d);
    var ret_str = "";
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    if(format=='M') {
        m = d;
    }

    var hDisplay = h > 0 ? h + (h == 1 ? " hr " : " hrs ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " min " : " mins ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " sec" : " secs") : "";

    if(format=='M') {
        ret_str = mDisplay;
    }
    else {
        ret_str = hDisplay + mDisplay + sDisplay;
    }
    
    return ret_str; 
}

/*export function PingUserActive(clientid) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ mode: 'CLIENT_ACTIVE', clientid:clientid })
    };

    fetch(global.config.bURL+"global_data.php", requestOptions)
        .then(function (response) {
            return response.json();
        })
        .then((result)=> {
            console.log('PingUserActive: '+result);
        });
}*/

export function messageReceivedHTML(image="", title="", text="", time="")
{
    let htmlSTR = "";

    
    htmlSTR ='<div class="message-item">\
                <img src='+(image?image:"assets/img/sample/avatar/avatar1.jpg")+' alt="avatar" class="avatar" />\
                <div class="content">\
                    <div class="title">'+title+'</div>\
                    <div class="bubble">\
                        '+text+'\
                    </div>\
                    <div class="footer">'+time+'</div>\
                </div>\
            </div>';

    return htmlSTR;
}

export function messageSentHTML(text="", time="")
{
    let htmlSTR = "";

    htmlSTR = '<div class="message-item user">\
                <div class="content">\
                    <div class="bubble">\
                        '+text+'\
                    </div>\
                    <div class="footer">'+time+'</div>\
                </div>\
            </div>';

    return htmlSTR;
}

export function checkClientSubscription(customer="", s_id="")
{
	var ret_val = false;

	if(customer!='' && s_id!='') {
		const requestOptions = {
		    method: 'POST',
		    headers: { 'Content-Type': 'application/json' },
		    body: JSON.stringify({ mode: 'SUBSCRIPTION_ACTIVE', clientid:customer })
		};

		fetch(global.config.bURL+"global_data.php", requestOptions)
		    .then(function (response) {
		        return response.json();
		    })
		    .then((result)=> {
		        // console.log('Subscription: '+result);
		        if(result.ACTIVE=='Y') {
		        	ret_val = true;
		        }
		    });
	}

	return ret_val;
}

export function chatNewMessage(customer="", s_id="", c_id="")
{
	var ret_val = [];
	// console.log(customer+' '+s_id+' '+c_id);

	if(customer!='' && s_id!='') {
		const requestOptions = {
		    method: 'POST',
		    headers: { 'Content-Type': 'application/json' },
		    body: JSON.stringify({ mode: 'NEW_MESSAGES', clientid:customer, s_id:s_id, c_id:c_id })
		};

		fetch(global.config.bURL+"global_data.php", requestOptions)
		    .then(function (response) {
		        return response.json();
		    })
		    .then((result)=> {
		        // console.log('Chat: '+result);
		        ret_val = result;
		    });
	}

	return ret_val;
}

export function pushIDUpdate(customer="", push_id="")
{
	var ret_val = [];
	// console.log(customer+' '+s_id+' '+c_id);

	if(customer!='') {
		const requestOptions = {
		    method: 'POST',
		    headers: { 'Content-Type': 'application/json' },
		    body: JSON.stringify({ mode: 'UPDATE_WEBPUSHR', clientid:customer, push_id:push_id})
		};

		fetch(global.config.bURL+"global_data.php", requestOptions)
		    .then(function (response) {
		        return response.json();
		    })
		    .then((result)=> {
		        // console.log('Chat: '+result);
		        ret_val = result;
		    });
	}

	return ret_val;
}

export function gtag_report_conversion2(url) {
  /*console.log('conversion recorded...');
  var callback = function () {
    if (typeof(url) != 'undefined') {
      window.location = url;
    }
  };
  
  gtag('event', 'conversion', {
      'send_to': 'AW-441851412/YFOACNjYrJICEJS82NIB',
      'transaction_id': '',
      'event_callback': callback
  });
  return false;*/
}

/*export function playBGVideo ()
{
    $(document).ready(function(){
        $("#bgVideo").YTPlayer();
    });
}*/