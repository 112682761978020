import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import AppHeader from './AppHeader';
import Loader from './Loader';
import AppBottomMenu from './AppBottomMenu';
import ModalAlert from './ModalAlert';
import { PingUserActive, formatDate } from './Common';
import $ from "jquery";

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            LOADING: true,
            Notifications: false,
            Logged: false,
            profiledata: [],
            subscriptiondata: [],
            selectedProfile: "",
            profileImage: global.config.defaultProfile,
            notifType: '',
            customerid: localStorage.getItem('userData'),
            ALLOWED: false,
            TODAY: formatDate(new Date()),
        };
        this.handleProfileChange = this.handleProfileChange.bind(this);
        this.handleProfileSave = this.handleProfileSave.bind(this);
    }    

    handleProfileSave (evt) {
        evt.preventDefault();
        // console.log('submitting the new profile here...');

        let err = 0;
        if ($('#frmProfile')[0].checkValidity() === false) {
            evt.preventDefault();
            evt.stopPropagation();
            err++;
        }
        $('#frmProfile')[0].classList.add('was-validated');

        let notify = "";
        let notify_type = "";
        let filePic = $('#file_pic').val();

        if(filePic=='') {
            notify = "Please select an image to change!";
            err ++;
            this.setState({
                Notifications: notify,
                notifType: "error",
            })
        }

        if (notify != '')
            window.$('#ModalAlert').modal('show'); 

        if(err==0)
        {
            const method = "POST";
            const body = new FormData(this.form);
            var that = this;

            fetch(global.config.bURL+"customer_changepic.php", { method, body })
                .then(function (response) {
                    //console.log(response);
                    return response.json();
                })
                .then(function (result) {
                    let responseJson = result;
                    notify = "Profile Pic Updated Successfully!";
                    notify_type = "success";

                    if (result.err != '') {
                        if(result.err >= 0) notify_type = "error";

                        if (result.err == '1') notify = 'Invalid Access Detected!';
                        else if (result.err == '2') notify = 'Select an image whose size is less than 3 MB';
                        else if (result.err == '3') notify = 'Please upload images with type png,pjpeg,jpeg,jpg,JPG';
                    }

                    that.setState({
                        profileImage:responseJson.IMAGE, 
                        LOADING: false, 
                        Logged: true,
                        Notifications: notify,
                        notifType: notify_type,
                    });

                    if (notify != '')
                        window.$('#ModalAlert').modal('show'); 
                });
        }
    }

    handleProfileChange (evt) {
    	

    	let valid_ext = false;
    	let valid_size = false;
    	
    	if(this.fileInput.value)
    	{
	    	let file_name = this.fileInput.files[0].name;
	    	let i_size = this.fileInput.files[0].size;
	    	let i_type = this.fileInput.files[0].type;
	    	let i_ext = file_name.split('.')[file_name.split('.').length - 1].toLowerCase();

	    	if(i_ext=='jpg' || i_ext=='jpeg' || i_ext=='png') {
	    		valid_ext = true;
	    	}

	    	if(i_size <= 700000) {
	    		valid_size = true;
	    	}

	    	if(i_ext)
	    	{
		        this.setState({
		            selectedProfile: this.fileInput.value?file_name:'',
		            profileImage: this.fileInput.value? URL.createObjectURL(this.fileInput.files[0]): '',
		        });
	    	}
	    	else
	    	{
	    		this.setState({
	    			Notifications: "Invalid Image type detected.",
	    			notifType: "error",
	    		})
	    	}
	    }
    }

    getProfileData() {
        if(this.state.customerid)
        {
          const requestOptions = {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ mode: 'HOME', clientid: this.state.customerid})
          };

          fetch(global.config.bURL+"global_data.php", requestOptions)
              .then(function (response) {
                  return response.json();
              })
              .then((result)=> {
                  let responseJson = result;
                  let allowed_flag = true;

                  if(responseJson.SUBSCRIPTION.DAYS_LEFT < 0) {
                  	allowed_flag = false;
                  }

                  console.log(result);
                  console.log(allowed_flag);
                  
                  this.setState({
                  	profileImage:responseJson.USER.PIC,
                  	subscriptiondata:responseJson.SUBSCRIPTION,
                  	profiledata: responseJson.USER, 
                  	LOADING: false, 
                  	Logged: true,
                  	ALLOWED: allowed_flag,
                  });

                  localStorage.setItem('programID', responseJson.SUBSCRIPTION.PID);
              });
        }
    }

    componentDidMount() {
        this.getProfileData();
    }
    render() {

        if (!this.state.customerid) {
            localStorage.setItem('userData','');
            return (<Redirect to={'/'}/>)
        }

        let AppHeaderClass = 'appHeader no-border bg-common-header text-light';
        let AppHeaderLeft = '';
        let AppHeaderLeftLink = '';
        let AppHeaderTitle = 'Profile';
        let AppHeaderRight = '';
        let AppHeaderRightLink = '';

        localStorage.setItem('userProfileImg', this.state.profileImage?this.state.profileImage:global.config.defaultProfile);


        return (
            <div>
                {this.state.LOADING && <Loader />}
                
                <AppHeader AppHeaderClass={AppHeaderClass} AppHeaderLeft={AppHeaderLeft} AppHeaderLeftLink={AppHeaderLeftLink} AppHeaderTitle={AppHeaderTitle} AppHeaderRight={AppHeaderRight} AppHeaderRightLink={AppHeaderRightLink} />

                <div id="appCapsule" className="pb-5">
                    <div className="section bg-common-header profile-pic">
                        <div className="imaged w140 rounded profile-cover">
                           <img src={this.state.profileImage?this.state.profileImage:global.config.defaultProfile} alt="image"/>
                           </div>
                    </div>        

                    <div className="section user-options pr-0 pl-0">
                        <ul className="user-name listview image-listview media mb-0">
                           <li>
                              <div className="item">
                                 <div className="in">
                                    <div>
                                       <div className="font-16 text-gre5 ft-wt-500">Hi {this.state.profiledata.NAME}</div>
                                    </div>

                                    <form id="frmProfile" ref={el =>(this.form = el)} className="needs-validation" noValidate onSubmit={this.handleProfileSave} encType="multipart/form-data">
                                        <input type="hidden" name="mode" id="mode" readOnly value="CHANGE_PIC" />
                                        <input type="hidden" name="clientid" id="clientid" readOnly value={this.state.customerid} />
                                        <label>
                                            <input type="file" name="file_pic" id="file_pic" onChange={this.handleProfileChange} ref={input => this.fileInput = input} className="form-control" />
                                        </label>
                                        <button type="submit" className="btn btn-outline-secondary rounded btn-ssm mb-0 font-10 text-upper ft-wt-600 let-1 text-gre8">Update pic</button>
                                    </form>
                                 </div>
                              </div>
                           </li>
                        </ul>
                        <ul className="user-edits listview link-listview">
                            <li>
                                <Link to={"/UpdateProfile"}>
                                    Edit Profile
                                </Link>
                            </li>

                            {this.state.subscriptiondata.SUBS_TYPE=='P' &&
                                <li>
                                    <Link to={(this.state.ALLOWED)?"/UpdateProgress":"#"}>
                                        Progress
                                    </Link>
                                </li>
                            }

                            <li>
                                <Link to={(this.state.ALLOWED)?"/ChangeLevel":"#"}>
                                    Change Level
                                </Link>
                            </li>
                            
                            <li>
                                <Link to={"/Subscription"}>
                                    Subscriptions
                                    {this.state.subscriptiondata.DAYS_LEFT <= 10 && this.state.subscriptiondata.DAYS_LEFT > 0 &&
                                        <span className="text-muted">{this.state.subscriptiondata.DAYS_LEFT} Days Left</span>
                                    }

                                    {this.state.subscriptiondata.DAYS_LEFT < 0 &&
                                        <span className="text-muted">Subscription Expired</span>
                                    }
                                </Link>
                            </li>
                            <li>
                                <Link to={(this.state.ALLOWED)?"/Calendar":"#"}>
                                    Calendar
                                </Link>
                            </li>
                            <li>
                                <Link to={"/FAQ"}>
                                    FAQ
                                </Link>
                            </li>
                            <li>
                                <Link to={"/Logout"}>
                                    Logout
                                </Link>
                            </li>
                            
                        </ul>
                    </div>

                </div>

                <ModalAlert Notifications={this.state.Notifications} type={this.state.notifType} />

                <AppBottomMenu activeClass={"profile"} allowClick={this.state.ALLOWED}/>
            </div>
        )
    }
}

export default Login;