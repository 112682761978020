import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import AppHeader from './AppHeader';
import Loader from './Loader';
import AppBottomMenu from './AppBottomMenu';
import ModalAlert from './ModalAlert';
import $ from "jquery";

class ChangeLevel extends Component {
	constructor(props) {
		super(props);
		this.state = {
			LOADING: true,
			Notifications: false,
			Logged: false,
			dispData: [],
			notifType: "",
			customerid: localStorage.getItem('userData')
		}
	}

	profileData() {
	    const requestOptions = {
	        method: 'POST',
	        headers: { 'Content-Type': 'application/json' },
	        body: JSON.stringify({ mode: 'CHANGE_LEVEL', clientid:this.state.customerid, sid: localStorage.getItem("subscriptionID") })
	    };

	    fetch(global.config.bURL+"global_data.php", requestOptions)
	        .then(function (response) {
	            return response.json();
	        })
	        .then((result)=> {
	            console.log(result.CHANGE_LEVEL);
	            this.setState({
	            	LOADING: false,
	            	dispData: result.CHANGE_LEVEL
	            })
	        });
	}

	ToggleClass(event, classname="", selected="") {
	  let el = event.target;
	  let el_id = "";

	  if(selected!= '') {
	  	el_id = selected;
	  }
	  else {
	  	el_id = el.id;
	  }

	  let parent = $("#"+el_id).parent().parent();
	    
	    $('.'+classname).removeClass('active'); // remove class
	    $(parent).addClass('active'); // add class
	}

	handleSubmit = (e) => {
	    // console.log('submitting form...');
	    e.preventDefault();
	    let err = 0;

	    let newLevel = $('input[name="level"]:checked');

	    if(this.state.dispData.DLEVEL != newLevel.val() ) 
	    {
	    	if( window.confirm("You are about to change the level, Continue?")) 
	    	{
	    		// console.log('changeing level...');
	    		if (err == 0) {
	    		    $('#submitLoading').css('display', 'block');

	    		    const method = "POST";
	    		    const body = new FormData(this.form);
	    		    var that = this;

	    		    fetch(global.config.bURL+"customer_schedule_update.php?mode=UPDATE_SCHEDULE&customer="+this.state.customerid+"&subID="+localStorage.getItem('subscriptionID')+"&lvlID="+newLevel.val(), { method, body })
	    		        .then(function (response) {
	    		            return response.json();
	    		        })
	    		        .then(function (result) {

	    		            let notify = '';
	    		            if (result.err != '') {
	    		                if (result.err == '1') notify = 'Profile Details Updated Successfully';
	    		                else if (result.err == '2') notify = 'Invalid Customer ID';
	    		                else if (result.err == '3') notify = 'Invalid Access Detected';
	    		            }

	    		            let updated = false;
	    		            let nType = 'error';

	    		            if (result.updated == 'Y') {
	    		                updated = true;
	    		                notify = 'Profile Details Updated Successfully';
	    		                nType = 'success';
	    		            }

	    		            that.setState({ Notifications: notify, Updated: updated, notifType:nType });
	    		            $('#submitLoading').css('display', 'none');

	    		            if (notify != '')
	    		                window.$('#ModalAlert').modal('show');
	    		        });
	    		}
	    	}
	    }
	}

	componentDidMount() {
		this.profileData();
	}

	render() {
		let AppHeaderClass = 'appHeader no-border bg-common-header text-light';
		let AppHeaderLeft = 'showLINK';
		let AppHeaderLeftLink = '/Profile';
		let AppHeaderTitle = 'Change Level';
		let AppHeaderRight = '';
		let AppHeaderRightLink = '';

		let classRadio = "";
		let checkedRadio = "";

		return(
			<div>
				{this.state.LOADING && <Loader />}
				<AppHeader AppHeaderClass={AppHeaderClass} AppHeaderLeft={AppHeaderLeft} AppHeaderLeftLink={AppHeaderLeftLink} AppHeaderTitle={AppHeaderTitle} AppHeaderRight={AppHeaderRight} AppHeaderRightLink={AppHeaderRightLink} />
			
				<div id="appCapsule">
				    <div className="login-form">      
						<form id="frmProgram" ref={el =>
		                        (this.form = el)} onSubmit={this.handleSubmit} className="needs-validation" noValidate>

								<div className="section full mt-2 pr-1 pl-1">
								    <div className="section-title btn-titles">Fitness Level</div>
								    <div className="wide-block-noborder pt-05 pb-2">
								        <div className="row">
								              {this.state.dispData.LEVELS!=undefined &&
								                  this.state.dispData.LEVELS.map((lt, i)=>{

								                  		if(lt.ID==this.state.dispData.DLEVEL) {
								                  			classRadio = "active";
								                  			checkedRadio = "checked='checked'";
								                  		}
								                  		else {
								                  			classRadio = "";
								                  			checkedRadio = "";
								                  		}

								                      return(

								                          	<div className="col-4" key={i}>
								                               <div className={"radio fancy-blue btn btn-select2 btn-outline-grey rounded mb-1 imaged img-fluid level "+classRadio}>
								                                <label>
								                                  <input type="radio" name="level" id={'level'+lt.ID} value={lt.ID} defaultChecked={checkedRadio} required onClick={(e)=>this.ToggleClass(e, 'level')}/> 
								                                  {lt.NAME}
								                                  <span></span>
								                                </label>
								                              </div>
								                          	</div>
								                      );
								                  })
								              }
								        </div>

								    </div>
								</div>

								<div className="section double mt-1 mb-5">
								    <div className="form-button-group">
								        <button type="submit" className="btn btn-coach-blue rounded btn-block btn-mid text-upper">
								        	<span className="spinner-border spinner-border-sm mr-05" id="submitLoading" style={{ display: 'none' }} role="status" aria-hidden="true"></span>
								            Update
								         </button>
								    </div>
								</div>
						</form>
					</div>
				</div>
				<ModalAlert Notifications={this.state.Notifications} type={this.state.notifType} />
			</div>
		);
	}
}

export default ChangeLevel;