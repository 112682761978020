import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

class AppHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            AppHeaderClass: props.AppHeaderClass,
            AppHeaderLeft: props.AppHeaderLeft,
            AppHeaderLeftLink: props.AppHeaderLeftLink,
            AppHeaderTitle: props.AppHeaderTitle,
            AppHeaderRight: props.AppHeaderRight,
            AppHeaderRightLink: props.AppHeaderRightLink,
            AppHeaderSearch: props.AppHeaderSearch || '',
            redirect: false,
            search_text: '',
        }
    }

    render() {

        return (
                <div className={this.state.AppHeaderClass}>
                    <div className="left">
                        {this.state.AppHeaderLeft == 'showHOME' && <Link to={process.env.PUBLIC_URL+this.state.AppHeaderLeftLink} className="headerButton goBack"><ion-icon name="home" role="img" className="md hydrated" aria-label="document text outline"></ion-icon></Link>}
                        {this.state.AppHeaderLeft == 'showLINK' && <Link to={process.env.PUBLIC_URL+this.state.AppHeaderLeftLink} className="headerButton goBack"><ion-icon name="chevron-back-outline"></ion-icon></Link>}
                    </div>
                    <div className="pageTitle">{this.state.AppHeaderTitle}</div>
                    <div className="right">
                    	{this.state.AppHeaderRight &&
	                        <div className="headerButton">
	                           <button className="btn drop-btn dropdown-toggle" type="button" data-toggle="dropdown">
	                            <ion-icon name="ellipsis-vertical"></ion-icon>
	                           </button>
	                           <div className="dropdown-menu">
	                                <Link className="dropdown-item" to={'/Logout'}>Logout</Link>
	                            </div>
	                        </div>
	                    }
                    </div>
                </div>
        );
    }
}

export default AppHeader;