import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import AppHeader from './AppHeader';
import Loader from './Loader';
import $ from "jquery";
import Moment from 'react-moment';


const menuItems = [
        { 'title': 'Warmup', 'type': 'W', 'url':'/WorkoutDetail', 'tag':'warmup', 'icon':''},
        { 'title': 'Exercise', 'type': 'E', 'url': '/WorkoutDetail', 'tag': 'exercise', 'icon':''},
        { 'title': 'Cooldown', 'type': 'C', 'url': '/WorkoutDetail', 'tag': 'cooldown', 'icon':''},
];

class WorkoutMenu extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            activeClass: props.activeClass,
            scheduleid: props.scheduleid,
            mode: props.mode,
            subscriptionid: props.subscriptionid,
            scheduleDate: props.date
        }
    }

    render() {
        return (
            <div className="section user-status day-display">
                <ul className="listview image-listview media mb-0">
                    <li>
                        <div className="item">
                            <div className="in starts">
                                <div>
                                    <h3 className="pb-0 mb-1 font-18  ft-wt-900 text-blue2">
                                        <Moment date={this.state.scheduleDate} format="dddd"> </Moment> &nbsp;
                                        
                                        <span className="ft-wt-400">
                                            <Moment date={this.state.scheduleDate} format="MMM DD"> </Moment>
                                        </span>
                                    </h3>

                                    <div className="text-grey ft-wt-500 font-13">
                                        {menuItems.length > 0 && this.props.length > 0 &&
                                            menuItems.map((menuitem, i)=>{
                                                let activeClass = "";
                                                let url_str = "#";
                                                let scid = this.state.scheduleid;

                                                if(this.props.type==menuitem.type) {
                                                    activeClass = 'active';
                                                }

                                                // if(this.state.scheduleid != 0) {
                                                    url_str = menuitem.url
                                                                +'/'+menuitem.type
                                                                +'/'+scid
                                                                +'/'+this.state.subscriptionid
                                                                +'/'+this.props.lvl_id
                                                                +'/'+this.props.week_id
                                                                +'/'+this.props.day_id
                                                                +'/'+this.props.s_date;
                                                // }

                                                return(
                                                    <Link to={url_str} className={activeClass} key={i}>
                                                        {menuitem.title}
                                                        &nbsp;
                                                    </Link>
                                                );
                                            })
                                        }
                                    </div>
                                </div>

                                {/*<Link type="button" className="btn btn-outline-secondary rounded btn-ssm mb-0 font-11"> Start </Link>*/}
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        );
    }
}

export default WorkoutMenu;