import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import AppHeader from './AppHeader';
import Loader from './Loader';
import AppBottomMenu from './AppBottomMenu';
import { CompareDate, formatDate } from './Common';
import Moment from 'react-moment';
import $ from 'jquery';

class Workout extends Component {
    constructor(props) {
            super(props);
            this.state = {
            Notifications: false,
            LOADING: true,
            weeks: [],
            days:[],
            schedule: [],
            userDetails:[],
            currentWEEK: (props.match.params.week)?props.match.params.week:'1',
            customerid: localStorage.getItem('userData'),
            TODAY: formatDate(new Date()),
            WO_COMPLETED: "",
            ALLOWED: true,
            alertNotif: "",
            alertTitle: "",
            redirectRenewal: "N",
            redirectPayment: "N",
        };

        this._clickConfrim = this._clickConfrim.bind(this);
    }

    _clickConfrim () {
    	this.setState({
    		redirectPayment: "Y",

    	})
    }

    getWorkoutShedule() {
        fetch(global.config.bURL+"schedule.php?mode=SCHEDULE&customer="+this.state.customerid+"&s_id="+localStorage.getItem("subscriptionID"), {method:'POST', headers:{ 'Content-Type': 'application/json' }})
            .then(function (response) {
                return response.json();
            })
            .then((result)=> {
            	
                let responseJson = result;
                let paid_status = responseJson.SUBSCRIPTION.PAID;
                let exp_date = responseJson.SUBSCRIPTION.END;
                let allowed_flag = true;
                let alert_title = "";
                let alert_notif = "";

                if(exp_date < this.state.TODAY) {
                
                	allowed_flag = false;
                	alert_title = "Subscription Expired";
                	alert_notif = "Your subscription has expired";	
                }
                else if(paid_status=='N') {
                
                	allowed_flag = false;
                	alert_title = "Incomplete Payment";
                	alert_notif = "Please complete the payment to continue";
                }

                
                this.setState({ schedule: responseJson.SCHEDULE, weeks:responseJson.WEEKS, subscription: responseJson.SUBSCRIPTION, LOADING: false, Logged: true,
                        WO_COMPLETED: responseJson.SCHEDULE.WO_COMPLETED,
                        ALLOWED: allowed_flag,
                        alertTitle: alert_title,
                        alertNotif: alert_notif
                     });

		        if (alert_notif!='')
		            window.$('#ModalAlert').modal('show'); 
            });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.match.params.week !== this.props.match.params.week) {
            console.log('component updated...');
            this.setState({
                LOADING: true
            })
            window.location.reload(); 
            // this.getWorkoutShedule();
        }
    }

    componentWillMount() {
        let mount = $("div").hasClass("modal-backdrop");
        if (mount == true) {
            $('body').removeClass('modal-open');
            $(".modal-backdrop").remove();
            //window.location.reload(true);
        } 
    }

    componentDidMount() {
        this.getWorkoutShedule();
    }

    render() {
        let AppHeaderClass = 'appHeader no-border ';
        let AppHeaderLeft = 'showLINK';
        let AppHeaderLeftLink = '/Home';
        let AppHeaderTitle = 'Workout';
        let AppHeaderRight = '';
        let AppHeaderRightLink = '';
        let day = 1;

        if(!this.state.customerid) {
          	localStorage.setItem('userData',"");
          	return (<Redirect to={'/'}/>);
        }

        if(this.state.redirectRenewal=='Y') {
        	return (<Redirect to={'/'}/>);	
        }

        if(this.state.redirectPayment=='Y') {
        	return (<Redirect to={'/ContinuePayment'}/>);
        }

        return(
            <div>
                {this.state.LOADING && <Loader />}

                <AppHeader AppHeaderClass={AppHeaderClass} AppHeaderLeft={AppHeaderLeft} AppHeaderLeftLink={AppHeaderLeftLink} AppHeaderTitle={AppHeaderTitle} AppHeaderRight={AppHeaderRight} AppHeaderRightLink={AppHeaderRightLink} />

                <div id="appCapsule" className="pb-5">
                    {this.state.schedule.length > 0 &&
                        this.state.schedule.map((schedule, i) => {

                            let level_id = schedule.LEVEL;
                            let week_id = schedule.WEEK;
                            let day_id = schedule.DAY;
                            let s_date = schedule.DATE;

                            let w_edit_url = "";                            
                            let image_src = "";
                            let prefix_text = "";
                            

                            if(schedule.ID) {
                                w_edit_url = '/WorkoutDetail/W/'+schedule.ID
                                                +'/'+localStorage.getItem('subscriptionID');
                            }
                            else {
                                w_edit_url = '/WorkoutDetail/W/'+schedule.ID
                                                +'/'+localStorage.getItem('subscriptionID')
                                                +'/'+level_id
                                                +'/'+week_id
                                                +'/'+day_id
                                                +'/'+s_date;
                            }

                            if(this.state.WO_COMPLETED==0) {
                                w_edit_url = '/WorkoutSummary/'+schedule.ID;
                            }

                            if(!this.state.ALLOWED) {
                            	w_edit_url = "#"
                            }

                            if(schedule.IMAGE!='') {
                            	image_src = schedule.IMAGE;
                            }
                            else if (this.state.subscription.PROGRAM_IMAGE) {
                            	image_src = this.state.subscription.PROGRAM_IMAGE;
                            }
                            else {
                            	image_src = global.config.defaultWorkoutImage;
                            }


                            // let image_src = (schedule).IMAGE?schedule.IMAGE:global.config.defaultWorkoutImage;

                            if(this.state.subscription.START > this.state.TODAY)
                            {
                            	prefix_text = "Starts On ";

                            	return (
                            	    <div className="section mt-1 mb-1" key={i}>
                            	        <div className="exercise exercise-new work-exe">
                            	            <img src={image_src} className="card-img-top imaged" alt="image" />
                            	             <div className="workout-text">
                            	                <p className="text-blue text-upper mb-0 font-18 ft-wt-600">{this.state.subscription.PROGRAM_NAME}</p>
                            	            </div>
                            	            <div className="exercise-text">
                            	                <div className="text-left">
                            	                    <h5 className="text-white m-0 ft-wt-300 text-upper">{prefix_text}</h5>
                            	                    <p className="text-blue m-0 font-13 ft-wt-500">
                            	                          <Moment date={schedule.DATE} format="DD MMM YYYY"> </Moment>
                            	                    </p>
                            	                </div>
                            	            </div>
                            	        </div>
                            	    </div>
                            	);
                            }
                            else if(this.state.subscription.END < this.state.TODAY)
                            {
                            	prefix_text = "Ended On ";

                            	if(this.state.subscription.END == schedule.DATE)
                            	{
	                            	return (
	                            	    <div className="section mt-1 mb-1" key={i}>
	                            	        <div className="exercise exercise-new work-exe">
	                            	            <img src={image_src} className="card-img-top imaged" alt="image" />
	                            	             <div className="workout-text">
	                            	                <p className="text-blue text-upper mb-0 font-18 ft-wt-600">{this.state.subscription.PROGRAM_NAME}</p>
	                            	            </div>
	                            	            <div className="exercise-text">
	                            	                <div className="text-left">
	                            	                    <h5 className="text-white m-0 ft-wt-300 text-upper">{prefix_text}</h5>
	                            	                    <p className="text-blue m-0 font-13 ft-wt-500">
	                            	                          <Moment date={schedule.DATE} format="DD MMM YYYY"> </Moment>
	                            	                    </p>
	                            	                </div>
	                            	            </div>
	                            	        </div>
	                            	    </div>
	                            	);
	                            }
                            }
                            else if(schedule.DATE == this.state.TODAY) 
                            {
                        	    return (
                        	        <div className="section mt-1 mb-1" key={i}>
                        	            <div className="exercise exercise-new work-exe">
                        	                <img src={image_src} className="card-img-top imaged" alt="image" />
                        	                 <div className="workout-text">
                        	                        <p className="text-blue text-upper mb-0 font-18 ft-wt-600">{this.state.subscription.PROGRAM_NAME}</p>
                        	                        <h2 className="text-white text-upper ft-wt-500">{schedule.NAME}</h2>
                        	                </div>
                        	                <div className="exercise-text">
                        	                    <div className="text-left">
                        	                        <h5 className="text-white m-0 ft-wt-300 text-upper">Day {schedule.DAY}</h5>
                        	                        <p className="text-blue m-0 font-13 ft-wt-500">
                        	                              <Moment date={schedule.DATE} format="DD MMM YYYY"> </Moment>
                        	                        </p>
                        	                    </div>
                        	                    <Link className="btn btn-coach-pink rounded btn-mid  text-upper " to={w_edit_url}> Start</Link>
                        	                </div>
                        	            </div>
                        	        </div>
                        	    );
                        	}	
                        })
                    }

                    {this.state.weeks.length > 0 && this.state.ALLOWED &&
                        <div className="section user-status">
                            <ul className="listview image-listview media mb-1">
                               <li>
                                  <Link to={'/Calendar'} className="item">
                                     <div className="in">
                                        <div>
                                           <div className="font-18 text-gre5 ft-wt-600">Activities</div>
                                        </div>
                                        <span className="btn btn-outline-secondary rounded btn-ssm mb-0 font-11 lp-2">VIEW ALL</span>
                                     </div>
                                  </Link>
                               </li>
                            </ul>
                        </div>
                    }
                    
                    <div className="section activites pt-0 pb-0 pr-0 pl-0 item-tab">
                        <div className="wide-block wide-block2">
                          <div className="text-upper pb-1" style={{display: "flex"}}> 
                          {this.state.weeks.length > 0 &&
                            this.state.weeks.map((week, i) =>{                            	
                            	let activeWEEK = "";
                            	let url_str = "#";

                            	if(week==this.state.currentWEEK) {
                            		activeWEEK = 'activeb';
                            	}

                            	if(this.state.ALLOWED) {
                            		url_str = "/Workout2/"+week
                            	}

                                return (
                                    <Link key={i} className={"btn btn-outline-mix rounded btn-lsm mr-1 newshadowed "+activeWEEK} to={url_str}>Week {week}</Link>
                                );
                            })
                          }
                          </div>
                        </div>
                      </div>

                      {this.state.ALLOWED &&
                    <div className="section user-status">
                        <ul className="listview image-listview media">
                           <li>
                              <Link to={"/Calendar"} className="item">
                                 <div className="in">
                                    <div className="font-18 text-gre5 ft-wt-600">
                                       Schedule
                                    </div>
                                    <span className="btn btn-outline-secondary rounded btn-ssm mb-0 font-11 lp-2">VIEW CALENDAR</span>
                                 </div>
                              </Link>
                           </li>
                        </ul>
                    </div>
                	}

                    <div>
                        <ul className="listview image-listview media mb-2">
                            {this.state.schedule.length>0 &&
                                this.state.schedule.map((schedule, i)=>{
                                    
                                    if(schedule.DISPLAY_WEEK == this.state.currentWEEK) 
                                    {
                                        let day_num = day++;
                                        let level_id = schedule.LEVEL;
                                        let week_id = schedule.WEEK;
                                        let day_id = schedule.DAY;
                                        let s_date = schedule.DATE;

                                        let w_edit_url = "";



                                        if(schedule.ID) {
                                            w_edit_url = '/WorkoutDetail/W/'+schedule.ID
                                                            +'/'+localStorage.getItem('subscriptionID');
                                        }
                                        else {
                                            w_edit_url = '/WorkoutDetail/W/'+schedule.ID
                                                            +'/'+localStorage.getItem('subscriptionID')
                                                            +'/'+level_id
                                                            +'/'+week_id
                                                            +'/'+day_id
                                                            +'/'+s_date;
                                        }

                                        if(this.state.WO_COMPLETED==0) {
                                            w_edit_url = '/WorkoutSummary/'+schedule.ID;
                                        }

                                        if(!this.state.ALLOWED) {
                                        	w_edit_url = "#"
                                        }

                                        return(
                                            <li key={i}>
                                                <Link to={w_edit_url} className="item">
                                                    <div className="in">
                                                        <div className="text-gre5 ft-wt-600 font-15">
                                                            <div className="text-blue ft-wt-400 font-13">Day {day_num}</div>
                                                            {schedule.NAME}
                                                        </div>
                                                    </div>
                                                </Link>
                                            </li>
                                        );
                                    }
                                    /*else
                                    {
                                        if(i==0) {
                                            return (
                                                <li>
                                                    <div className="in">
                                                        <div className="text-gre5 ft-wt-600 font-15">
                                                            <div className="text-blue ft-wt-400 font-13">You have completed all your workouts!</div>
                                                        </div>
                                                    </div>
                                                </li>
                                            );
                                        }
                                    }*/
                                })
                            }
                        </ul>
                    </div>
                </div>

                <div className="modal fade dialogbox" id="ModalAlert" data-backdrop="static" tabIndex="-1" role="dialog">
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                    	<div className="modal-icon text-danger">
                    	    <ion-icon name="close-circle"></ion-icon>
                    	</div>
	                	
	                	<div className="modal-header">
	                    	<h5 className="modal-title">{this.state.alertTitle}</h5>
	                  	</div>
                      	<div className="modal-body">{this.state.alertNotif}</div>
                      	<div className="modal-footer">
                        <div className="btn-inline"> 
                            <button type="button" className="btn btn-text-secondary" data-dismiss="modal">CANCEL</button> 
                            <button type="button" onClick={this._clickConfrim} className="btn btn-text-primary" data-dismiss="modal">PROCEED</button> 
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {this.state.schedule &&
                	<AppBottomMenu activeClass={"workout"}  allowClick={this.state.ALLOWED}/>
                }
            </div>
        );
    }
}

export default Workout;