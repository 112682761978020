import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import AppHeader from './AppHeader';
import Loader from './Loader';
import $ from "jquery";
import {validatedate, validdate} from "./Common";
// import 'bootstrap';

class PaymentNow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            LOADING: true,
            Notifications: false,
            Logged: false,
            planType: props.match.params.type,
            planID: props.match.params.pid,
            basicPackages: [],
            premiumPackages:[],
            packages:[],
            planChecked: '',
            tSHIRT: [],
            GENDER: [],
            customer: localStorage.getItem('userData'),
            PayNow: "N",
        };

        this.onRadioChange = this.onRadioChange.bind(this);
    }
    handleSubmit = (e) => {
        e.preventDefault();

        let err = 0;
        if ($('#frmRegister')[0].checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            err++;
        }
        $('#frmRegister')[0].classList.add('was-validated');

        let subscr = $('input[name="rdsubscription"]:checked');

        if (this.state.planType && subscr.length==0) {
            err++;
            //alert('Please select a subscription plan');
            $("#subsc-invalid").html("Please select a subscription plan");
        }

        if (err == 0) {
            $('#submitLoading').css('display', 'block');

            const method = "POST";
            const body = new FormData(this.form);
            var that = this;

            fetch(global.config.bURL+"customer_subscription_upgrade.php", { method, body })
                .then(function (response) {
                    //console.log(response);
                    return response.json();
                })
                .then(function (result) {

                    //console.log(result);
                    //alert(result);
                    let notify = '';
                    if (result.err != '') {
                        if (result.err == '1') notify = 'Name Field is Empty';
                        else if (result.err == '2') notify = 'Invalid Customer ID';
                        else if (result.err == '3') notify = 'Email Id Field is Empty';
                        else if (result.err == '4') notify = 'Password Field is Empty';
                        else if (result.err == '5') notify = 'Password Field is Empty';
                        else if (result.err == '6') notify = 'Please Check The Terms &amp; Condtions';
                        else if (result.err == '7') notify = 'Mobile No Already Exists';
                        else if (result.err == '8') notify = 'Invalid Access Detected';
                    }

                    let registered = false;

                    let customer = result.customer;
                    if (customer != '' && customer != undefined) {
                        localStorage.setItem('userData', customer);
                        localStorage.setItem('subscriptionID', result.SUBSCRIPTION_ID);
                        localStorage.setItem('Verified', 'N');
                        registered = true;
                    }

                    that.setState({ Notifications: notify, Registered: registered, PayNow: result.FREE_TRIAL});
                     $('#submitLoading').css('display', 'none');
                    if (notify != '')
                        window.$('#ModalAlert').modal('show'); 
                });
        }
    }

    handleClickGetTerms = () => {
        // alert('test');
        window.$('#ModalTerms').modal('show');
    }

    handlePlanClick = (planid) => {
        // alert(planid);
        //localStorage.setItem('planChecked', planid);
        /*this.setState({
            planChecked: planid,
        })*/
        // defaultChecked={payMode=='2'?true:false}
    }

    applyCouponcode = () => {
        let inp_code = $('#txtcouponcode').val();
        let inp_subscr = $('input[name="rdsubscription"]:checked');
        let curr_type = localStorage.getItem('CURRENCY_TYPE')?localStorage.getItem('CURRENCY_TYPE'):'INR';
        let coupon_code = (inp_code!='')?inp_code:0;
        let subscr = (inp_subscr.val()!='')?inp_subscr.val():0;
        console.log('subscr', subscr);

        if (subscr==0 || subscr==undefined) {
            $("#subsc-invalid").html("Please select a subscription plan");
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ mode: 'APPLY_COUPON', clientid: this.state.customer, code:coupon_code, tariffid: subscr, currType:curr_type})
        };

        fetch(global.config.bURL+"global_data.php", requestOptions)
            .then(function (response) {
                return response.json();
            })
            .then((result)=> {
                console.log(result);
               if(result.err) {
                   $('#err_coupon').html(result.err);
               }
               else {
                   // localstorageresult.COUPON_CODE                 
                   let msg_str = "You have redeemed coupon worth "+result.COUPON_AMOUNT;
                   $('#err_coupon').html(msg_str);   
               }
            });
    }

    getPackages() {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ mode: 'PACKAGES', p_id: this.state.planID, type:this.state.planType})
        };

        fetch(global.config.bURL+"packages.php?mode=PACKAGES&p_id="+this.state.planID+'&type='+this.state.planType, requestOptions)
            .then(function (response) {
                return response.json();
            })
            .then((result)=> {
                let responseJson = result;
                // console.log(responseJson);
                let notify = "";
                if (result.err != '') {
                    if (result.err == '1') notify = 'Invalid Parameter';
                    else if (result.err == '2') notify = 'Invalid Package selected';
                    else if (result.err == '3') notify = 'No Subscription Package found for this plan';
                }

                if(this.state.planType=='B') {
                    this.setState({
                        packages: responseJson.BASIC,
                        LOADING: false,
                        Notifications: notify
                    });
                }
                else {
                    this.setState({
                        packages: responseJson.PREMIUM,
                        LOADING: false,
                        Notifications: notify
                    });   
                }

                if (notify != '')
                    window.$('#ModalAlert').modal('show'); 
            });

         const requestOptions2 = {
             method: 'POST',
             headers: { 'Content-Type': 'application/json' },
             body: JSON.stringify({ mode: 'GLOBALS'})
         };
        fetch(global.config.bURL+"global_data.php", requestOptions2)
            .then(function (response) {
                return response.json();
            })
            .then((result)=> {
            	// console.log(result);
            	this.setState({
            		tSHIRT: result.SIZES,
            		GENDER: result.GENDER,
            	})
            });
    }

    componentWillMount() {
         let mount = $("div").hasClass("modal-backdrop");
        if (mount == true) {
            $('body').removeClass('modal-open');
            $(".modal-backdrop").remove();
            //window.location.reload(true);
        } 
    }

    componentDidMount() {
        this.getPackages();
        this.setState({ LOADING: false });
    }

    onRadioChange = e => {
        const { name, value } = e.target;

        this.setState({
          [name]: value
        });
    };

    render() {

        let appTitle = "";

        if(this.state.planType=='B') appTitle = "Basic Plan Subscription";
        else if(this.state.planType=='P') appTitle = "Premium Plan Subscription";
        else if(!this.state.planType) appTitle = "7 Day Free Trial";

        let AppHeaderClass = 'appHeader no-border bg-common-header text-light';
        let AppHeaderLeft = 'showLINK';
        let AppHeaderLeftLink = '/Subscription';
        let AppHeaderTitle = appTitle;
        let AppHeaderRight = '';
        let AppHeaderRightLink = '';
      	
      	// console.log(this.state.customer)
        if(!this.state.customer) {
        	return (<Redirect to={'/'}/>);
        }
        if(!this.state.planID) {
            return (<Redirect to={'/'}/>);
        }

        if(this.state.Registered) {

        	if(this.state.PayNow == "N") {
            	return (<Redirect to={'/OnlinePay'} />)
            }
            else {
            	return (<Redirect to={'/Workout'} />)
            }
        }

        let dietTARIFFAVL = "N";
        let currency_type = localStorage.getItem('CURRENCY_TYPE')?localStorage.getItem('CURRENCY_TYPE'):'INR';

        return (
            <div>
                {this.state.LOADING &&
                    <Loader />
                }
                <AppHeader AppHeaderClass={AppHeaderClass} AppHeaderLeft={AppHeaderLeft} AppHeaderLeftLink={AppHeaderLeftLink} AppHeaderTitle={AppHeaderTitle} AppHeaderRight={AppHeaderRight} AppHeaderRightLink={AppHeaderRightLink} />
                <div id="appCapsule">
                	<div className="login-form">
                    <form id="frmRegister" ref={el =>
                        (this.form = el)} onSubmit={this.handleSubmit} className="needs-validation" noValidate>
                        <input type="hidden" value="UPGRADE" className="form-control" name="mode" id="mode" />
                        <input type="hidden" value={localStorage.getItem('storedSelection')} className="form-control" name="params" id="params" />
                        <input type="hidden" value={this.state.planID} className="form-control" name="program_id" id="program_id" />
                        <input type="hidden" value={this.state.planType} className="form-control" name="sub_type" id="sub_type" />
                        <input type="hidden" value={this.state.customer} className="form-control" name="customer" id="customer" />
                        <input type="hidden" value={currency_type} className="form-control" name="curr_type" id="curr_type" />


                            {this.state.planType!=undefined && this.state.planType!='' &&
                                <div className="section payment-opts full mt-0 pt-2 mb-0 pl-3 pr-3 bg-coach-org-pink">
                                    <div className="section-title btn-titles pl-0 pr-0 mb-05 text-white ft-wt-300">Choose your Workout Plan</div>
                                    <div className="wide-block bg-transparent pt-0 pb-2 pl-0 pr-0 no-border plans-opt">
                                        {this.state.packages &&
                                            this.state.packages.map((subs_package, i)=>{
                                                let saved_amount = "";
                                                let original_amount = "";
                                                let price_amount = "";
                                                let duration_str = "";
                                                let currency_symb = "";
                                                // console.log(currency_type);

                                                if(currency_type=='INR') {
                                                	saved_amount = parseInt(subs_package.PACKAGE_ORIGINALPRICE)-parseInt(subs_package.PACKAGE_PRICE);
                                                	original_amount = subs_package.PACKAGE_ORIGINALPRICE;
                                                	price_amount = subs_package.PACKAGE_PRICE;
                                                	currency_symb = '&#x20B9;';
                                                }
                                                else {
                                                	saved_amount = parseInt(subs_package.PACKAGE_ORIGINALPRICE_USD)-parseInt(subs_package.PACKAGE_PRICE_USD);
                                                	original_amount = subs_package.PACKAGE_ORIGINALPRICE_USD;
                                                	price_amount = subs_package.PACKAGE_PRICE_USD;
                                                	currency_symb = '&#36;';
                                                }
                                                
                                                if(subs_package.PACKAGE_DURATIONTYPE=='M') {
                                                    duration_str = subs_package.PACKAGE_DURATION+' Month';
                                                }
                                                else if(subs_package.PACKAGE_DURATIONTYPE=='W') {
                                                    duration_str = subs_package.PACKAGE_DURATION+' Week';
                                                }
                                                else if(subs_package.PACKAGE_DURATIONTYPE=='Y') {
                                                    duration_str = subs_package.PACKAGE_DURATION+' Year';
                                                }

                                                if(subs_package.PACKAGE_TYPE=='D') dietTARIFFAVL = 'Y';

                                                if(subs_package.PACKAGE_TYPE=='P')
                                                {
    	                                            return(
    	                                                <div className="each-price custom-control custom-radio mb-2" key={i} >
    	                                                    <input type="radio" name="rdsubscription" className="custom-control-input" defaultValue={subs_package.TARIFF_ID} required="required" />

    	                                                    <label className="custom-control-label" >
    	                                                        <span>
    	                                                            {original_amount >0 && original_amount!=price_amount &&
    	                                                                <p className="cancel"><span className="cancel-price" dangerouslySetInnerHTML={{ __html:currency_symb}} />  {original_amount}</p>
    	                                                            }
    	                                                            
    	                                                            {saved_amount >0 &&
    	                                                                <p>Save <span className="cancel-price" dangerouslySetInnerHTML={{ __html:currency_symb}} /> {saved_amount}</p>
    	                                                            }
    	                                                        </span>
    	                                                        <span><h5><span className="ft-wt-300" dangerouslySetInnerHTML={{ __html:currency_symb}} /> {price_amount}</h5></span>
    	                                                        <span><p>{duration_str}</p></span>                            
    	                                                    </label>
    	                                                </div>
    	                                            );
    	                                        }
                                            })
                                        }

                                        <div className="valid-feedback">&nbsp;</div>
                                        <div id="subsc-invalid" className="err-required"></div>
                                    </div>
                                </div>
                            }

                            {dietTARIFFAVL == 'Y' &&
	                            <div className="section payment-opts full mt-0 pt-2 mb-2 pl-3 pr-3 bg-coach-greenish">
	                                <div className="section-title btn-titles pl-0 pr-0 mb-05 text-white ft-wt-300">Upgrade Diet Plans (Optional)</div>
	                                <div className="wide-block bg-transparent pt-0 pb-2 pl-0 pr-0 no-border plans-opt">
	                                    {this.state.packages &&
                                        	this.state.packages.map((subs_package, i)=>{ 
		                                     	let saved_amount = "";
	                                            let original_amount = "";
	                                            let price_amount = "";
	                                            let duration_str = "";
	                                            let currency_symb = "";
	                                            // console.log(currency_type);

	                                            if(currency_type=='INR') {
	                                            	saved_amount = parseInt(subs_package.PACKAGE_ORIGINALPRICE)-parseInt(subs_package.PACKAGE_PRICE);
	                                            	original_amount = subs_package.PACKAGE_ORIGINALPRICE;
	                                            	price_amount = subs_package.PACKAGE_PRICE;
	                                            	currency_symb = '&#x20B9;';
	                                            }
	                                            else {
	                                            	saved_amount = parseInt(subs_package.PACKAGE_ORIGINALPRICE_USD)-parseInt(subs_package.PACKAGE_PRICE_USD);
	                                            	original_amount = subs_package.PACKAGE_ORIGINALPRICE_USD;
	                                            	price_amount = subs_package.PACKAGE_PRICE_USD;
	                                            	currency_symb = '&#36;';
	                                            }
	                                            
	                                            if(subs_package.PACKAGE_DURATIONTYPE=='M') {
	                                                duration_str = subs_package.PACKAGE_DURATION+' Month';
	                                            }
	                                            else if(subs_package.PACKAGE_DURATIONTYPE=='W') {
	                                                duration_str = subs_package.PACKAGE_DURATION+' Week';
	                                            }
	                                            else if(subs_package.PACKAGE_DURATIONTYPE=='Y') {
	                                                duration_str = subs_package.PACKAGE_DURATION+' Year';
	                                            }

	                                            if(subs_package.PACKAGE_TYPE=='D')
	                                            {
		                                            return(
		                                                <div className="each-price custom-control custom-radio mb-2" key={i} >
	                                                    <p className="mb-0 ft-wt-500 font-14 text-left text-white">{subs_package.PACKAGE_NAME}</p>

		                                                    <input type="radio" name="rddiet" className="custom-control-input" defaultValue={subs_package.TARIFF_ID} />

		                                                    <label className="custom-control-label" >

		                                                        <span>
		                                                            {original_amount >0 && original_amount!=price_amount &&
		                                                                <p className="cancel"><span className="cancel-price" dangerouslySetInnerHTML={{ __html:currency_symb}}/>  {original_amount}</p>
		                                                            }
		                                                            
		                                                            {saved_amount >0 &&
		                                                                <p>Save <span className="cancel-price" dangerouslySetInnerHTML={{ __html:currency_symb}} /> {saved_amount}</p>
		                                                            }
		                                                        </span>
		                                                        <span><h5><span className="ft-wt-300" dangerouslySetInnerHTML={{ __html:currency_symb}} /> {price_amount}</h5></span>
		                                                        <span><p>{duration_str}</p></span>                            
		                                                    </label>
		                                                </div>
		                                            );
		                                        }
	                                        })
                                    }

	                                    <div className="valid-feedback">&nbsp;</div>
	                                    <div id="subsc-invalid" className="err-required"></div>
	                                </div>
	                            </div>
                            }


                            <div className="form-group basic pr-1 pl-1 pb-0">
                                <div className="divide-50l">
                                    <div className="input-wrapper guest-detail float-label">
                                        <label className="label">Coupon Code</label>                                        
                                        <input type="text" className="form-control input-box inputText " name="txtcouponcode" id="txtcouponcode" autoComplete="off" />
                                        <i className="clear-input">
                                            <ion-icon name="close-circle" role="img" className="md hydrated" aria-label="close circle"></ion-icon>
                                        </i>
                                    </div>
                                    {/*<div className="input-info">Enter coupon code</div>*/}
                                </div>

                                <div className="divide-50r">
                                    <div className="">
                                        <button type="button" className="btn mt-6 text-upper w-100 btn-outline-secondary rounded btn-ssm mb-0 font-11 lp-2" name="btnapplycoupon" onClick={(e)=> {this.applyCouponcode()}} id="btnapplycoupon">Apply</button>
                                    </div>
                                </div>
                                <div className="clear"></div>
                            </div>
                            <div id="err_coupon" className="err-required"></div>
                            <div className="clear"></div>

                            <div className="section double mt-1 mb-5">
		                        <div className="mt-4">
		                            <button type="submit" className="btn btn-coach-pink rounded btn-block btn-mid text-upper">
                                    	<span className="spinner-border spinner-border-sm mr-05" id="submitLoading" style={{ display: 'none' }} role="status" aria-hidden="true"></span>
    				            		Pay Now
        				        	</button>
		                        </div>
		                    </div>
                    </form>
                    </div>

                        <div className="modal fade dialogbox" id="ModalAlert" data-backdrop="static" tabIndex="-1" role="dialog">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-icon text-danger">
                                        <ion-icon name="close-circle"></ion-icon>
                                    </div>
                                    <div className="modal-body"> 
                                            {this.state.Notifications} 
                                    </div>
                                    <div className="modal-footer">
                                        <div className="btn-inline"> 
                                            <button type="button" className="btn" data-dismiss="modal">Ok</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        )
    }
}
export default PaymentNow;