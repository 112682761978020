import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import AppHeader from './AppHeader';
import Loader from './Loader';
import AppBottomMenu from './AppBottomMenu';
import {secondsToHms} from './Common';
import ModalAlert from './ModalAlert';
import $ from 'jquery';

class WorkoutSummary extends Component {
    constructor(props) {
        super(props);

        this.state= {
        	scheduleID: props.match.params.scheduleid,
        	customerid: localStorage.getItem('userData'),
        	subscriptionID: localStorage.getItem('subscriptionid'),
        	Notifications: "",
        	notifType: "",
        	timeTaken: "11",
        	SCHEDULE: [],
        	WARMUP: [],
        	EXERCISE: [],
        	COOLDOWN: [],
        	WO_COMPLETE: "N", 
        	WO_EXECOUNT: 0,
        	redirectWorkout: false,
        }

        this.confirmComplete = this.confirmComplete.bind(this);
        this._markComplete = this._markComplete.bind(this);
        this._redirectWorkout = this._redirectWorkout.bind(this);
    }

    confirmComplete() {

    	this.setState({
    		Notifications: "You are about to mark this workout complete, Continue?",
    		notifType: "success",
    	});

		window.$('#ModalAlert').modal('show'); 
    }

    _markComplete() {
    	let url_str = "schedule_exercise_submit.php?mode=SCHEDULE_EXERCISE_SUBMIT&customer="+this.state.customerid
    	                +"&s_id="+this.state.subscriptionID
    	                +"&sc_id="+this.state.scheduleID
    	                +"&time_taken="+this.state.timeTaken;

    	fetch(global.config.bURL+url_str, {method:'POST', headers:{ 'Content-Type': 'application/json' }})
    	    .then(function (response) {
    	        return response.json();
    	    })
    	    .then((result)=> {
    	        this.setState({ 
    	            // $arr['SUBSCRIPTION'] = $s_id;
    	            WO_COMPLETE : result.COMPLETE,
    	            WO_EXECOUNT : result.EXERCISES,	
    	        });
    	    });

    	   	window.$('#ModalAlert2').modal('show');
    }

    _redirectWorkout() {
    	this.setState({
    		redirectWorkout: true,
    	});
    }

    getScheduleSummary() {
    	let url_str = "schedule_exercise_summary.php?mode=SCHEDULE_EXERCISE_SUMMARY&customer="+this.state.customerid
    	                +"&s_id="+this.state.subscriptionID
    	                +"&sc_id="+this.state.scheduleID;

    	fetch(global.config.bURL+url_str, {method:'POST', headers:{ 'Content-Type': 'application/json' }})
    	    .then(function (response) {
    	        return response.json();
    	    })
    	    .then((result)=> {
    	        // console.log(result);
    	        this.setState({ 
    	            SCHEDULE: result.SCHEDULE, 
    	            WARMUP: result.SCHEDULE_WARMUP,
    	            EXERCISE: result.SCHEDULE_EXERCISE,
    	            COOLDOWN: result.SCHEDULE_COOLDOWN,
    	            LOADING: false, 
    	            Logged: true 
    	        });
    	    });
    }

    componentWillMount() {
         let mount = $("div").hasClass("modal-backdrop");
        if (mount == true) {
            $('body').removeClass('modal-open');
            $(".modal-backdrop").remove();
            //window.location.reload(true);
        } 
    }

    componentDidMount() {
    	this.getScheduleSummary();
    }

    render () {

    	let AppHeaderClass = 'appHeader no-border bg-common-header text-light';
    	let AppHeaderLeft = 'showLINK';
    	let AppHeaderLeftLink = '/Workout';
    	let AppHeaderTitle = 'Summary';
    	let AppHeaderRight = '';
    	let AppHeaderRightLink = '';

    	if(this.state.redirectWorkout) {
    		return(<Redirect to={'/Workout'} />);
    	}

    	return (
    		<div>
    		    {this.state.LOADING && <Loader />}

    		    <AppHeader AppHeaderClass={AppHeaderClass} AppHeaderLeft={AppHeaderLeft} AppHeaderLeftLink={AppHeaderLeftLink} AppHeaderTitle={AppHeaderTitle} AppHeaderRight={AppHeaderRight} AppHeaderRightLink={AppHeaderRightLink} />

    		    <div id="appCapsule">
    		        <div className="section pr-0 pl-0 pb-1 pt-0 basic-form summary">
		            	<div className="listview-title mt-2">{this.state.SCHEDULE.NAME}</div>
		                <div className="section p-0  mt-0"> 
		                	{this.state.WARMUP.length > 0 &&
		                		this.state.WARMUP.map((warmup, i)=> {
		                			let detail_str = "";
		                			let image_src = (warmup.WORKOUT_IMAGE)?warmup.WORKOUT_IMAGE:global.config.defaultImage;
		                			
		                			detail_str += (warmup.SETS!=0)?warmup.SETS+' Sets | ':''
		                			detail_str += (warmup.REPS!=0)?warmup.REPS+' Reps | ':''
		                			detail_str += (warmup.TIME!=0)?'Time '+secondsToHms(warmup.TIME)+' | ':''
		                			detail_str += (warmup.REST!=0 && warmup.REST!=undefined)?'Rest '+secondsToHms(warmup.REST, warmup.RESTTIMETYPE)+'  ':''

		                			return(
					                    <div className="acc-options work-er accordion edited-accordian no-lines" id={"accordionExample"+i} key={i}>
			                                <div className="item">
			                                    <div className="accordion-header">
			                                        <button className="btn collapsed" type="button" data-toggle="collapse" data-target={"#accordion"+i} aria-expanded="false">
			                                                <img src={image_src} alt="image" className="imaged w64 mr-2" />
			                                                <div className="in">
			                                                    <div className="text-gre5 ft-wt-600 font-15">
			                                                        { (warmup.WORKOUT_NAME)?warmup.WORKOUT_NAME:warmup.TYPE }
			                                                        <div className="text-muted ft-wt-400">
			                                                        	{ detail_str.slice(0, -2) }
			                                                        </div>
			                                                    </div>
			                                                </div>
			                                        </button>
			                                    </div>

			                                    <div id={"accordion"+i} className="accordion-body collapse" data-parent={"#accordionExample"+i} >
			                                        <div className="accordion-content text-left">
			                                            <h5 className="mb-05 pb-0 font-14">Comment</h5>
			                                            <p className="mb-2">{warmup.COMMENT}</p>

		                                            	<h5 className="mb-05 pb-0 font-14">Images</h5>
		                                            	<div className="pt-1 pb-0 pr-0 pl-0">
		                                                	<div className="row">
				                                                {warmup.IMAGES &&
				                                                	warmup.IMAGES.map((images, p)=>{
				                                                		return (
				                                                			<img src="assets/img/workout3.jpg" alt="image" className="mb-2 imaged w48 mr-1 ml-1" />
				                                                		);
				                                                	})
				                                                }
				                                            </div>
		                                            	</div>

			                                        </div>
			                                    </div>

			                                </div>
			                            </div>
		                			);
		                		})
		                	}

							{this.state.EXERCISE.length > 0 &&
		                		this.state.EXERCISE.map((exercise, i)=> {
		                			let detail_str = "";
		                			let image_src = (exercise.WORKOUT_IMAGE)?exercise.WORKOUT_IMAGE:global.config.defaultImage;
		                			
		                			detail_str += (exercise.SETS!=0)?exercise.SETS+' Sets | ':''
		                			detail_str += (exercise.REPS!=0)?exercise.REPS+' Reps | ':''
		                			detail_str += (exercise.TIME!=0)?'Time '+secondsToHms(exercise.TIME)+' | ':''
		                			detail_str += (exercise.REST!=0 && exercise.REST!=undefined)?'Rest '+secondsToHms(exercise.REST, exercise.RESTTIMETYPE)+'  ':''

		                			return(
					                    <div className="acc-options work-er accordion edited-accordian no-lines" id={"accordionExample"+i} key={i}>
			                                <div className="item">
			                                    <div className="accordion-header">
			                                        <button className="btn collapsed" type="button" data-toggle="collapse" data-target={"#accordion"+i} aria-expanded="false">
			                                                <img src={image_src} alt="image" className="imaged w64 mr-2" />
			                                                <div className="in">
			                                                    <div className="text-gre5 ft-wt-600 font-15">
			                                                        { (exercise.WORKOUT_NAME)?exercise.WORKOUT_NAME:exercise.TYPE }
			                                                        <div className="text-muted ft-wt-400">
			                                                        	{ detail_str.slice(0, -2) }
			                                                        </div>
			                                                    </div>
			                                                </div>
			                                        </button>
			                                    </div>

			                                    <div id={"accordion"+i} className="accordion-body collapse" data-parent={"#accordionExample"+i} >
			                                        <div className="accordion-content text-left">
			                                            <h5 className="mb-05 pb-0 font-14">Comment</h5>
			                                            <p className="mb-2">{exercise.COMMENT}</p>

		                                            	<h5 className="mb-05 pb-0 font-14">Images</h5>
		                                            	<div className="pt-1 pb-0 pr-0 pl-0">
		                                                	<div className="row">
				                                                {exercise.IMAGES &&
				                                                	exercise.IMAGES.map((images, p)=>{
				                                                		return (
				                                                			<img src="assets/img/workout3.jpg" alt="image" className="mb-2 imaged w48 mr-1 ml-1" />
				                                                		);
				                                                	})
				                                                }
				                                            </div>
		                                            	</div>

			                                        </div>
			                                    </div>

			                                </div>
			                            </div>
		                			);
		                		})
		                	}


		                	{this.state.COOLDOWN.length > 0 &&
		                		this.state.COOLDOWN.map((cooldown, i)=> {
		                			let detail_str = "";
		                			let image_src = (cooldown.WORKOUT_IMAGE)?cooldown.WORKOUT_IMAGE:global.config.defaultImage;
		                			
		                			detail_str += (cooldown.SETS!=0)?cooldown.SETS+' Sets | ':''
		                			detail_str += (cooldown.REPS!=0)?cooldown.REPS+' Reps | ':''
		                			detail_str += (cooldown.TIME!=0)?'Time '+secondsToHms(cooldown.TIME)+' | ':''
		                			detail_str += (cooldown.REST!=0 && cooldown.REST!=undefined)?'Rest '+secondsToHms(cooldown.REST, cooldown.RESTTIMETYPE)+'  ':''

		                			return(
					                    <div className="acc-options work-er accordion edited-accordian no-lines" id={"accordionExample"+i} key={i}>
			                                <div className="item">
			                                    <div className="accordion-header">
			                                        <button className="btn collapsed" type="button" data-toggle="collapse" data-target={"#accordion"+i} aria-expanded="false">
			                                                <img src={image_src} alt="image" className="imaged w64 mr-2" />
			                                                <div className="in">
			                                                    <div className="text-gre5 ft-wt-600 font-15">
			                                                        { (cooldown.WORKOUT_NAME)?cooldown.WORKOUT_NAME:cooldown.TYPE }
			                                                        <div className="text-muted ft-wt-400">
			                                                        	{ detail_str.slice(0, -2) }
			                                                        </div>
			                                                    </div>
			                                                </div>
			                                        </button>
			                                    </div>

			                                    <div id={"accordion"+i} className="accordion-body collapse" data-parent={"#accordionExample"+i} >
			                                        <div className="accordion-content text-left">
			                                            <h5 className="mb-05 pb-0 font-14">Comment</h5>
			                                            <p className="mb-2">{cooldown.COMMENT}</p>

		                                            	<h5 className="mb-05 pb-0 font-14">Images</h5>
		                                            	<div className="pt-1 pb-0 pr-0 pl-0">
		                                                	<div className="row">
				                                                {cooldown.IMAGES &&
				                                                	cooldown.IMAGES.map((images, p)=>{
				                                                		return (
				                                                			<img src="assets/img/workout3.jpg" alt="image" className="mb-2 imaged w48 mr-1 ml-1" />
				                                                		);
				                                                	})
				                                                }
				                                            </div>
		                                            	</div>

			                                        </div>
			                                    </div>

			                                </div>
			                            </div>
		                			);
		                		})
		                	}
		                </div>
		            <div className="row">
		                <div className="col-4 mt-2">
		                    <button type="button" onClick={this.state.SCHEDULE.STATUS!='A'?this.confirmComplete:''} className="btn btn-outline-primary rounded btn-bsm btn-block text-upper">
		                        { (this.state.SCHEDULE.STATUS=='A')?'Completed':'Submit Workout' } 
		                    </button>
		                </div>
		            </div>
		            </div>
		        </div>

		        <div className="modal fade dialogbox" id="ModalAlert" data-backdrop="static" tabIndex="-1" role="dialog">
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                    	<div className="modal-icon text-success">
                    	    <ion-icon name="checkmark-circle"></ion-icon>
                    	</div>   
	                	
                      	<div className="modal-body">{this.state.Notifications}</div>
                      	<div className="modal-footer">
                        <div className="btn-inline"> 
                            <button type="button" className="btn btn-text-secondary" data-dismiss="modal">CANCEL</button> 
                            <button type="button" onClick={this._markComplete} className="btn btn-text-primary" data-dismiss="modal">PROCEED</button> 
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="modal fade dialogbox" id="ModalAlert2" data-backdrop="static" tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">

                            <div className="modal-body"> 
                                  <p>Way to crush today's workout</p>
                                  	<p>
                                  		<div className="text-success">
                                    		<ion-icon name="checkmark-circle"></ion-icon> Completed {this.state.WO_EXECOUNT} of {this.state.WO_EXECOUNT} exercices
                                		</div>
                                  	</p>
                            </div>
                            <div className="modal-footer">
                                <div className="btn-inline"> 
                                    <button type="button" className="btn" onClick={this._redirectWorkout} data-dismiss="modal">Ok</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    		    <AppBottomMenu activeClass={"warmup"} />
    		</div>
    	);
    }
}

export default WorkoutSummary;