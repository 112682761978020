import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import AppHeader from './AppHeader';
import Loader from './Loader';
import FreeTrialSlider from './FreeTrialSlider';
import $ from "jquery";

class FreeTrial extends Component {
    constructor(props) {
        super(props);
        this.state = {
            LOADING: true,
            Notifications: false,
            Logged: false,
            HeaderTitle: "THATLIFESTYLECOACH",
            INSTA:[],
        };
    }

    getInstaFeed() {
    	const requestOptions = {
    	    method: 'POST',
    	    headers: { 'Content-Type': 'application/json' },
    	    body: JSON.stringify({ mode: 'INSTAGRAM_FEED'})
    	};

    	fetch(global.config.bURL+"global_data.php", requestOptions)
    	    .then(function (response) {
    	        return response.json();
    	    })
    	    .then((result)=> {
    	        let responseJson = result;
    	        // console.log(responseJson.USER);
    	        this.setState({ 
    	        	INSTA: responseJson.INSTAGRAM_FEED, 
    	        	LOADING: false,
    	        });
    	    });

    }

    componentDidMount() {
        this.getInstaFeed();
        window.$("#bgVideo").YTPlayer();
        // window.$("#wrapper_bgVideo").css({'display': 'block', 'background-size': '50%'});
        window.$("#wrapper_bgVideo").css({'display': 'block'});
    }
    render() {
        let AppHeaderClass = 'appHeader no-border bg-coach-header text-light';
        let AppHeaderLeft = '';
        let AppHeaderLeftLink = '/';
        let AppHeaderTitle = 'THATLIFESTYLECOACH';
        let AppHeaderRight = '';
        let AppHeaderRightLink = '';
        
        return (
            <div class="extra-height">
                {this.state.LOADING && <Loader />}
                <AppHeader AppHeaderClass={AppHeaderClass} AppHeaderLeft={AppHeaderLeft} AppHeaderLeftLink={AppHeaderLeftLink} AppHeaderTitle={AppHeaderTitle} AppHeaderRight={AppHeaderRight} AppHeaderRightLink={AppHeaderRightLink} />

                <div id="appCapsule" className="pb-5">
                    <div className="login-form mt-4 coach-intro">
                        <div className="section mt-1 mb-4 back-overlay">
                            <div className="video-outer">
                                <div className="third">
                                   <div id="bgVideo" data-property="{videoURL: 'https://m.youtube.com/watch?v=wLaDlr8NNdo',showControls:false, autoPlay:true, loop:true, mute:true, opacity:3, quality:'default'}"></div>
                                </div>
                                <div className="intro-text">
                                    <h1 className="text-white font-28 mb-0">Get fit at home or anywhere</h1>
                                    <h4 className="text-white font-19">with</h4>
                                </div>
                            </div>
                            <div className="in-video-img mt-6">
                                    <img src="assets/img/Logo_TLC.png" alt="alt" className="imaged w-100" />
                            </div>
                        </div>
                        
                        {/*this.state.INSTA && this.state.INSTA.length > 0 &&
	                        <div className="intro-slider">
	                            <div className="section full m-3">
	                                <FreeTrialSlider instagram_feed={this.state.INSTA}/>
	                            </div>
	                        </div>*/
	                    }

                        {/*<div className="bg-coach-body2">
                            <div className="section full m-3">
                                <div className="tlc-carousel carousel-slider owl-carousel owl-theme">
                                    <div className="item p-0">
                                        <img src="assets/img/sample/photo/vector1.png" alt="alt" className="imaged w-100 mb-4" />
                                    </div>
                                    <div className="item p-0">
                                        <img src="assets/img/sample/photo/vector2.png" alt="alt" className="imaged w-100 mb-4" />
                                    </div>
                                    <div className="item p-0">
                                        <img src="assets/img/sample/photo/vector3.png" alt="alt" className="imaged w-100 mb-4" />
                                    </div>
                                </div>
                            </div>            
                        </div>*/}

                        <div className="section double mt-1 mb-0">
                            <div className="form-button-group">
                                    <Link to={'/SelectProgram'} className="btn btn-coach-pink rounded btn-block btn-mid text-upper">Take 7 days free trial</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default FreeTrial;