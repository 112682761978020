import React from 'react';
import ReactDOM from 'react-dom';
import { Link, Redirect } from 'react-router-dom';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const HomeSlider = (props) => {
	console.log(props);
    let Items = props.instagram_feed && props.instagram_feed.map((ig, i) => {
            return (
                    <div className="item p-0 from-insta" key={i}>
                    	<a href={ig.PERMALINK} target="_blank">
	                        <img src={ig.MEDIA_URL} className="imaged w-100 mb-4" />
	                        <div className="exercise-text">
	                            {/*<h3 className="text-white font-24 ft-wt-900">Flow Yoga</h3>
	                            <button type="button" className="btn btn-coach-pink rounded btn-mid  text-upper">Start</button>*/}
	                        </div>
	                    </a>
                    </div>
            );
        });

    const options = {
        items: 2,
        nav: false,
        loop: false,
        dots: false,
        stagePadding: 30
    };

    return (
        <div>
            <OwlCarousel className="owl-theme" loop margin={15} {...options}>
                {Items}
            </OwlCarousel>
        </div>
    );
}

export default HomeSlider;