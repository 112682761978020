import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import AppHeader from './AppHeader';
import Loader from './Loader';
import AppBottomMenu from './AppBottomMenu';
import WorkoutMenu from './WorkoutMenu';
import WorkoutSlider from './WorkoutSlider';
import ModalAlert from './ModalAlert';
import { formatDate, secondsToHms } from './Common';
import $ from 'jquery';


class WorkoutDetail extends Component {
    constructor(props) {
            super(props);
            this.state = {
            LOADING: true,
            Notifications: false,
            scheduleid: props.match.params.scheduleid,
            subscriptionid: props.match.params.subscriptionid,
            type: props.match.params.type,
            scheduleName: "",
            workoutSlider: "",
            workoutSets: "",
            schedule: '',
            customerid: localStorage.getItem('userData'),
            notifType: "",
            modalType: "",
            redirect: false,
            redirectURL: "",
            WARMUP_COMPLETE: "N",
            EXERCISE_COMPLETE: "N",
            COOLDOWN_COMPLETE: "N",
            prevWOComplete: "P",
        };

        localStorage.setItem('subscriptionid', this.state.subscriptionid);
        this.notCompleted = this.notCompleted.bind(this);
        this.startSchedule = this.startSchedule.bind(this);
        this.pressStart = this.pressStart.bind(this);
    }

    _playVideo = (title="", code="") => {
    	console.log('popup called...');
    	// console.log(title);
    	// console.log(code);
    	// this.setState({
    	// 	WO_TITLE:
    	// 	WO_VCODE:
    	// })
    	window.$('#vTITLE').html(title);
    	window.$('#vSRC').attr('src','https://www.youtube.com/embed/'+code+'?rel=0');
    	window.$('#playVideo').modal('show'); 
    }

    pressStart(e) {
    	e.preventDefault();

    	this.setState({
    	    Notifications: "Please Click Start to begin your schedule.",
    	    notifType : "error",
    	})

    	window.$('#ModalAlert').modal('show');
    }

    notCompleted(e) {
        e.preventDefault();

        this.setState({
            Notifications: "Please complete the previous exercise to proceed!",
            notifType : "error",
        })

        window.$('#ModalAlert').modal('show');
    }

    startSchedule (s_id, sc_id, lvl_id, week_id, day_id, type, s_date) {
        // console.log(' s_id:'+s_id+' sc_id:'+sc_id+' lvl_id:'+lvl_id+' week_id:'+week_id+' day_id:'+day_id+' s_date:'+s_date+' type:'+type);
        /*this.setState({
            Notifications: "You are about to start the exercise, continue?",
            modalType: 'CONFIRM',
        })
        window.$('#ModalAlert').modal('show');*/
        // fetching sets for workout
        let startSCHEDULE = false;
        let TODAY = formatDate(new Date());
        let schDATE = formatDate(s_date);

        if(this.state.prevWOComplete=='P') {
        	this.setState({
        	    Notifications: "Please complete the previous days workout.",
        	    notifType: "error",
        	})
        	window.$('#ModalAlert').modal('show');	
	    }

	   	if(this.state.prevWOComplete=='A') {

	   		if(schDATE <= TODAY ) {
	   		    startSCHEDULE = true;
	   		}
	   		else {
	   		    this.setState({
	   		        Notifications: "You cannot start a future workout.",
	   		        notifType: "error",
	   		    })
	   		    window.$('#ModalAlert').modal('show');
	   		}
	        // startSCHEDULE = true;
        }

        if(startSCHEDULE)
        {
            let url_str = "schedule_exercise_update.php?mode=SCHEDULE_EXERCISE_INSERT&customer="+this.state.customerid
                            +'&s_id='+s_id
                            +'&sc_id='+sc_id
                            +'&type='+type
                            +'&lvl_id='+lvl_id
                            +'&week_id='+week_id
                            +'&day_id='+day_id
                            +'&s_date='+s_date;

            fetch(global.config.bURL+url_str, {method:'POST', headers:{ 'Content-Type': 'application/json' }})
                .then(function (response) {
                    return response.json();
                })
                .then((result)=> {
                    // console.log(result);
                    let sc_id = result.SCHEDULE_ID;
                    let wo_id = result.SCHEDULE_EXERCISE_ID;

                    this.setState({
                        redirect: true,
                        redirectURL: '/WorkoutStart/W/'+sc_id+'/'+wo_id,
                    })

                    localStorage.setItem('timerBack', '/WorkoutStart/W/'+sc_id+'/'+wo_id);
                }); 
        }       
    }

    getExercises() {
        // fetching sets for workout
        let url_str = "schedule_exercise.php?mode=SCHEDULE_EXERCISE&customer="+this.state.customerid
                        +"&s_id="+this.props.match.params.subscriptionid
                        +"&sc_id="+this.props.match.params.scheduleid
                        +'&type='+this.props.match.params.type
                        +'&lvl_id='+this.props.match.params.lvl_id
                        +'&week_id='+this.props.match.params.week_id
                        +'&day_id='+this.props.match.params.day_id
                        +'&s_date='+this.props.match.params.s_date

        fetch(global.config.bURL+url_str, {method:'POST', headers:{ 'Content-Type': 'application/json' }})
            .then(function (response) {
                return response.json();
            })
            .then((result)=> {
                let responseJson = result;
                // console.log(responseJson.PREVIOUS_DATE);

                this.setState({ 
                    schedule: responseJson.SCHEDULE, 
                    workoutSets: responseJson.SCHEDULE_EXERCISE, 
                    LOADING: false, 
                    Logged: true,
                    WARMUP_COMPLETE:responseJson.WARMUP_COMPLETE,
                    EXERCISE_COMPLETE:responseJson.EXERCISE_COMPLETE,
                    COOLDOWN_COMPLETE:responseJson.COOLDOWN_COMPLETE,
                    prevWOComplete: responseJson.PREVIOUS_DATE,
                });
            });

    }

    componentWillMount() {
        let mount = $("div").hasClass("modal-backdrop");
        if (mount == true) {
            $('body').removeClass('modal-open');
            $(".modal-backdrop").remove();
            //window.location.reload(true);
        } 
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.match.params.type !== this.props.match.params.type) {
            console.log('component updated...');
            /*this.setState({
                LOADING: true
            })*/
            window.location.reload(); 
        }
    }

    componentDidMount() {
        this.getExercises();
    }

    render() {
        let AppHeaderClass = 'appHeader no-border';
        let AppHeaderLeft = 'showLINK';
        let AppHeaderLeftLink = '/Workout';
        let AppHeaderTitle = (this.state.schedule)?this.state.schedule.NAME:"";
        let AppHeaderRight = '';
        let AppHeaderRightLink = '';

        if(!this.state.customerid) {
          localStorage.setItem('userData',"");
          return (<Redirect to={'./'}/>);
        }

        if(this.state.redirect) {
            return (<Redirect to={this.state.redirectURL} />)
        }

        if(this.state.WARMUP_COMPLETE=='Y' && this.state.EXERCISE_COMPLETE=='Y' && this.state.COOLDOWN_COMPLETE=='Y')
        {
            return(<Redirect to={'/WorkoutSummary/'+this.state.schedule.ID} />)
        }

        return(
            <div>
                {this.state.LOADING && <Loader />}

                {this.state.schedule &&
                    <AppHeader AppHeaderClass={AppHeaderClass} AppHeaderLeft={AppHeaderLeft} AppHeaderLeftLink={AppHeaderLeftLink} AppHeaderTitle={AppHeaderTitle} AppHeaderRight={AppHeaderRight} AppHeaderRightLink={AppHeaderRightLink} />
                }

                <div id="appCapsule" className="pb-5">
                    {this.state.schedule &&
                        <WorkoutMenu 
                            activeClass=""
                            mode = {this.state.MODE}
                            subscriptionid={this.state.subscriptionid}
                            scheduleid={ this.state.schedule.ID }
                            date={this.state.schedule.DATE}
                            type={this.props.match.params.type}
                            lvl_id={this.props.match.params.lvl_id}
                            week_id={this.props.match.params.week_id}
                            day_id={this.props.match.params.day_id}
                            s_date={this.props.match.params.s_date}
                            length={this.state.workoutSets.length > 0}
                        />
                    }
                    <div className="section mr-2 ml-2">
                    <div className="text-left ln-20">
                        {this.state.schedule.length>0 && this.state.schedule.DESC && <p dangerouslySetInnerHTML={{ __html: this.state.schedule.DESC }} /> }
                    </div>
                    </div>


                    <div className="section workout-detail full mb-2 pb-0 pt-0">
                        {this.state.workoutSets.length > 0 &&
                            <WorkoutSlider 
                            	_playVideo={this._playVideo} 
                            	slider={this.state.workoutSets} 
                            	scheduleid={this.state.scheduleid} 
                            	notCompleted={this.notCompleted}
                            	pressStart={this.pressStart}
                            	type={this.props.match.params.type}
                            	WARMUP_COMPLETE={this.state.WARMUP_COMPLETE}
                            	COOLDOWN_COMPLETE={this.state.COOLDOWN_COMPLETE}
                            />
                        }
                    </div>

                    

                    <div className="section rounds pt-1 pb-4">
                    {this.props.match.params.scheduleid== 0 &&
                        <button 
                            onClick={(e) => this.startSchedule(
                                    this.state.subscriptionid,
                                    this.state.schedule.ID,
                                    this.props.match.params.lvl_id,
                                    this.props.match.params.week_id,
                                    this.props.match.params.day_id,
                                    this.props.match.params.type,
                                    this.props.match.params.s_date
                                )} 
                            className="btn btn-outline-secondary rounded btn-ssm mb-0 font-11 mb-1">
                            START
                        </button>
                    }
                    	{this.state.workoutSets.length > 0 &&
                        <ul className="listview image-listview media pt-1 pb-1 mb-2">
                            {/*<div className="chip chip-grey ml-2 mb-05">
                                <span className="chip-label text-upper">Round 1</span>
                            </div>*/}

                            {this.state.workoutSets.map((workout, i) => {
                                    let start_url = "#";
                                    let detail_str = "";
                                    let image_src = (workout.WORKOUT_IMAGE)?workout.WORKOUT_IMAGE:global.config.tlcImage;
                                    let prevItem = i-1;
                                    let prevCompleted = "Y";
                                    let prevCompleted2 = "";
                                    let fnCompleted = "";//(event) => event.preventDefault();

                                    if(prevItem >= 0) {
                                        prevCompleted = this.state.workoutSets[prevItem].COMPLETED;
                                    }

                                    if(i==0 && this.props.match.params.type=='E'){
                                        prevCompleted = this.state.WARMUP_COMPLETE;
                                    }
                                    else if(i==0 && this.props.match.params.type=='C') {
                                        prevCompleted = this.state.COOLDOWN_COMPLETE;       
                                    }

                                    detail_str += (workout.SETS!=0)?workout.SETS+' Sets | ':''
                                    detail_str += (workout.REPS!=0)?workout.REPS+' Reps | ':''
                                    detail_str += (workout.TIME!=0)?'Time '+secondsToHms(workout.TIME, workout.TIMETYPE)+' | ':''
                                    detail_str += (workout.REST!=0 && workout.REST!=undefined)?'Rest '+secondsToHms(workout.REST, workout.RESTTIMETYPE)+'  ':''

                                    if(this.state.scheduleid != 0) {
                                        start_url = '/WorkoutStart/'+this.state.type+'/'+this.state.scheduleid+'/'+workout.ID;
                                    }

                                    if(prevCompleted == 'N' || prevCompleted==undefined) {
                                        fnCompleted = (e) =>this.notCompleted(e);
                                        start_url = "#";
                                    }

                                    if(prevCompleted=='' || this.props.match.params.scheduleid== 0) {
                                    	fnCompleted = (e) =>this.pressStart(e);
                                        start_url = "#";	
                                    }

                                    // console.log(prevCompleted);

                                    return (
                                        <li key={'wo_'+i} >
                                            <Link to={start_url} className="item" onClick={fnCompleted}>
                                            	{image_src && 
	                                                <div className="imageWrapper">
	                                                    <img src={image_src} alt="image" className="imaged w64" />
	                                                </div>
	                                            }
                                                <div className="in">
                                                    <div className="text-gre5 ft-wt-600 font-15">
                                                        { (workout.WORKOUT_NAME)?workout.WORKOUT_NAME:workout.TYPE }
                                                        {/*<ion-icon name="checkmark-circle"></ion-icon>*/}
                                                        <div className="text-muted ft-wt-400">
                                                            { detail_str.slice(0, -2) }
                                                        </div>

                                                        <div className="workout-dat">
                                                        {workout.DAT.length > 0 &&
                                                            workout.DAT.map((w_dat, j)=> {

                                                                let detail_str2 = "";
                                                                detail_str2 += (w_dat.SETS!=0)?w_dat.SETS+' Sets | ':''
                                                                detail_str2 += (w_dat.REPS!=0)?w_dat.REPS+' Reps | ':''
                                                                detail_str2 += (w_dat.TIME!=0)?'Time '+secondsToHms(w_dat.TIME, w_dat.TIMETYPE)+' | ':''
                                                                detail_str2 += (w_dat.REST!=0 && w_dat.REST!=undefined)?'Rest '+secondsToHms(w_dat.REST,w_dat.RESTTIMETYPE)+'  ':''

                                                                return(
                                                                    <div className="dat-item" key={'dat_'+j}>
                                                                        { (w_dat.WORKOUT_NAME)?w_dat.WORKOUT_NAME:w_dat.TYPE }
                                                                        <div className="text-muted ft-wt-400">
                                                                            { detail_str2.slice(0, -2) }
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                        </div>

                                                    </div>
                                                </div>
                                            </Link>
                                        </li>
                                    );

                                })
                            }
                        </ul>
                    	}
                    </div>
                </div>


                <ModalAlert type={this.state.notifType} Notifications={this.state.Notifications} modalType={this.state.modalType}/>

                <div className="modal fade in-popup-video modalbox" id="playVideo" tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="m-0 ft-wt-400 text-gre6 font-15" id="vTITLE"></h5>
                                <a href="javascript:;" data-dismiss="modal"><ion-icon name="close-outline"></ion-icon></a>
                            </div>
                            <div className="modal-body">
                                <iframe id="vSRC" className="yt-video" src="" width="100%" frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen> </iframe>
                            </div>
                        </div>
                    </div>
                </div>


                <AppBottomMenu activeClass={"workout"} />
            </div>
        );
    }
}

export default WorkoutDetail;