import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import AppHeader from './AppHeader';
import Loader from './Loader';
import $ from "jquery";
import {validatedate, validdate} from "./Common";
// import 'bootstrap';

class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
            LOADING: true,
            Notifications: false,
            Logged: false,
            planType: props.match.params.type,
            planID: props.match.params.pid,
            basicPackages: [],
            premiumPackages:[],
            packages:[],
            planChecked: '',
            tSHIRT: [],
            GENDER: [],
            PayNow: "N",
        };

        this.onRadioChange = this.onRadioChange.bind(this);
    }
    handleSubmit = (e) => {
        e.preventDefault();

        let err = 0;
        if ($('#frmRegister')[0].checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            err++;
        }
        $('#frmRegister')[0].classList.add('was-validated');

        const password = $('#txtpassword').val().trim();
        const password2 = $('#txtpassword2').val().trim();
        let subscr = $('input[name="rdsubscription"]:checked');
        let dateofbirth = $('#txtdate').val()+'-'+$('#txtmonth').val()+'-'+$('#txtyear').val();
        let startdate = $('#txtdate').val()+'-'+$('#txtmonth').val()+'-'+$('#txtyear').val();

        if(!validatedate(dateofbirth)) {
            err++;
            //alert('Please enter a valid date of birth');
            $("#dob-invalid").html("Enter a valid date of birth");
        }
        else if(!validdate( $('#txtdate').val(),  $('#txtmonth').val(),  $('#txtyear').val(), "P")) {
            err++;
            //alert('Date of birth cannot be in the future');
            $("#dob-invalid").html("Date of birth cannot be in the future");
        }
        else {
        	$("#dob-invalid").html("");	
        }

        if(!validatedate(startdate)) {
            err++;
            //alert('Please enter a valid start date for program');
            $("#prg-invalid").html("Enter a valid start date for program");
        }
        else if(!validdate( $('#txtprgdate').val(),  $('#txtprgmonth').val(),  $('#txtprgyear').val(), "F")) {
            err++;
            //alert('Program start date cannot be in the past');
            $("#prg-invalid").html("Program start date cannot be in the past");
        }
        else {
        	$("#prg-invalid").html("");	
        }

        if (password != password2) {
            err++;
            $('#txtpassword2').val('');
        }

        if (this.state.planType && subscr.length==0) {
            err++;
            //alert('Please select a subscription plan');
            $("#subsc-invalid").html("Select a subscription plan");
        }
        else {
        	$("#subsc-invalid").html("");
        }

        if (err == 0) {
            $('#submitLoading').css('display', 'block');

            const method = "POST";
            const body = new FormData(this.form);
            var that = this;

            fetch(global.config.bURL+"customer_register.php", { method, body })
                .then(function (response) {
                    //console.log(response);
                    return response.json();
                })
                .then(function (result) {

                    //console.log(result);
                    //alert(result);
                    let notify = '';
                    if (result.err != '') {
                        if (result.err == '1') notify = 'Name Field is Empty';
                        else if (result.err == '2') notify = 'Mobile No Already Exists';
                        else if (result.err == '3') notify = 'Email Id Field is Empty';
                        else if (result.err == '4') notify = 'Invalid Access Detected';
                    }

                    let registered = false;

                    let customer = result.customer;
                    if (customer != '' && customer != undefined) {
                        localStorage.setItem('userData', customer);
                        localStorage.setItem('subscriptionID', result.SUBSCRIPTION_ID);
                        localStorage.setItem('Verified', 'N');
                        registered = true;
                    }

                    that.setState({ Notifications: notify, Registered: registered, PayNow: result.FREE_TRIAL });
                     $('#submitLoading').css('display', 'none');
                    if (notify != '')
                        window.$('#ModalAlert').modal('show'); 
                });
        }
    }

    handleClickGetTerms = () => {
        // alert('test');
        window.$('#ModalTerms').modal('show');
    }

    handleClickGetSizeChart = () => {
    	window.$('#ModalSizeChart').modal('show');	
    }

    handlePlanClick = (planid) => {
        // alert(planid);
        //localStorage.setItem('planChecked', planid);
        /*this.setState({
            planChecked: planid,
        })*/
        // defaultChecked={payMode=='2'?true:false}
    }

    applyCouponcode = () => {
        let inp_code = $('#txtcouponcode').val();
        let inp_subscr = $('input[name="rdsubscription"]:checked');
        let curr_type = localStorage.getItem('CURRENCY_TYPE')?localStorage.getItem('CURRENCY_TYPE'):'INR';
        // console.log('coupon', coupon_code);
        let coupon_code = (inp_code!='')?inp_code:0;
        let subscr = (inp_subscr.val()!='')?inp_subscr.val():0;

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ mode: 'APPLY_COUPON', clientid: 0, code:coupon_code, tariffid: subscr, currType:curr_type})
        };

        fetch(global.config.bURL+"global_data.php", requestOptions)
            .then(function (response) {
                return response.json();
            })
            .then((result)=> {
                console.log(result);
                if(result.err) {
                    $('#err_coupon').html(result.err);
                }
                else {
                    // localstorageresult.COUPON_CODE                 
                    let msg_str = "You have redeemed coupon worth "+result.COUPON_AMOUNT;
                    $('#err_coupon').html(msg_str);   
                }
            });
    }

    getPackages() {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ mode: 'PACKAGES', p_id: this.state.planID, type:this.state.planType})
        };

        fetch(global.config.bURL+"packages.php?mode=PACKAGES&p_id="+this.state.planID+'&type='+this.state.planType, requestOptions)
            .then(function (response) {
                return response.json();
            })
            .then((result)=> {
                let responseJson = result;
                // console.log(responseJson);
                let notify = "";
                if (result.err != '') {
                    if (result.err == '1') notify = 'Invalid Parameter';
                    else if (result.err == '2') notify = 'Invalid Package selected';
                    else if (result.err == '3') notify = 'No Subscription Package found for this plan';
                }

                if(this.state.planType=='B') {
                    this.setState({
                        packages: responseJson.BASIC,
                        LOADING: false,
                        Notifications: notify
                    });
                }
                else {
                    this.setState({
                        packages: responseJson.PREMIUM,
                        LOADING: false,
                        Notifications: notify
                    });   
                }

                if (notify != '')
                    window.$('#ModalAlert').modal('show'); 
            });

         const requestOptions2 = {
             method: 'POST',
             headers: { 'Content-Type': 'application/json' },
             body: JSON.stringify({ mode: 'GLOBALS'})
         };
        fetch(global.config.bURL+"global_data.php", requestOptions2)
            .then(function (response) {
                return response.json();
            })
            .then((result)=> {
            	// console.log(result);
            	this.setState({
            		tSHIRT: result.SIZES,
            		GENDER: result.GENDER,
            	})
            });
    }

    componentWillMount() {
         let mount = $("div").hasClass("modal-backdrop");
        if (mount == true) {
            $('body').removeClass('modal-open');
            $(".modal-backdrop").remove();
            //window.location.reload(true);
        } 
    }

    componentDidMount() {
        this.getPackages();
        this.setState({ LOADING: false });
    }

    onRadioChange = e => {
        const { name, value } = e.target;

        this.setState({
          [name]: value
        });
    };

    render() {

        let appTitle = "";

        if(this.state.planType=='B') appTitle = "Basic Plan Subscription";
        else if(this.state.planType=='P') appTitle = "Premium Plan Subscription";
        else if(!this.state.planType) appTitle = "7 Day Free Trial";

        let AppHeaderClass = 'appHeader no-border bg-common-header text-light';
        let AppHeaderLeft = 'showLINK';
        let AppHeaderLeftLink = '/Plans';
        let AppHeaderTitle = appTitle;
        let AppHeaderRight = '';
        let AppHeaderRightLink = '';

        if(!this.state.planID) {
            return (<Redirect to={'/'}/>);
        }

        if(this.state.Registered) {

        	if(this.state.PayNow == "N") {
            	return (<Redirect to={'/OnlinePay'} />)
            }
            else {
            	return (<Redirect to={'/Home'} />)
            }
        }

        let dietTARIFFAVL = "N";
        let currency_type = localStorage.getItem('CURRENCY_TYPE')?localStorage.getItem('CURRENCY_TYPE'):'INR';

        return (
            <div>
                {this.state.LOADING &&
                    <Loader />
                }
                <AppHeader AppHeaderClass={AppHeaderClass} AppHeaderLeft={AppHeaderLeft} AppHeaderLeftLink={AppHeaderLeftLink} AppHeaderTitle={AppHeaderTitle} AppHeaderRight={AppHeaderRight} AppHeaderRightLink={AppHeaderRightLink} />
                <div id="appCapsule">
                <div className="login-form">
                    <form id="frmRegister" ref={el =>
                        (this.form = el)} onSubmit={this.handleSubmit} className="needs-validation" noValidate>
                        <input type="hidden" value="REGISTER" className="form-control" name="mode" id="mode" />
                        <input type="hidden" value={localStorage.getItem('storedSelection')} className="form-control" name="params" id="params" />
                        <input type="hidden" value={localStorage.getItem('WEBPUSHR_ID')} className="form-control" name="push_id" id="push_id" />
                        <input type="hidden" value={this.state.planID} className="form-control" name="program_id" id="program_id" />
                        <input type="hidden" value={this.state.planID} className="form-control" name="program_id" id="program_id" />
                        <input type="hidden" value={this.state.planType} className="form-control" name="sub_type" id="sub_type" />

                        {this.state.planType!=undefined && this.state.planType!='' &&
                            <div className="section payment-opts full mt-0 pt-2 mb-0 pl-3 pr-3 bg-coach-org-pink">
                                <div className="section-title btn-titles pl-0 pr-0 mb-05 text-white ft-wt-300">Choose your Workout Plan</div>
                                <div className="wide-block bg-transparent pt-0 pb-2 pl-0 pr-0 no-border plans-opt">
                                    {this.state.packages &&
                                        this.state.packages.map((subs_package, i)=>{
                                            let saved_amount = "";
                                            let original_amount = "";
                                            let price_amount = "";
                                            let duration_str = "";
                                            let currency_symb = "";
                                            let checkedPackage = false;
                                            // console.log(currency_type);

                                            if(subs_package.PACKAGE_DEFAULT=='Y')
                                            	checkedPackage = true;
                                            else 
                                            	checkedPackage = false;

                                            if(currency_type=='INR') {
                                            	saved_amount = parseInt(subs_package.PACKAGE_ORIGINALPRICE)-parseInt(subs_package.PACKAGE_PRICE);
                                            	original_amount = subs_package.PACKAGE_ORIGINALPRICE;
                                            	price_amount = subs_package.PACKAGE_PRICE;
                                            	currency_symb = '&#x20B9;';
                                            }
                                            else {
                                            	saved_amount = parseInt(subs_package.PACKAGE_ORIGINALPRICE_USD)-parseInt(subs_package.PACKAGE_PRICE_USD);
                                            	original_amount = subs_package.PACKAGE_ORIGINALPRICE_USD;
                                            	price_amount = subs_package.PACKAGE_PRICE_USD;
                                            	currency_symb = '&#36;';
                                            }
                                            
                                            if(subs_package.PACKAGE_DURATIONTYPE=='M') {
                                                duration_str = subs_package.PACKAGE_DURATION+ (subs_package.PACKAGE_DURATION > 1)?' Months':' Month';
                                            }
                                            else if(subs_package.PACKAGE_DURATIONTYPE=='W') {
                                                duration_str = subs_package.PACKAGE_DURATION+ (subs_package.PACKAGE_DURATION > 1)?' Weeks':' Week';
                                            }
                                            else if(subs_package.PACKAGE_DURATIONTYPE=='Y') {
                                                duration_str = subs_package.PACKAGE_DURATION+ (subs_package.PACKAGE_DURATION > 1)?' Years':' Year';
                                            }

                                            if(subs_package.PACKAGE_TYPE=='D') dietTARIFFAVL = 'Y';

                                            if(subs_package.PACKAGE_TYPE=='P')
                                            {
	                                            return(
	                                                <div className="each-price custom-control custom-radio mb-2" key={i} >
	                                                    <input type="radio" name="rdsubscription" className="custom-control-input" defaultValue={subs_package.TARIFF_ID} defaultChecked={checkedPackage} required="required" />

	                                                    <label className="custom-control-label" >
	                                                        <span>
	                                                            {original_amount >0 && original_amount!=price_amount &&
	                                                                <p className="cancel"><span className="cancel-price" dangerouslySetInnerHTML={{ __html:currency_symb}} />  {original_amount}</p>
	                                                            }
	                                                            
	                                                            {saved_amount >0 &&
	                                                                <p>Save <span className="cancel-price" dangerouslySetInnerHTML={{ __html:currency_symb}} /> {saved_amount}</p>
	                                                            }
	                                                        </span>
	                                                        {price_amount > 0 
	                                                        	?<span><h5><span className="ft-wt-300" dangerouslySetInnerHTML={{ __html:currency_symb}} /> {price_amount}</h5></span>
	                                                        	:<span><h5><span className="ft-wt-300" />Free</h5></span>

	                                                        }
	                                                        <span><p>{duration_str}</p></span>                            
	                                                    </label>
	                                                </div>
	                                            );
	                                        }
                                        })
                                    }

                                    <div className="valid-feedback">&nbsp;</div>
                                    <div id="subsc-invalid" className="err-required"></div>
                                </div>
                            </div>
                        }

                            {dietTARIFFAVL == 'Y' &&
	                            <div className="section payment-opts full mt-0 pt-2 mb-2 pl-3 pr-3 bg-coach-greenish">
	                                <div className="section-title btn-titles pl-0 pr-0 mb-05 text-white ft-wt-300">Upgrade Diet Plans (Optional)</div>
	                                <div className="wide-block bg-transparent pt-0 pb-2 pl-0 pr-0 no-border plans-opt">
	                                    {this.state.packages &&
                                        	this.state.packages.map((subs_package, i)=>{ 
		                                     	let saved_amount = "";
	                                            let original_amount = "";
	                                            let price_amount = "";
	                                            let duration_str = "";
	                                            let currency_symb = "";
	                                            // console.log(currency_type);

	                                            if(currency_type=='INR') {
	                                            	saved_amount = parseInt(subs_package.PACKAGE_ORIGINALPRICE)-parseInt(subs_package.PACKAGE_PRICE);
	                                            	original_amount = subs_package.PACKAGE_ORIGINALPRICE;
	                                            	price_amount = subs_package.PACKAGE_PRICE;
	                                            	currency_symb = '&#x20B9;';
	                                            }
	                                            else {
	                                            	saved_amount = parseInt(subs_package.PACKAGE_ORIGINALPRICE_USD)-parseInt(subs_package.PACKAGE_PRICE_USD);
	                                            	original_amount = subs_package.PACKAGE_ORIGINALPRICE_USD;
	                                            	price_amount = subs_package.PACKAGE_PRICE_USD;
	                                            	currency_symb = '&#36;';
	                                            }
	                                            
	                                            if(subs_package.PACKAGE_DURATIONTYPE=='M') {
	                                                duration_str = subs_package.PACKAGE_DURATION+' Month';
	                                            }
	                                            else if(subs_package.PACKAGE_DURATIONTYPE=='W') {
	                                                duration_str = subs_package.PACKAGE_DURATION+' Week';
	                                            }
	                                            else if(subs_package.PACKAGE_DURATIONTYPE=='Y') {
	                                                duration_str = subs_package.PACKAGE_DURATION+' Year';
	                                            }

	                                            if(subs_package.PACKAGE_TYPE=='D')
	                                            {
		                                            return(
		                                                <div className="each-price custom-control custom-radio mb-2" key={i} >
	                                                    <p className="mb-0 ft-wt-500 font-14 text-left text-white">{subs_package.PACKAGE_NAME}</p>

		                                                    <input type="radio" name="rddiet" className="custom-control-input" defaultValue={subs_package.TARIFF_ID} />

		                                                    <label className="custom-control-label" >

		                                                        <span>
		                                                            {original_amount >0 && original_amount!=price_amount &&
		                                                                <p className="cancel"><span className="cancel-price" dangerouslySetInnerHTML={{ __html:currency_symb}}/>  {original_amount}</p>
		                                                            }
		                                                            
		                                                            {saved_amount >0 &&
		                                                                <p>Save <span className="cancel-price" dangerouslySetInnerHTML={{ __html:currency_symb}} /> {saved_amount}</p>
		                                                            }
		                                                        </span>
		                                                        <span><h5><span className="ft-wt-300" dangerouslySetInnerHTML={{ __html:currency_symb}} /> {price_amount}</h5></span>
		                                                        <span><p>{duration_str}</p></span>                            
		                                                    </label>
		                                                </div>
		                                            );
		                                        }
	                                        })
                                    }

	                                    <div className="valid-feedback">&nbsp;</div>
	                                    <div id="subsc-invalid" className="err-required"></div>
	                                </div>
	                            </div>
                            }

                        <div className="section pr-3 pl-3 pb-1 pt-0 basic-form text-left">
                            {/* <input type="hidden" value={localStorage.getItem('SUBSCRIBER_ID')} className="form-control" name="webpushr_id" id="webpushr_id" /> */}
                            <div className="form-group basic">
                                <div className="input-wrapper guest-detail float-label">
                                    <input type="text" className="input-box inputText form-control" name="txtusername" id="txtusername" required autoComplete="off" />
                                    <label className="floating-label">Name</label>
                                    <i className="clear-input">
                                        <ion-icon name="close-circle" role="img" className="md hydrated" aria-label="close circle"></ion-icon>
                                    </i>
                                    <div className="valid-feedback">&nbsp;</div>
                                    <div className="invalid-feedback">Enter your full name.</div>
                                </div>
                                <div className="input-info">Enter your full name</div>
                            </div>
                            <div className="form-group basic mb-2">
                                <div className="input-wrapper guest-detail float-label">
                                    <input type="email" className="input-box inputText form-control" name="txtuseremail" id="txtuseremail" required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" autoComplete="off" />
                                    <label className="floating-label">Email</label>
                                    <i className="clear-input">
                                        <ion-icon name="close-circle" role="img" className="md hydrated" aria-label="close circle"></ion-icon>
                                    </i>
                                    <div className="valid-feedback">&nbsp;</div>
                                    <div className="invalid-feedback">Enter your email id.</div>
                                </div>
                                <div className="input-info">Enter your email</div>
                            </div>

                            <div className="form-group basic">
                                     <div className="input-wrapper new-input-gp0">
                                        <label className="label" htmlFor="number">Code</label>
                                        <input type="text" className="form-control" placeholder="" name="txtmobilecode" id="txtmobilecode" required defaultValue="+91" maxLength="3"/>
                                        <span className="focus-border"></span>
                                        <i className="clear-input">
                                            <ion-icon name="close-circle" role="img" className="md hydrated" aria-label="close circle"></ion-icon>
                                        </i>
                                        <div className="valid-feedback">&nbsp;</div>
                                        <div className="invalid-feedback">Enter country code.</div>
                                        <div className="mt-02 text-gre4 font-12">Country</div>
                                    </div>
                                    <div className="input-wrapper new-input-gp">
                                        <label className="label" htmlFor="txtusermobile">Mobile</label>
                                        <input type="text" className="form-control" placeholder="" name="txtusermobile" id="txtusermobile" required minLength="10" maxLength="10" pattern="[0-9]{10}" autoComplete="off"/>
                                        <span className="focus-border"></span>
                                        <i className="clear-input">
                                            <ion-icon name="close-circle" role="img" className="md hydrated" aria-label="close circle"></ion-icon>
                                        </i>

                                        <div className="valid-feedback">&nbsp;</div>
                                        <div className="invalid-feedback">Enter your mobile number.</div>
                                    </div>
                                    <div className="clear"></div>
                            </div>
                            {/*<div className="form-group basic">
                                <div className="input-wrapper guest-detail float-label">
                                    <input type="text" className="input-box inputText form-control" name="txtusermobile" id="txtusermobile" required minLength="10" maxLength="10" pattern="[0-9]{10}" autoComplete="off" />
                                    <label className="floating-label">Mobile</label>
                                    <i className="clear-input">
                                        <ion-icon name="close-circle" role="img" className="md hydrated" aria-label="close circle"></ion-icon>
                                    </i>
                                    <div className="valid-feedback">&nbsp;</div>
                                    <div className="invalid-feedback">Please enter your mobile number.</div>
                                </div>
                                <div className="input-info">Enter your mobile number</div>
                            </div>*/}
                            <div className="form-group basic">
                                <div className="input-wrapper guest-detail float-label">
                                    <input type="password" className="form-control input-box inputText " name="txtpassword" id="txtpassword" minLength="8" required autoComplete="off" />{/* pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" */}
                                    <label className="floating-label">Password</label>
                                    <i className="clear-input">
                                        <ion-icon name="close-circle"></ion-icon>
                                    </i>
                                    <div className="valid-feedback">&nbsp;</div>
                                    <div className="invalid-feedback">Must contain at least 8 or more characters.</div>
                                </div>
                            </div>
                            <div className="form-group basic mb-2">
                                <div className="input-wrapper guest-detail float-label">
                                    <input type="password" className="form-control input-box inputText " name="txtpassword2" id="txtpassword2" minLength="8" required autoComplete="off" /> {/*pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"  */}
                                    <label className="floating-label">Password (Again)</label>
                                    <i className="clear-input">
                                        <ion-icon name="close-circle"></ion-icon>
                                    </i>
                                    <div className="valid-feedback">&nbsp;</div>
                                    <div className="invalid-feedback">Must contain at least 8 or more characters.</div>
                                </div>
                            </div>
                            
                                {/* 
                                    <div className="form-group basic">
                                        <div className="input-wrapper">
                                            <label className="label" htmlFor="number">Mobile No.</label>
                                            <select className="new-select-gp form-control custom-select" id="number4">
                                                <option value="0">+91</option>
                                                <option value="1">+92</option>
                                                <option value="2">+93</option>
                                                <option value="3">+94</option>
                                            </select>
                                            <input type="number" className="new-input-gp form-control" id="number5" placeholder="" />
                                            <span className="focus-border"></span>
                                            <i className="clear-input">
                                                <ion-icon name="close-circle" role="img" className="md hydrated" aria-label="close circle"></ion-icon>
                                            </i>
                                            <div className="clear"></div>
                                        </div>
                                        <div className="input-info">Enter correct number</div>
                                    </div>
	            	    		*/}
                            {/*<div className="form-group basic">
                                <div className="input-wrapper guest-detail float-label">
                                    <input type="text" className="input-box inputText form-control" required="" name="txtdob" id="txtdob" />
                                    <label className="floating-label">Date of Birth</label>
                                    <i className="clear-input">
                                        <ion-icon name="close-circle" role="img" className="md hydrated" aria-label="close circle"></ion-icon>
                                    </i>
                                </div>
                                <div className="input-info">Enter Date of birth</div>
                            </div>*/}

                            <div className="form-group basic mb-2">
                            	<div className="gender-select">
                            		<label className="label" htmlFor="gender">Gender</label>
                                    <div className="gender-inline">
                                    		{this.state.GENDER && this.state.GENDER.length > 0 &&
                                    		  this.state.GENDER.map((gender, i)=>{
                                    		      	return (
                                    		      	        <div className="custom-control custom-radio mb-1 mr-2" key={i}>
                                    		      	            <input type="radio" id={'rdgender'+gender.ID} name="rdgender" className="custom-control-input" defaultValue={gender.ID} />
                                    		      	            <label className="custom-control-label" htmlFor={'rdgender'+gender.ID}>{gender.TEXT}</label>
                                       							<span className="focus-border"></span>
                                    		      	        </div>
                                    		        );
                                    		    })
                                    		}
                                    </div>
                            	</div>
                            </div>


                            {/*<div className="form-group basic">
                                     <div className="input-wrapper new-input-gp0">
                                        <label className="label" htmlFor="number">City</label>
                                        <input type="text" className="form-control" placeholder="" name="txtmobilecode" id="txtmobilecode" required defaultValue="+91" maxLength="3"/>
                                        <span className="focus-border"></span>
                                        <i className="clear-input">
                                            <ion-icon name="close-circle" role="img" className="md hydrated" aria-label="close circle"></ion-icon>
                                        </i>
                                        <div className="valid-feedback">&nbsp;</div>
                                        <div className="invalid-feedback">Enter country code.</div>
                                        <div className="mt-02 text-gre4 font-12">Country</div>
                                    </div>
                                    <div className="input-wrapper new-input-gp">
                                        <label className="label" htmlFor="txtusermobile">Mobile</label>
                                        <input type="text" className="form-control" placeholder="" name="txtusermobile" id="txtusermobile" required minLength="10" maxLength="10" pattern="[0-9]{10}" autoComplete="off"/>
                                        <span className="focus-border"></span>
                                        <i className="clear-input">
                                            <ion-icon name="close-circle" role="img" className="md hydrated" aria-label="close circle"></ion-icon>
                                        </i>

                                        <div className="valid-feedback">&nbsp;</div>
                                        <div className="invalid-feedback">Enter your mobile number.</div>
                                    </div>
                                    <div className="clear"></div>
                            </div>*/}


                            <div className="form-group basic">
								<div className="divide-50">
								    <div className="input-wrapper guest-detail float-label">
								        <input type="text" className="form-control input-box inputText " name="txtaddress" id="txtaddress" required autoComplete="off" />
								        <label className="floating-label">Address</label>
								        <i className="clear-input">
								            <ion-icon name="close-circle" role="img" className="md hydrated" aria-label="close circle"></ion-icon>
								        </i>
								        <div className="valid-feedback">&nbsp;</div>
								        <div className="invalid-feedback">Enter your full address.</div>
								    </div>
								    <div className="input-info">Enter your full addreess</div>
								</div>
							</div>

							<div className="form-group basic">
								<div className="divide-50l">
								    <div className="input-wrapper guest-detail float-label">
								        <input type="text" className="form-control input-box inputText " name="txtstate" id="txtstate" required autoComplete="off" />
								        <label className="floating-label">State</label>
								        <i className="clear-input">
								            <ion-icon name="close-circle" role="img" className="md hydrated" aria-label="close circle"></ion-icon>
								        </i>
								        <div className="valid-feedback">&nbsp;</div>
								        <div className="invalid-feedback">Enter your state.</div>
								    </div>
								    <div className="input-info">Enter your state</div>
								</div>

							    <div className="divide-50r">
							        <div className="input-wrapper guest-detail float-label">
							            <input type="text" className="form-control input-box inputText " name="txtcountry" id="txtcountry" required autoComplete="off" />
							            <label className="floating-label">Country</label>
							            <i className="clear-input">
							                <ion-icon name="close-circle" role="img" className="md hydrated" aria-label="close circle"></ion-icon>
							            </i>
							            <div className="valid-feedback">&nbsp;</div>
							            <div className="invalid-feedback">Enter your country.</div>
							        </div>
							        <div className="input-info">Enter your country</div>
							    </div>
							    <div className="clear"></div>
							</div>

							<div className="form-group basic">
								<div className="divide-50l">
								    <div className="input-wrapper guest-detail float-label">
								        <input type="text" className="form-control input-box inputText " name="txtcity" id="txtcity" required autoComplete="off" />
								        <label className="floating-label">City</label>
								        <i className="clear-input">
								            <ion-icon name="close-circle" role="img" className="md hydrated" aria-label="close circle"></ion-icon>
								        </i>
								        <div className="valid-feedback">&nbsp;</div>
								        <div className="invalid-feedback">Enter your city.</div>
								    </div>
								    <div className="input-info">Enter your city</div>
								</div>

							    <div className="divide-50r">
							        <div className="input-wrapper guest-detail float-label">
							            <input type="text" className="form-control input-box inputText " name="txtpincode" id="txtpincode" required autoComplete="off" /> {/*pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"  */}
							            <label className="floating-label">Pincode</label>
							            <i className="clear-input">
							                <ion-icon name="close-circle" role="img" className="md hydrated" aria-label="close circle"></ion-icon>
							            </i>
							            <div className="valid-feedback">&nbsp;</div>
							            <div className="invalid-feedback">Enter your pincode.</div>
							        </div>
							        <div className="input-info">Enter your pincode</div>
							    </div>
							    <div className="clear"></div>
							</div>


                            <div className="form-group basic">
                                <div className="mb-2">
                                    <label className="label" htmlFor="number">Date of Birth</label>
                                    <div className="input-wrapper new-input-gp3">
                                       <input type="number" className="form-control" placeholder="DD"  name="txtdate" id="txtdate" autoComplete="off" required />
                                       <span className="focus-border"></span>
                                        <i className="clear-input">
                                            <ion-icon name="close-circle" role="img" className="md hydrated" aria-label="close circle"></ion-icon>
                                        </i>
                                        <div className="valid-feedback">&nbsp;</div>
                                        {/*<div className="invalid-feedback">Please enter a date.</div>*/}
                                        <div className="mt-02 text-gre4 font-12">Date</div>
                                    </div>
                                    <div className="input-wrapper new-input-gp3">
                                       <input type="number" className="form-control" placeholder="MM" name="txtmonth" id="txtmonth" autoComplete="off" required />
                                       <span className="focus-border"></span>
                                        <i className="clear-input">
                                            <ion-icon name="close-circle" role="img" className="md hydrated" aria-label="close circle"></ion-icon>
                                        </i>
                                        <div className="valid-feedback">&nbsp;</div>
                                        {/*<div className="invalid-feedback">Please enter a month.</div>*/}
                                         <div className="mt-02 text-gre4 font-12">Month</div>
                                    </div>
                                    <div className="input-wrapper new-input-gp3">
                                       <input type="number" className="form-control" placeholder="YYYY" name="txtyear" id="txtyear"  autoComplete="off" required/> 
                                       <span className="focus-border"></span>
                                        <i className="clear-input">
                                            <ion-icon name="close-circle" role="img" className="md hydrated" aria-label="close circle"></ion-icon>
                                        </i>
                                        <div className="valid-feedback">&nbsp;</div>
                                        {/*<div className="invalid-feedback">Please enter a year.</div>*/}
                                        <div className="mt-02 text-gre4 font-12">Year</div>
                                    </div>

                                    <div className="clear"></div>

                                    <div className="valid-feedback">&nbsp;</div>
                                    <div className="err-required2" id="dob-invalid"></div>
                                </div>
                            </div>

                            <div className="form-group basic">
                                <div className="">
                                    <label className="label" htmlFor="number">Program Start Date</label>
                                    <div className="input-wrapper new-input-gp3">
                                       <input type="number" className="form-control"  placeholder="DD" name="txtprgdate" id="txtprgdate" autoComplete="off" required />
                                       <span className="focus-border"></span>
                                        <i className="clear-input">
                                            <ion-icon name="close-circle" role="img" className="md hydrated" aria-label="close circle"></ion-icon>
                                        </i>
                                        <div className="valid-feedback">&nbsp;</div>
                                        {/*<div className="invalid-feedback">Please enter a date.</div>*/}
                                        <div className="mt-02 text-gre4 font-12">Date</div>

                                    </div>
                                    <div className="input-wrapper new-input-gp3">
                                       <input type="number" className="form-control"  placeholder="MM" name="txtprgmonth" id="txtprgmonth" autoComplete="off" required />
                                       <span className="focus-border"></span>
                                        <i className="clear-input">
                                            <ion-icon name="close-circle" role="img" className="md hydrated" aria-label="close circle"></ion-icon>
                                        </i>
                                        <div className="valid-feedback">&nbsp;</div>
                                       {/* <div className="invalid-feedback">Please enter a month.</div>*/}
                                         <div className="mt-02 text-gre4 font-12">Month</div>

                                    </div>
                                    <div className="input-wrapper new-input-gp3">
                                       <input type="number" className="form-control"  placeholder="YYYY" name="txtprgyear" id="txtprgyear" autoComplete="off" required /> 
                                       <span className="focus-border"></span>
                                        <i className="clear-input">
                                            <ion-icon name="close-circle" role="img" className="md hydrated" aria-label="close circle"></ion-icon>
                                        </i>
                                        <div className="valid-feedback">&nbsp;</div>
                                        {/*<div className="invalid-feedback">Please enter a year.</div>*/}
                                        <div className="mt-02 text-gre4 font-12">Year</div>

                                    </div>

                                    <div className="clear"></div>

                                    <div className="valid-feedback">&nbsp;</div>
                                    <div id="prg-invalid" className="err-required2"></div>
                                </div>
                            </div>
                            <div className="form-group basic">
                                  <div className="divide-50l">
			                            <div className="section inset mt-0 pr-0 pl-0 pb-0 pt-2">
			                                <div className="acc-options accordion no-lines" id="accordionExample2">
			                                    <div className="item">
			                                        <div className="accordion-header">
			                                            <button className="btn collapsed p-0" type="button" data-toggle="collapse" data-target="#accordion01">T-Shirt Size
			                                            </button>
			                                            <Link title="Size Chart" className="text-gre4 font-12 tsize-chart" onClick={() => { this.handleClickGetSizeChart() }}>
			                                            	<ion-icon name="help-circle-outline"></ion-icon>
			                                            </Link>
			                                        </div>
			                                        <div id="accordion01" className="accordion-body collapse" data-parent="#accordionExample2">
			                                            <div className="accordion-content text-left p-0 shirt-size">
			                                                <div className="wide-block no-border">
			                                                {this.state.tSHIRT && this.state.tSHIRT.length > 0 &&
			                                                  this.state.tSHIRT.map((shirt, i)=>{
			                                                      return (
			                                                          <div className="custom-control custom-radio mb-1" key={i}>
			                                                              <input type="radio" id={'cmbtshirtsize'+shirt.ID} name="cmbtshirtsize" className="custom-control-input" defaultValue={shirt.ID} />
			                                                              <label className="custom-control-label" htmlFor={'cmbtshirtsize'+shirt.ID}>{shirt.TEXT}</label>
			                                                          </div>
			                                                      );
			                                                  })
			                                                }
			                                              </div>
			                                            </div>
			                                        </div>
			                                        <span style={{display: "none"}} id="err_goal" className="err-required"></span>
			                                    </div>
			                                </div>
			                            </div>
			                        </div>

		                            <div className="divide-50r">
		                                <div className="input-wrapper guest-detail float-label">
		                                    <input type="text" className="form-control input-box inputText " name="txtprofession" id="txtprofession" required autoComplete="off" /> {/*pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"  */}
		                                    <label className="floating-label">Profession</label>
		                                    <i className="clear-input">
		                                        <ion-icon name="close-circle" role="img" className="md hydrated" aria-label="close circle"></ion-icon>
		                                    </i>
		                                    <div className="valid-feedback">&nbsp;</div>
		                                    <div className="invalid-feedback">Enter your profession.</div>
		                                </div>
		                                <div className="input-info">Enter your profession</div>
		                            </div>
		                            <div className="clear"></div>

                                    <div className="form-group basic">
                                        <div className="divide-50l">
                                            <div className="input-wrapper guest-detail float-label">
                                                <input type="text" className="form-control input-box inputText " name="txtstate" id="txtstate" required autoComplete="off" />
                                                <label className="floating-label">State</label>
                                                <i className="clear-input">
                                                    <ion-icon name="close-circle" role="img" className="md hydrated" aria-label="close circle"></ion-icon>
                                                </i>
                                                <div className="valid-feedback">&nbsp;</div>
                                                <div className="invalid-feedback">Enter your state.</div>
                                            </div>
                                            <div className="input-info">Enter your state</div>
                                        </div>

                                        <div className="divide-50r">
                                            <div className="input-wrapper guest-detail float-label">
                                                <input type="text" className="form-control input-box inputText " name="txtcountry" id="txtcountry" required autoComplete="off" />
                                                <label className="floating-label">Country</label>
                                                <i className="clear-input">
                                                    <ion-icon name="close-circle" role="img" className="md hydrated" aria-label="close circle"></ion-icon>
                                                </i>
                                                <div className="valid-feedback">&nbsp;</div>
                                                <div className="invalid-feedback">Enter your country.</div>
                                            </div>
                                            <div className="input-info">Enter your country</div>
                                        </div>
                                        <div className="clear"></div>
                                    </div>
                                    <div className="form-group basic">
                                        <div className="divide-50l">
                                            <div className="input-wrapper guest-detail float-label">
                                                <label className="label">Coupon Code</label>                                        
                                                <input type="text" className="form-control input-box inputText " name="txtcouponcode" id="txtcouponcode" autoComplete="off" />
                                                <i className="clear-input">
                                                    <ion-icon name="close-circle" role="img" className="md hydrated" aria-label="close circle"></ion-icon>
                                                </i>
                                            </div>
                                            <div className="input-info">Enter coupon code</div>
                                        </div>

                                        <div className="divide-50r">
                                            <div className="">
                                                <button type="button" className="btn mt-6 text-upper w-100 btn-outline-secondary rounded btn-ssm mb-0 font-11 lp-2" name="btnapplycoupon" onClick={(e)=> {this.applyCouponcode()}} id="btnapplycoupon">Apply</button>
                                            </div>
                                        </div>
                                        <div className="clear"></div>
                                    </div>
                                    <div id="err_coupon" className="err-required pl-0"></div>
                            </div>
                            <div className="mt-1 text-left">
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" defaultValue="Y" id="chkterms" name="chkterms" required="required" />
                                    <label className="custom-control-label text-gre4 font-12" htmlFor="chkterms">
                                        I Agree to
							            <Link className="text-gre4 font-12" onClick={() => { this.handleClickGetTerms() }}>Terms &amp; Conditions</Link>
                                    </label>
                                    <div className="valid-feedback">&nbsp;</div>
                                    <div className="invalid-feedback">Please select the checkbox.</div>
                                </div>
                            </div>
                    </div>
                        <div className="section double mt-1 mb-5">
                        <div className="mt-4">
                            <button type="submit" className="btn btn-coach-pink rounded btn-block btn-mid text-upper">
                                <span className="spinner-border spinner-border-sm mr-05" id="submitLoading" style={{ display: 'none' }} role="status" aria-hidden="true"></span>
				            Register
    				        </button>
                        </div>
                    </div>
                    </form>
                    </div>

                    	    <div className="modal web-size-popup fade modalbox" id="ModalSizeChart" tabIndex="-1" role="dialog">
                    	        <div className="modal-dialog" role="document">
                    	            <div className="modal-content">
                    	                <div className="modal-header">
                    	                    <h5 className="modal-title">Size Chart</h5>
                    	                    <Link data-dismiss="modal"><ion-icon name="close-outline"></ion-icon></Link>
                    	                </div>
                    	                <div className="modal-body p-0">
                    	                    <img width="100%" src={'/assets/img/size-chart.jpeg'} />
                    	                </div>
                    	            </div>
                    	        </div>
                    	    </div>

                        <div className="modal fade modalbox" id="ModalTerms" tabIndex="-1" role="dialog">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">Terms &amp; Conditions</h5>
                                        <Link data-dismiss="modal"><ion-icon name="close-outline"></ion-icon></Link>
                                    </div>
                                    <div className="modal-body p-0">
                                        <ol className="tnc pr-2">
                                            <li>As a general rule, all food orders placed on the Platform are treated as confirmed.</li>
                                            <li>However, upon your successful completion of booking an order, we may call you on the telephone
                    or mobile number provided to confirm the details of the order, the price to be paid and the
                    estimated delivery time. For this purpose, you will be required to share certain information with us,
                    including but not limited to (i) your first and last name (ii) mobile number; and (iii) email address. It
                    shall be your sole responsibility to bring any incorrect details to our attention.</li>
                                            <li>In addition to the foregoing, we may also contact you by phone and / or email to inform and
                    confirm any change in the order, due to availability or unavailability or change in price of the order.</li>
                                            <li>It is clarified that ThatLifestyleCoach reserves the right to not to process your order in the event you
                    are unavailable on the phone at the time we call you for confirming the order and such event the
                    provisions of the cancellation and refund policy below shall be applicable.</li>
                                            <li>All payments made against the purchases/services on the Platform by you shall be compulsorily in
                    Indian Rupees acceptable in the Republic of India. The Platform will not facilitate transactions with
                    respect to any other form of currency with respect to the purchases made on Platform. You can pay
                    by (i) credit card or debit card or net banking; (ii) any other RBI approved payment method at the
                    time of booking an order; or (iii) credit or debit card or cash at the time of delivery</li>
                                            <li>You understand, accept and agree that the payment facility provided by ThatLifestyleCoach is neither a
                    banking nor financial service but is merely a facilitator providing an electronic, automated online
                    electronic payment, receiving payment on delivery, collection and remittance facility for the
                    transactions on the Platform using the existing authorized banking infrastructure and credit card
                    payment gateway networks. Further, by providing payment facility, ThatLifestyleCoach is neither acting
                    as trustees nor acting in a fiduciary capacity with respect to the transaction or the transaction price.</li>
                                            <li>You agree to pay us for the total amount for the order placed by you on the Platform. Please note
                    that we cannot control any amount that may be charged to you by your bank related to our
                    collection of the total amount, and we disclaim all liability in this regard.</li>
                                            
                                        </ol>
                                        {/*<div className="form-button-group">
                                            <div className="row">
                                                <div className="col-12">
                                                    <button type="button" className="btn btn-secondary btn-block btn-lg" data-dismiss="modal">CLOSE</button>
                                                </div>
                                            </div>
                                        </div>*/}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="modal fade dialogbox" id="ModalAlert" data-backdrop="static" tabIndex="-1" role="dialog">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-icon text-danger">
                                        <ion-icon name="close-circle"></ion-icon>
                                    </div>
                                    <div className="modal-body"> 
                                            {this.state.Notifications} 
                                    </div>
                                    <div className="modal-footer">
                                        <div className="btn-inline"> 
                                            <button type="button" className="btn" data-dismiss="modal">Ok</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        )
    }
}
export default Register;