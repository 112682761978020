import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import AppHeader from './AppHeader';
import Loader from './Loader';
import AppBottomMenu from './AppBottomMenu';
import ModalAlert from './ModalAlert';
import { PingUserActive } from './Common';
import $ from "jquery";

class FAQ extends Component {
    constructor(props) {
    	super(props);
    	this.state = {
	    	LOADING: true,
	    	Notifications: false,
	    	Logged: false,
	    	FAQ: [],
	    	customerid: localStorage.getItem('userData')
	    }
   	}

   	getFAQ() {
   		const requestOptions = {
   		    method: 'POST',
   		    headers: { 'Content-Type': 'application/json' },
   		    body: JSON.stringify({ mode: 'FAQ'})
   		};

   		fetch(global.config.bURL+"global_data.php", requestOptions)
   		    .then(function (response) {
   		        return response.json();
   		    })
   		    .then((result)=> {
   		    	// console.log(result);

   		        this.setState({
   		        	FAQ:result.FAQ,
   		         	LOADING: false, 
   		         	Logged: true 
   		        });
   		    });
   	}

   	componentDidMount() {
   		this.getFAQ();
   	}

   	render() {

   		if (!this.state.customerid) {
   		    localStorage.setItem('userData','');
   		    return (<Redirect to={'/'}/>)
   		}

   		let AppHeaderClass = 'appHeader no-border bg-common-header text-light';
   		let AppHeaderLeft = 'showLINK';
   		let AppHeaderLeftLink = '/Profile';
   		let AppHeaderTitle = "FAQ's";
   		let AppHeaderRight = '';
   		let AppHeaderRightLink = '';

   		return (
   			<div>
   				{this.state.LOADING && <Loader />}
   				    
   				<AppHeader AppHeaderClass={AppHeaderClass} AppHeaderLeft={AppHeaderLeft} AppHeaderLeftLink={AppHeaderLeftLink} AppHeaderTitle={AppHeaderTitle} AppHeaderRight={AppHeaderRight} AppHeaderRightLink={AppHeaderRightLink} />

   				<div id="appCapsule" className="pb-5">
   					<ol className="faq-list">
   					{this.state.FAQ.length > 0 &&
   						this.state.FAQ.map((faq, i)=> {
   							
   							return (
   							        <li>
		   							    <div className="item" key={i}>
		   							      	<h3 dangerouslySetInnerHTML={{__html: faq.QUESTION}} />
		   							      	<p dangerouslySetInnerHTML={{__html: faq.ANSWER}} />
		   							    </div>
	   							    </li>
   							)	
   						})
   					}
   					</ol>
   				</div>

   				<AppBottomMenu activeClass={"profile"}/>
   			</div>
   		);
   	}
}

export default FAQ;