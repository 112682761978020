import React, { Component, useRef } from 'react';
import { Link, Redirect } from 'react-router-dom';
import AppHeader from './AppHeader';
import Loader from './Loader';
import AppBottomMenu from './AppBottomMenu';
import { formatDate, messageReceivedHTML, messageSentHTML } from './Common';
import { animateScroll } from "react-scroll";
import $ from "jquery";

class Chat extends Component {
    constructor(props) {
        super(props);
        this.state = {
        	LOADING: true,
        	Notifications: false,
        	Logged: false,
        	profiledata: [],
        	subscriptiondata: [],
        	profileImage: "",
        	customerid: localStorage.getItem('userData'),
        	TODAY: formatDate(new Date()),
            chatHISTORY: "",
            chatID: "",
            chatDATID: "",
            ALLOWED: false,
            alertNotif: "",
            alertTitle: "",
            redirectRenewal: "N",
            redirectPayment: "N",
       	}

        this.getChatHistory = this.getChatHistory.bind(this);
        this._sendMessage = this._sendMessage.bind(this);
        this._receiveMessage = this._receiveMessage.bind(this);
        this.endMessage = React.createRef()  
    }

    _sendMessage () {
        let message = $("#txtmessage").val();

        if(message.trim()!='')
        {
	        const requestOptions = {
	            method: 'POST',
	            headers: { 
	                'Content-Type': 'application/json',
	                'Authorization': 'Bearer my-token',
	                'My-Custom-Header': 'foobar'
	            },
	            body: JSON.stringify({mode: 'CLIENT_CHAT_INSERT', customer: this.state.customerid, s_id: localStorage.getItem('subscriptionID'), chat_id: this.state.chatID, chat_dat_id: this.state.chatDATID, msg:message })
	        };

	        fetch(global.config.bURL+"customer_chat.php", requestOptions)
	          .then(function (response) {
	              return response.json();
	          })
	          .then((result) => {
	          		message = $("#txtmessage").val('');
	    			this._receiveMessage();
	          });
	    }
	    
    }

    _receiveMessage () {
      fetch(global.config.bURL+"customer_chat.php?mode=CLIENT_CHAT_NEW&s_id="+localStorage.getItem('subscriptionID')+"&customer="+this.state.customerid+"&chat_id="+localStorage.getItem('CHAT_ID')+"&chat_dat_id="+localStorage.getItem("CHAT_DAT_ID") )
        .then(function (response) {
            //console.log(response);
            return response.json();
        })
        .then((result) => {
        	// console.log(result);
        	localStorage.setItem('CHAT_ID', result.CHAT_ID);
        	localStorage.setItem('CHAT_DAT_ID', result.LAST_ID);

        	this.setState({
        	  LOADING:false,
        	  chatHISTORY: this.state.chatHISTORY.concat(result.CHAT),
        	  chatID: result.CHAT_ID,
        	  chatDATID: result.LAST_ID,
        	});

        });  
    }

    // executeScroll = () => this.endMessage.current.scrollIntoView()

    getChatHistory() {
      fetch(global.config.bURL+"customer_chat.php?mode=CLIENT_CHAT_HISTORY&s_id="+localStorage.getItem('subscriptionID')+"&customer="+this.state.customerid)
        .then(function (response) {
            //console.log(response);
            return response.json();
        })
        .then((result) => {
            // console.log(result);
            let paid_status = result.SUBSCRIPTION.PAID;
            let exp_date = result.SUBSCRIPTION.END;
            let allowed_flag = true;
            let alert_title = "";
            let alert_notif = "";
            localStorage.setItem('CHAT_ID', result.CHAT_ID);
            localStorage.setItem('CHAT_DAT_ID', result.LAST_ID);

            if(result.SUBSCRIPTION.PROGRAM_TYPE =='P')
            {
	            if(exp_date < this.state.TODAY) {
	            
	            	allowed_flag = false;
	            	alert_title = "Subscription Expired";
	            	alert_notif = "Your subscription has expired";	
	            }
	            else if(paid_status=='N') {
	            
	            	allowed_flag = false;
	            	alert_title = "Incomplete Payment";
	            	alert_notif = "Please complete the payment to continue";
	            }
	        }

            var last_id = result.LAST_ID;

            console.log("CH: "+allowed_flag);
            
            this.setState({
              LOADING:false,
              chatHISTORY: result.CHAT,
              chatID: result.CHAT_ID,
              chatDATID: last_id,
              ALLOWED: allowed_flag,
              alertTitle: alert_title,
              alertNotif: alert_notif
            });

          if (alert_notif!='')
            window.$('#ModalAlert').modal('show'); 
        });
    }

    getProfileData() {
        if(this.state.customerid)
        {
          const requestOptions = {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ mode: 'HOME', clientid: this.state.customerid})
          };

          fetch(global.config.bURL+"global_data.php", requestOptions)
              .then(function (response) {
                  return response.json();
              })
              .then((result)=> {
                  let responseJson = result;
                  let allowed_flag = true;

                  if(responseJson.SUBSCRIPTION.DAYS_LEFT < 0) {
                  	allowed_flag = false;
                  }
                  // console.log("PD: "+allowed_flag);

                  this.setState({profileImage:responseJson.USER.PIC, subscriptiondata:responseJson.SUBSCRIPTION, profiledata: responseJson.USER, LOADING: false, 		Logged: true, 
                  		ALLOWED: allowed_flag,
                  });

                  /*if (allowed_flag!='')
		            window.$('#ModalAlert').modal('show'); */
              });
        }
    }

    componentDidMount() {
        this.getProfileData();
        this.getChatHistory();
    }

    componentWillMount() {
        let mount = $("div").hasClass("modal-backdrop");
        if (mount == true) {
            $('body').removeClass('modal-open');
            $(".modal-backdrop").remove();
            //window.location.reload(true);
        } 
    }

    componentDidUpdate() {
        var element = document.getElementById("endMessage"); 
        element.scrollIntoView();
    }

    render() {
    	if (!this.state.customerid) {
    	    localStorage.setItem('userData','');
    	    return (<Redirect to={'/'}/>)
    	}

    	let AppHeaderClass = 'appHeader no-border bg-common-header text-light';
    	let AppHeaderLeft = 'showLINK';
    	let AppHeaderLeftLink = '/Workout';
    	let AppHeaderTitle = 'Chat';
    	let AppHeaderRight = '';
    	let AppHeaderRightLink = '';
        let chatDATE = "";
        let thisDATE = "";

        const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

    	return(
    		<div>
    			{this.state.LOADING && <Loader />}
    			
    			<AppHeader AppHeaderClass={AppHeaderClass} AppHeaderLeft={AppHeaderLeft} AppHeaderLeftLink={AppHeaderLeftLink} AppHeaderTitle={AppHeaderTitle} AppHeaderRight={AppHeaderRight} AppHeaderRightLink={AppHeaderRightLink} />

	    			<div>
		    			<div id="appCapsule" className="pb-122">

                            {this.state.chatHISTORY && this.state.chatHISTORY.length > 0 &&
                                this.state.chatHISTORY.map((chat, i) => {
                                    
                                    let type = chat.MSG_TYPE;//?'RECEIVED':'SENT';
                                    // thisDATE = formatDate(chat.MSG_DATE;

                                    <div style={{display: (chat.MSG_TYPE)?"none":"block"}} className="message-divider">{chat.MSG_TYPE}</div>

                                    if(type=='SENT')
                                    {
                                        return(
                                            <div className="message-item user" key={i}>
                                                <div className="content">
                                                    <div className="bubble">
                                                        {chat.MSG_TEXT}
                                                    </div>
                                                    <div className="footer">{chat.MSG_TIME}</div>
                                                </div>
                                            </div>
                                        );
                                    }
                                    else if(type=='RECEIVED')
                                    {
                                        return(
                                            <div className="message-item" key={i}>
                                                <img src={chat.MSG_IMAGE?chat.MSG_IMAGE:"assets/img/sample/avatar/avatar1.jpg"} alt="avatar" className="avatar" />
                                                <div className="content">
                                                    <div className="title">{chat.MSG_TITLE}</div>
                                                    <div className="bubble">
                                                        {chat.MSG_TEXT}
                                                    </div>
                                                    <div className="footer">{chat.MSG_TIME}</div>
                                                </div>
                                            </div>
                                        );
                                    } 

                                    chatDATE = thisDATE;                                 
                                })
                            }

		    			</div>

                    	<div id="endMessage" ref={this.endMessage}></div>
				    		<div className="chatFooter">
	    			       	{this.state.subscriptiondata.SUBS_TYPE=='P' && this.state.ALLOWED &&
	    			       		<div className="chat-premium">
			    			        <div className="form-group boxed">
			    			            <div className="input-wrapper">
			    			                <input type="text" className="form-control" name="txtmessage" id="txtmessage" placeholder="Type a message..." autoComplete="off" />
			    			                <i className="clear-input">
			    			                    <ion-icon name="close-circle"></ion-icon>
			    			                </i>
			    			            </div>
			    			        </div>
		    			        	<button type="button" className="btn btn-icon btn-primary rounded send-outer" onClick={this._sendMessage}>
		    			            	<ion-icon name="send"></ion-icon>
		    			        	</button>
		    			        </div>
	    			        }

	    			        {this.state.subscriptiondata.SUBS_TYPE!='P' &&
	    			        	<div className="chat-basic">
	    			        		<p>This Feature is available to our premium members only.</p>
	    			        	</div>
	    			    	}

	    			    	{this.state.alertNotif!='' && !this.state.ALLOWED &&
	    			    		<p>{this.state.alertNotif+', the chat feature is disabled.'}</p>
	    			    	}
				    		</div>

		    		</div>

	                <div className="modal fade dialogbox" id="ModalAlert" data-backdrop="static" tabIndex="-1" role="dialog">
	                  <div className="modal-dialog" role="document">
	                    <div className="modal-content">
	                    	<div className="modal-icon text-danger">
	                    	    <ion-icon name="close-circle"></ion-icon>
	                    	</div>
		                	
		                	<div className="modal-header">
		                    	<h5 className="modal-title">{this.state.alertTitle}</h5>
		                  	</div>
	                      	<div className="modal-body">{this.state.alertNotif}</div>
	                      	<div className="modal-footer">
	                        <div className="btn-inline"> 
	                            <button type="button" className="btn btn-text-secondary" data-dismiss="modal">CANCEL</button> 
	                            <button type="button" onClick={this._clickConfrim} className="btn btn-text-primary" data-dismiss="modal">PROCEED</button> 
	                        </div>
	                      </div>
	                    </div>
	                  </div>
	                </div>

                <AppBottomMenu activeClass={"chat"} />
	               
	    	</div>
    	);
    }
}

export default Chat;