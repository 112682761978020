import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import AppHeader from './AppHeader';
import Loader from './Loader';
import $ from "jquery";

class ForgotPassword extends Component {
    constructor(props) {
    	super(props);
    	this.state = {
    		LOADING: true,
    		Logged: false,
    		showMESG: false,
    		MESG: "",
    	};
    }

    componentDidMount() {
    	this.setState({
    		LOADING: false,
    		Logged: false,
    	})
    }

    handleSubmit = (e) => {
        e.preventDefault();

        let err = 0;
        if ($('#frmForgotPassowrd')[0].checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            err++;
        }
        $('#frmForgotPassowrd')[0].classList.add('was-validated');

        if (err == 0) {
            $('#submitLoading').css('display', 'block');

	        const method = "POST";
	        const body = new FormData(this.form);
	        //console.log(body);
	        var that = this;

	        fetch(global.config.bURL+'customer_forgotpassword.php', { method, body })
	            .then(function (response) {
	                //console.log(response);
	                return response.json();
	            })
	            .then(function (result) {
	            	// console.log(result);
	            	$('#submitLoading').css('display', 'none');

            		that.setState({
            			showMESG: true,
            			MESG: result.mesg,
            		})

	            });
	        }
    }

    render() {

    	let AppHeaderClass = 'appHeader no-border bg-common-header text-light';
    	let AppHeaderLeft = 'showLINK';
    	let AppHeaderLeftLink = '/Login';
    	let AppHeaderTitle = 'Forgot Password';
    	let AppHeaderRight = '';
    	let AppHeaderRightLink = '';

    	return (

    	        <div>
    	            {this.state.LOADING && <Loader />}

    	            <AppHeader AppHeaderClass={AppHeaderClass} AppHeaderLeft={AppHeaderLeft} AppHeaderLeftLink={AppHeaderLeftLink} AppHeaderTitle={AppHeaderTitle} AppHeaderRight={AppHeaderRight} AppHeaderRightLink={AppHeaderRightLink} />

    	            <div id="appCapsule">
    	            	<div className="login-form mt-6 pt-1">
	    	                <div className="section pr-3 pl-3 pb-1 pt-0 basic-form">
			            	    {this.state.showMESG &&
				            	    <div className="wide-block pt-2 pb-2">
				            	        <div className="alert alert-primary mb-2 alert-dismissible fade show" role="alert">
				            	            {this.state.MESG}
				            	            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
				            	                <ion-icon name="close-outline"></ion-icon>
				            	            </button>
				            	        </div>
				            	    </div>
			            		}

		                        <form id="frmForgotPassowrd" ref={el => (this.form = el)} onSubmit={this.handleSubmit} className="needs-validation" noValidate>
		                        	<input type="hidden" name="mode" id="mode" defaultValue="FORGOT_PASSWORD" />
		                            
		                            <div className="form-group basic mb-2">
		                               	<div className="input-wrapper guest-detail float-label">
		                                    <input type="email" className="input-box inputText form-control" name="txtuseremail" id="txtuseremail" required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" autoComplete="off" />
		                                    <label className="floating-label">Enter your Email</label>
		                                    <i className="clear-input">
		                                        <ion-icon name="close-circle" role="img" className="md hydrated" aria-label="close circle"></ion-icon>
		                                    </i>
		                                    <div className="valid-feedback">&nbsp;</div>
		                                    <div className="invalid-feedback">Please enter a valid email id.</div>
		                                </div>
		                                <div className="input-info">Enter your email</div> 
		                            </div>
		                            
		                            <div className="section double mt-1 mb-5">
		                              <div className="mt-4">
                                          	<button type="submit" className="btn btn-coach-pink rounded btn-block btn-mid text-upper">
                                            	<span className="spinner-border spinner-border-sm mr-05" id="submitLoading" style={{ display: 'none' }} role="status" aria-hidden="true"></span>
              				            		Reset
              				            	</button>
		                              </div>
		                            </div>
		                        </form>
	    	                </div>
	    	            </div>
    	            </div>
    	        </div>
		);
    }

}

export default ForgotPassword;