import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import AppHeader from './AppHeader';
import Loader from './Loader';
import SelectProgramSlider from './SelectProgramSlider';
// import {PostData} from './PostData';
import $ from "jquery";

class SelectProgram extends Component {
    constructor(props) {
            super(props);
            this.state = {
            LOADING: true,
            Notifications: false,
            Logged: false,
            pDATA: [],
            pSelected: false,
            Saved: ''
        };

        // this.fetchDetailList = this.fetchDetailList.bind(this);
        this.ToggleClass = this.ToggleClass.bind(this);
        localStorage.setItem('storedSelection', "");
    }

    componentWillMount() {
        // let mount = $("div").hasClass("modal-backdrop");
        // if (mount == true) {
        //     $('body').removeClass('modal-open');
        //     $(".modal-backdrop").remove();
        //     //window.location.reload(true);
        // }
    }

    fetchDetailList() {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ mode: 'PRGRM_OPTIONS' })
        };

        fetch(global.config.bURL+"global_data.php", requestOptions)
            .then(function (response) {
                // console.log(response.gvariables);
                return response.json();
            })
            .then((result)=> {
                let responseJson = result;
                this.setState({ pDATA: responseJson, LOADING: false });
            });
    }

    ToggleClass(event, classname="") {
      let el = event.target;
      let el_id = el.id;
      let parent = $("#"+el_id).parent().parent();
        
        $('.'+classname).removeClass('active'); // remove class
        $(parent).addClass('active'); // add class
    }

    handleSubmit = (e) => {
        e.preventDefault();

        let err = 0;
        if ($('#frmProgram')[0].checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            err++;
        }
        $('#frmProgram')[0].classList.add('was-validated');

        let plcofprac = $('input[name="plcofprac"]:checked');
        let level = $('input[name="level"]:checked');
        let goal = $('input[name="goal[]"]:checked');
        let health = $('input[name="health[]"]:checked');
        let exertype = '';//$('input[name="exertype"]:checked');

        if (plcofprac.length == 0) {
            err++;
            $("#err_practice").show();
            $("#err_practice").html('Select place of practice.');
        }else {
        	$("#err_practice").hide();
        	// $("#err_practice").html('Please select place of practice.');
        }

        if (level.length == 0) {
            err++;
            $("#err_level").show();
            $("#err_level").html('Select a fitness level.');
        }
        else {
            $("#err_level").hide();
        }

        if (goal.length == 0) {
            err++;
            $("#err_goal").show();
            $("#err_goal").html('Select atleast one goal.');
        }
        else {
            $("#err_goal").hide();
        }

        // err = 0;
        if (err == 0) {
            let notify = '';
            let dataSaved = false;

            const method = "POST";
            const body = new FormData(this.form);
            var that = this;

            let health_arr = [];    
            $(health).each(function(){
              health_arr.push($(this).val());
            });

            let goal_arr = [];    
            $(goal).each(function(){
              goal_arr.push($(this).val());
            });

            let storedSelection = "plcofprac="+plcofprac.val()+"|level="+level.val()+"|goal="+goal_arr+"|health="+health_arr;//+"|exertype="+exertype.val();
            localStorage.setItem('storedSelection', storedSelection);
            // console.log(localStorage.getItem("storedSelection"));
            if(localStorage.getItem("storedSelection")!='') {
              $('#submitLoading').css('display', 'block');
              dataSaved = true;
              this.setState({ Notifications: notify, Saved: dataSaved });
            }
        }
    }

    componentDidMount() {
        //alert(localStorage.getItem('SUBSCRIBER_ID'));
        this.fetchDetailList();
        window.$("#wrapper_bgVideo").css('display', 'none');

    }

    render() {
        let AppHeaderClass = 'appHeader no-border bg-common-header text-light';
        let AppHeaderLeft = '';
        let AppHeaderLeftLink = '/FreeTrial';
        let AppHeaderTitle = 'BASIC DETAILS';
        let AppHeaderRight = '';
        let AppHeaderRightLink = '';

        if(this.state.Saved) {
          return ( <Redirect to="/Plans" />);
        }

        return (
            <div>
                {this.state.LOADING && <Loader />}
                <AppHeader AppHeaderClass={AppHeaderClass} AppHeaderLeft={AppHeaderLeft} AppHeaderLeftLink={AppHeaderLeftLink} AppHeaderTitle={AppHeaderTitle} AppHeaderRight={AppHeaderRight} AppHeaderRightLink={AppHeaderRightLink} />

                  <div id="appCapsule">
                    <form id="frmProgram" ref={el =>
                        (this.form = el)} onSubmit={this.handleSubmit} className="needs-validation" noValidate>
                        <input type="hidden" value="PRGRM" className="form-control" name="mode" id="mode" />

                          <div className="login-form select-prg">      

                              <div className="section full mt-2 pr-1 pl-1">
                                  <div className="text-center">
                                     <img src="assets/img/new-logo.png" alt="tlc logo"/>
                                  </div>

                                  <div className="section-title btn-titles">Place of Practice</div>
                                  <div className="wide-block-noborder pt-05 pb-2">
                                      <div className="row">
                                            {this.state.pDATA.practice && 
                                                this.state.pDATA.practice.map((prc, i)=> {
                                                    return (
                                                        <div className="col-6" key={i} id={prc.ID}>
                                                          <div className="radio fancy-blue btn btn-select btn-outline-grey rounded mb-1 imaged img-fluid practice">
                                                            <label>
                                                              <input type="radio" name="plcofprac" id={"plcofprac_"+i} value={prc.ID}  required onClick={(e)=>this.ToggleClass(e, 'practice')}/>
                                                              {prc.NAME}
                                                              <span dangerouslySetInnerHTML={{__html: prc.ICON}} />
                                                              <span></span>
                                                            </label>
                                                          </div>
                                                      </div>
                                                    );
                                                })
                                            }
                                            <span style={{display: "none"}} id="err_practice" className="err-required"></span>
                                      </div>

                                  </div>
                              </div>
                              


                                {/*<div className="section full mt-1 mb-5">
                                    {this.state.pDATA.etype!=undefined && <SelectProgramSlider etype={this.state.pDATA.etype} />}
                                </div>*/}

                              <div className="section full mt-2 pr-1 pl-1">
                                  <div className="section-title btn-titles">Fitness Level</div>
                                  <div className="wide-block-noborder pt-05 pb-2">
                                      <div className="row">
                                            {this.state.pDATA.level &&
                                                this.state.pDATA.level.map((lt, i)=>{
                                                    return(
                                                        <div className="col-4" key={i}>
                                                             <div className="radio fancy-blue btn btn-select2 btn-outline-grey rounded mb-1 imaged img-fluid level">
                                                              <label>
                                                                <input type="radio" name="level" id={'level'+lt.ID} value={lt.ID} required onClick={(e)=>this.ToggleClass(e, 'level')}/> 
                                                                {lt.NAME}
                                                                <span></span>
                                                              </label>
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                            }
                                            <span style={{display: "none"}} id="err_level" className="err-required"></span>
                                      </div>

                                  </div>
                              </div>   
                              <div className="section inset mt-0">

                                  <div className="acc-options accordion no-lines" id="accordionExample2">

                                      <div className="item">
                                          <div className="accordion-header">
                                              <button className="btn collapsed" type="button" data-toggle="collapse" data-target="#accordion01">
                                                  Your Goal
                                              </button>
                                          </div>
                                          <div id="accordion01" className="accordion-body collapse" data-parent="#accordionExample2">
                                              <div className="accordion-content text-left">
                                                  <div className="wide-block no-border">
                                                  {this.state.pDATA.goal && 
                                                    this.state.pDATA.goal.map((goal, i)=>{
                                                        return (
                                                            /*<div className="custom-control custom-radio mb-1" key={i}>
                                                                <input type="radio" id={'goal'+goal.ID} name="goal" className="custom-control-input" value={goal.ID}  required />
                                                                <label className="custom-control-label" htmlFor={'goal'+goal.ID}>{goal.NAME}</label>
                                                            </div>*/
                                                            <div className="custom-control custom-checkbox mb-1" key={i}>
                                                                <input type="checkbox" className="custom-control-input" name="goal[]" id={'goal'+goal.ID} value={goal.ID} />
                                                                <label className="custom-control-label" htmlFor={'goal'+goal.ID}>{goal.NAME}</label>
                                                            </div>
                                                        );
                                                    })
                                                  }
                                                </div>
                                              </div>
                                          </div>
                                          <span style={{display: "none"}} id="err_goal" className="err-required"></span>
                                      </div>

                                      <div className="item">
                                          <div className="accordion-header">
                                              <button className="btn collapsed" type="button" data-toggle="collapse" data-target="#accordion02">
                                                  Health Conditions &nbsp;<span class="font-10 text-blue">(optional)</span>
                                              </button>
                                          </div>
                                          <div id="accordion02" className="accordion-body collapse" data-parent="#accordionExample2">
                                              <div className="accordion-content text-left">
                                                  <div className="wide-block no-border">
                                                    {this.state.pDATA.health && 
                                                      this.state.pDATA.health.map((health, i)=>{
                                                          return (
                                                              <div className="custom-control custom-checkbox mb-1" key={i}>
                                                                  <input type="checkbox" className="custom-control-input" name="health[]" id={'health'+health.ID} value={health.ID} />
                                                                  <label className="custom-control-label" htmlFor={'health'+health.ID}>{health.NAME}</label>
                                                              </div>
                                                          );
                                                      })
                                                    }

                                                  </div>
                                              </div>
                                          </div>
                                      </div>

                                  </div>
                              </div>      
                              <div className="section double mt-1 mb-5">
                                <div className="mt-4">
                                    <button type="submit" className="btn btn-coach-pink rounded btn-block btn-mid text-upper">
                                        <span className="spinner-border spinner-border-sm mr-05" id="submitLoading" style={{ display: 'none' }} role="status" aria-hidden="true"></span>
                                        Generate a program
                                    </button>
                                </div>
                              </div>
                          </div>
                    </form>
                  </div>
            </div>
        );
    }
}

export default SelectProgram;