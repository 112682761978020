import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import AppHeader from './AppHeader';
import Loader from './Loader';
import AppBottomMenu from './AppBottomMenu';
import Moment from 'react-moment';

class Calendar extends Component {
    constructor(props) {
            super(props);
            this.state = {
           	LOADING: true,
            Notifications: false,
            calendarDate: [],
            customerid: localStorage.getItem('userData')
        };
    }

    getCalendarOverview() {
    	const page_url = "schedule.php?mode=SCHEDULE&customer="+this.state.customerid+"&s_id="+localStorage.getItem("subscriptionID");
        
        fetch(global.config.bURL+page_url, {
        		method:'POST', 
        		headers:{ 'Content-Type': 'application/json' }
        	})
            .then(function (response) {
                return response.json();
            })
            .then((result)=> {
        		// console.log(result);
        		this.setState({
        			calendarDate: result.SCHEDULE,
        			LOADING: false,
        		})
            });
    }

    componentDidMount() {
    	this.getCalendarOverview();
    }

    render() {

    	let AppHeaderClass = 'appHeader no-border bg-common-header text-light';
    	let AppHeaderLeft = 'showLINK';
    	let AppHeaderLeftLink = '/Workout';
    	let AppHeaderTitle = 'Calendar';
    	let AppHeaderRight = '';
    	let AppHeaderRightLink = '';

    	if(!this.state.customerid) {
    	  localStorage.setItem('userData',"");
    	  return (<Redirect to={'/'}/>);
    	}

    	return(

    	       <div>
    	           {this.state.LOADING && <Loader />}

    	           <AppHeader AppHeaderClass={AppHeaderClass} AppHeaderLeft={AppHeaderLeft} AppHeaderLeftLink={AppHeaderLeftLink} AppHeaderTitle={AppHeaderTitle} AppHeaderRight={AppHeaderRight} AppHeaderRightLink={AppHeaderRightLink} />

    	           	<div id="appCapsule" className="choose-datepicker">
    	           		<div className="section pr-3 pl-3 pb-6 pt-2 basic-form">
    	           			<div className="form-group basic">
    	           				<div className="calendar-structure">
    	           					<ul className="listview image-listview media pt-0 pb-1 mb-2">
	    	           					{this.state.calendarDate && this.state.calendarDate.length > 0 &&
	    	           						this.state.calendarDate.map((eachDAY, i) => {
	    	           							let w_edit_url = "";
	    	           							let level_id = eachDAY.LEVEL;
	    	           							let week_id = eachDAY.WEEK;
	    	           							let day_id = eachDAY.DAY;
	    	           							let s_date = eachDAY.DATE;
	    	           							let statusCLASS = "badge-primary";
	    	           							let statusTEXT = "";

	    	           							if(eachDAY.STATUS=='P') {
	    	           								statusCLASS = "badge-warning";
	    	           								statusTEXT = "Pending";
	    	           							}
	    	           							else if(eachDAY.STATUS=='A') {
	    	           								statusCLASS = "badge-success";
	    	           								statusTEXT = "Completed";
	    	           							}
	    	           							else if(eachDAY.STATUS=='') {
	    	           								statusCLASS = "";
	    	           								statusTEXT = "";
	    	           							}

	    	           							if(eachDAY.ID) {
	    	           							    w_edit_url = '/WorkoutDetail/W/'+eachDAY.ID
	    	           							                    +'/'+localStorage.getItem('subscriptionID');
	    	           							}
	    	           							else {
	    	           							    w_edit_url = '/WorkoutDetail/W/'+eachDAY.ID
	    	           							                    +'/'+localStorage.getItem('subscriptionID')
	    	           							                    +'/'+level_id
	    	           							                    +'/'+week_id
	    	           							                    +'/'+day_id
	    	           							                    +'/'+s_date;
	    	           							}

	    	           							if(this.state.WO_COMPLETED==0) {
	    	           							    w_edit_url = '/WorkoutSummary/'+eachDAY.ID;
	    	           							}

	    	           							return(
	    	           							      	<li key={i}>
		    	           							      	<Link to={w_edit_url} className="item">
		    	           							      		<div className="date-display">
		    	           							      			<p className="ft-wt-400 font-14 mb-0 lp-2 text-upper color-base">
		    	           							      				<Moment date={eachDAY.DATE} format="MMM"> </Moment>
		    	           							      			</p>
		    	           							      				<Moment date={eachDAY.DATE} format="DD"> </Moment>
		    	           							      			<p className="ft-wt-400 font-11 mb-0 lp-1">
		    	           							      				<Moment date={eachDAY.DATE} format="YYYY"> </Moment>
		    	           							      			</p>
		    	           							      		</div>
		    	           							      		<div className="in">
		    	           							      			<div className="text-gre5 ft-wt-600 font-15 w-60">
		    	           							      				{eachDAY.NAME}
		    	           							      				{/*<div className="text-muted ft-wt-400">Exercise 1  | Exercise 2</div>*/}
		    	           							      			</div>
		    	           							      			<span className={"badge "+statusCLASS}>{statusTEXT}</span>
		    	           							      		</div>
		    	           							      	</Link>
	    	           							      	</li> 
	    	           							);
	    	           						})
	    	           					}
    	           					</ul>
    	           				</div>   
    	           			</div>
    	           		</div>
    	           	</div>

    	           <AppBottomMenu activeClass={"home"} />
    	        </div>
    	);
    }
}

export default Calendar;