import React from 'react';
import ReactDOM from 'react-dom';
import { Link, Redirect } from 'react-router-dom';

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import $ from 'jquery';


/*function _playVideo(title="", code="") {
	console.log('popup called...');
	// console.log(title);
	// console.log(code);
	// this.setState({
	// 	WO_TITLE:
	// 	WO_VCODE:
	// })
	window.$('#playVideo').modal('show'); 
}*/

const WorkoutSlider2 = (props) => {
    let Items = "";
    
    Items = props.slider.map((s_slider, i) => {
        return (
                <div className="item video-cover " key={'slider_'+i} onClick={()=>{props._playVideo(s_slider.WORKOUT_NAME, s_slider.WORKOUT_VIDEOLINK)}}>
                	<button type="button" className="video-btn">
                    	<div className="video-area">
                            {/*<iframe src={"https://www.youtube.com/embed/"+s_slider.WORKOUT_VIDEOLINK} height="250px" frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen> </iframe>*/}
                           	<img src={s_slider.WORKOUT_VIDEOIMG} alt={s_slider.WORKOUT_NAME} className="w-100" />
                            {/*<ion-icon name="play"></ion-icon>*/}
                    	</div>
                    </button>
                    <div className="video-text video-icon">
                        <h5 className="m-0 ft-wt-400 text-gre6 font-12">{s_slider.WORKOUT_NAME}</h5>
                        <button type="button" className="btn btn-coach-pink rounded play-mid  text-upper"><ion-icon name="play"></ion-icon></button>
                    </div> 
                </div>
        );
    });

    const options = {
        items: 2,
        nav: false,
        loop: false,
        dots: false,
        stagePadding: 30,
        rewind: false,
    };

    return (
        <div>
            <OwlCarousel className="owl-theme" loop margin={15} {...options}>
                    {Items}
            </OwlCarousel>
        </div>
    );
}

export default WorkoutSlider2;