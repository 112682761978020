import React from 'react';
import {BrowserRouter, Route, Switch, Link} from 'react-router-dom';
import Login from './components/Login';
import FreeTrial from './components/FreeTrial';
import SelectProgram from './components/SelectProgram';
import Plans from './components/Plans';
import Register from './components/Register';
import Home from './components/Home';
import Profile from './components/Profile';
import Workout from './components/Workout';
import Workout2 from './components/Workout2';
import WorkoutDetail from './components/WorkoutDetail';
import WorkoutStart from './components/WorkoutStart';
import Timer from './components/Timer';
import WorkoutSummary from './components/WorkoutSummary';
import UpdateProfile from './components/UpdateProfile';
import ChangeLevel from './components/ChangeLevel';
import UpdateProgress from './components/UpdateProgress';
import Chat from './components/Chat';
import Calendar from './components/Calendar';
import Subscription from './components/Subscription';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import ContinuePayment from './components/ContinuePayment';
import PaymentNow from './components/PaymentNow';
import Plans2 from './components/Plans2';
import FAQ from './components/FAQ';
import Logout from './components/Logout';
import OnlinePay from './components/OnlinePay';
import OnlinePayPT from './components/OnlinePayPT';
import Success from './components/Success';

const Routes = () => (
	<BrowserRouter>
		<Switch>
			<Route path="/" component={SelectProgram} exact={true} />
			<Route path="/Login" component={Login} exact={true} />
			<Route path='/Home' component={Home} exact={true} />
			<Route path='/ForgotPassword' component={ForgotPassword} exact={true} />
			<Route path="/SelectProgram" component={SelectProgram} exact={true} />
			<Route path="/Register/:pid/:type?" component={Register} exact={true} />
			<Route path="/Plans" component={Plans} exact={true} />
			<Route path="/Plans2" component={Plans2} exact={true} />
			<Route path="/Subscription" component={Subscription} exact={true} />
			<Route path="/ContinuePayment" component={ContinuePayment} exact={true} />
			<Route path="/PaymentNow/:pid/:type?" component={PaymentNow} exact={true} />
			<Route path="/OnlinePay" component={OnlinePay} exact={true} />
			<Route path="/OnlinePayPT/:bpid" component={OnlinePayPT} exact={true} />
			<Route path="/Logout" component={Logout} exact={true} />
			<Route path="/Success" component={Success} exact={true} />
		</Switch>
	</BrowserRouter>
);
export default Routes;