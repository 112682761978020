import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import AppHeader from './AppHeader';
import Loader from './Loader';
import AppBottomMenu from './AppBottomMenu';
import TimerControl from './TimerControl';
import $ from "jquery";

const Counter = function(props) {
    return (
        <h2> 
            <span>{props.min + ':'}</span>
            <span>{props.secs + ':'}</span>
            <span>{props.milisecs}</span>
        </h2>
    );
};

const Reset = function(props) {
    return (
        <div className="col-6 text-center play-pause">
            <button onClick={props.onClickReset} type="button" className="btn btn-coach-pink btn-lg mr-1"><ion-icon name="ellipse"></ion-icon></button>
            <p>Reset</p>
        </div>
    );
};

class Timer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Notifications: false,
            LOADING: true,
            workoutType: props.match.params.type,
            workoutID: props.match.params.workoutid,
            scheduleID: props.match.params.scheduleid,
            workoutName: "",
            paused: true,
            minute: 1,
            sec: 1, 
            milisec: 1,
            disp_milisec: '00',
            disp_secs: '00',
            disp_minute: '00',
        }
    }

    tick = () => {
        let z = '';
        let disp_milisec = '00';
        let disp_secs = '00';
        let disp_minute = '00';

        z = (this.state.milisec < 10) ? '0' : '';
        disp_milisec = z + this.state.milisec;
        this.setState({ milisec: this.state.milisec + 1, disp_milisec: disp_milisec });

        if (this.state.milisec == 90) {
            this.state.milisec = 0;
            z = (this.state.sec < 10) ? '0' : '';
            disp_secs = z + this.state.sec;
            this.setState({ sec: this.state.sec + 1, disp_secs: disp_secs });

            if (this.state.sec == 60) {
                this.state.sec = 0;
                z = (this.state.minute < 10) ? '0' : '';
                disp_minute = z + this.state.minute;
                this.setState({ minute: this.state.minute + 1, disp_minute:disp_minute });
            }
        }
    }
    
    startTimer = () =>{
        this.interval = setInterval(this.tick,10);
        this.setState({ paused : false });
    }
    
    stopTimer = () => {
        clearInterval( this.interval );
        this.setState({ paused : true });
    }
    
    reset = () => {
        this.setState({ 
            minute: 1,
            sec: 1, 
            milisec: 1,
            disp_milisec: '00',
            disp_secs: '00',
            disp_minute: '00',
            paused: true 
        });
        clearInterval( this.interval );
    }

    getWorkoutDetails () {

    }

    componentDidMount() {
        // this.handleStart();
        this.getWorkoutDetails();
        this.setState({
            LOADING: false,
        });
    }

    render() {
    	let backurl = '/WorkoutStart/'+this.state.workoutType+'/'+this.state.scheduleID+'/'+this.state.workoutID;

        let AppHeaderClass = 'appHeader no-border';
        let AppHeaderLeft = 'showLINK';
        let AppHeaderLeftLink = (this.state.scheduleID)?backurl:'/Workout';
        let AppHeaderTitle = 'Timer';
        let AppHeaderRight = '';
        let AppHeaderRightLink = '';

        return (
            <div>
                {this.state.LOADING && <Loader />}

                <AppHeader AppHeaderClass={AppHeaderClass} AppHeaderLeft={AppHeaderLeft} AppHeaderLeftLink={AppHeaderLeftLink} AppHeaderTitle={AppHeaderTitle} AppHeaderRight={AppHeaderRight} AppHeaderRightLink={AppHeaderRightLink} />

                <div id="appCapsule" className="pb-5">
                    <div className="section pb-1 pt-1 mb-1">
                        <h3 className="pb-0 mb-0 font-18  ft-wt-900 text-blue2 text-center">{this.state.workoutName}</h3>
                    </div>
                    <div className="section work-timer">
                        <div className="wide-block pt-2 pb-2 pr-0 pl-0 no-border pr-0 pl-0"> 
                            <div className="col-12 text-center timer-area mb-4 pr-0 pl-0">
                                    <div className="time-display">
                                        <Counter min={this.state.disp_minute} secs={this.state.disp_secs} milisecs={this.state.disp_milisec}  />
                                        <input type="hidden" name="txttimer" id="txttimer" value={this.state.disp_minute+":"+this.state.disp_secs+":"+this.state.disp_milisec} />
                                    </div>
                            </div>
                            <div className="row">
                                <TimerControl 
                                  paused={this.state.paused} 
                                  start={this.startTimer} 
                                  stop={this.stopTimer} 
                                />
                                <Reset  onClickReset={this.reset}/>
                            </div>
                        </div>
                    </div>
                </div>

                <AppBottomMenu activeClass={"timer"} />
            </div>
        );
    }
}

export default Timer;