import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import AppHeader from './AppHeader';
import Loader from './Loader';
import $ from "jquery";

class ResetPassword extends Component {
    constructor(props) {
    	super(props);
    	this.state = {
    		LOADING: true,
    		Logged: false,
    		UPDATED: "N",
    		token: props.match.params.token,
    		showMESG: false,
    		MESG: "",
    		Notifications: '',
    	};

    	this._dialogClick = this._dialogClick.bind(this);
    }

    _dialogClick() {
    	this.setState({
    		UPDATED: "Y",
    		LOADING: false,
    	});
    }

    componentDidMount() {
    	this.setState({
    		LOADING: false,
    		Logged: false,
    	})
    }

    componentWillMount() {
    	let mount = $("div").hasClass("modal-backdrop");

    	if (mount == true) {
    	    $('body').removeClass('modal-open');
    	    $(".modal-backdrop").remove();
    	    //window.location.reload(true);
    	} 
    }

    handleSubmit = (e) => {
        e.preventDefault();

        let err = 0;
        if ($('#frmResetPassowrd')[0].checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            err++;
        }
        $('#frmResetPassowrd')[0].classList.add('was-validated');

        const password = $('#txtpassword').val().trim();
        const password2 = $('#txtpassword2').val().trim();

        if (password != password2) {
            err++;
            $('#txtpassword2').val('');
            $('#password_err').html('Passwords entered do not match');
        }

        if (err == 0) {
            $('#submitLoading').css('display', 'block');

	        const method = "POST";
	        const body = new FormData(this.form);
	        //console.log(body);
	        var that = this;

	        fetch(global.config.bURL+'customer_resetpassword.php', { method, body })
	            .then(function (response) {
	                //console.log(response);
	                return response.json();
	            })
	            .then(function (result) {
	            	console.log(result);
	            	$('#submitLoading').css('display', 'none');

	            	if(!result.err)
	            		window.$('#modalSuccess').modal('show');

	            	that.setState({
	            		LOADING: false,
	            		showMESG: true,
	            		MESG: result.mesg,
	            		Notifications: result.mesg,
	            	});

	            });
	    }

    }

    render() {

    	let AppHeaderClass = 'appHeader no-border bg-common-header text-light';
    	let AppHeaderLeft = 'showLINK';
    	let AppHeaderLeftLink = '/';
    	let AppHeaderTitle = 'Forgot Password';
    	let AppHeaderRight = '';
    	let AppHeaderRightLink = '';
    	let tokenVALUE = this.state.token?this.state.token:"";

    	if(this.state.UPDATED=='Y') {
    		return(<Redirect to={'/'} />);
    	}

    	return (

    	        <div>
    	            {this.state.LOADING && <Loader />}

    	            <AppHeader AppHeaderClass={AppHeaderClass} AppHeaderLeft={AppHeaderLeft} AppHeaderLeftLink={AppHeaderLeftLink} AppHeaderTitle={AppHeaderTitle} AppHeaderRight={AppHeaderRight} AppHeaderRightLink={AppHeaderRightLink} />

    	            <div id="appCapsule">
    	                <div className="section pr-3 pl-3 pb-1 pt-0 basic-form">
    	            	    {this.state.showMESG &&
    		            	    <div className="wide-block pt-2 pb-2">
    		            	        <div className="alert alert-primary mb-2 alert-dismissible fade show" role="alert">
    		            	            {this.state.MESG}
    		            	            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
    		            	                <ion-icon name="close-outline"></ion-icon>
    		            	            </button>
    		            	        </div>
    		            	    </div>
    	            		}
	                        <form id="frmResetPassowrd" ref={el => (this.form = el)} onSubmit={this.handleSubmit} className="needs-validation" noValidate>
	                        	<input type="hidden" name="mode" id="mode" defaultValue="RESET_PASSWORD" />
	                        	<input type="hidden" name="txttoken" id="txttoken" defaultValue={tokenVALUE} />

	                        	<div className="form-group basic mb-2">
	                        	    <div className="input-wrapper guest-detail float-label">
	                        	        <input type="password" className="form-control input-box inputText " name="txtpassword" id="txtpassword" minLength="8" required autoComplete="off" />{/* pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" */}
	                        	        <label className="floating-label">Password</label>
	                        	        <i className="clear-input">
	                        	            <ion-icon name="close-circle"></ion-icon>
	                        	        </i>
	                        	        <div className="valid-feedback">&nbsp;</div>
	                        	        <div className="invalid-feedback">Must contain at least 8 or more characters.</div>
	                        	    </div>
	                        	</div>
	                        	<div className="form-group basic mb-2">
	                        	    <div className="input-wrapper guest-detail float-label">
	                        	        <input type="password" className="form-control input-box inputText " name="txtpassword2" id="txtpassword2" minLength="8" required autoComplete="off" /> {/*pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"  */}
	                        	        <label className="floating-label">Password (Again)</label>
	                        	        <i className="clear-input">
	                        	            <ion-icon name="close-circle"></ion-icon>
	                        	        </i>
	                        	        <div className="valid-feedback">&nbsp;</div>
	                        	        <div className="invalid-feedback">Must contain at least 8 or more characters.</div>
	                        	        <div id="password_err" className="err-required2"></div>
	                        	    </div>
	                        	</div>

	                            <div className="form-button-group">
		                            <button type="submit" className="btn btn-coach-pink rounded btn-block btn-mid text-upper">
		                                <span className="spinner-border spinner-border-sm mr-05" id="submitLoading" style={{ display: 'none' }} role="status" aria-hidden="true"></span>
						            Reset
		    				        </button>
	                            </div>
	                        </form>
    	                </div>
    	            </div>

    	            <div className="modal fade dialogbox" id="modalSuccess" data-backdrop="static" tabIndex="-1" role="dialog">
    	                <div className="modal-dialog" role="document">
    	                    <div className="modal-content">
	                            <div className="modal-icon text-success">
	                                <ion-icon name="checkmark-circle"></ion-icon>
	                            </div>   

    	                        <div className="modal-body"> 
    	                            {this.state.Notifications} 
    	                        </div>
    	                        <div className="modal-footer">
    	                            <div className="btn-inline"> 
    	                                <button type="button" onClick={this._dialogClick} className="btn" data-dismiss="modal">Ok</button>
    	                            </div>
    	                        </div>
    	                    </div>
    	                </div>
    	            </div>
    	        </div>
		);
    }

}

export default ResetPassword;